<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button [icon]="'arrow_left' | iconName"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'APPOINTMENT.BOOK' | translate }}</ion-title>
    <ion-buttons slot="end">
      <mea-icon-button (buttonClick)="closeModal()" icon="close" color="dark-grey"></mea-icon-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <ion-item>
      <ion-icon [src]="'calendar' | iconName" slot="start"></ion-icon>
      <ion-label>{{ date }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-icon [src]="'time' | iconName" slot="start"></ion-icon>
      <ion-label>{{ time }} - {{ calculateEndTime(time, appointmentType) }}</ion-label>
    </ion-item>
  </ion-card>
  <div class="confirmation-component">
    <form [formGroup]="appointmentFormService.appointmentFormGroup" (ngSubmit)="bookAppointment()">
      @if (appointmentType.additionalInformation) {
        <div>
          <div class="mea-headline-m section-title">
            {{ 'APPOINTMENT_BOOKING.ADDITIONAL_INFORMATION' | translate }}
          </div>
          <div class="mea-label-m">
            {{ appointmentType.additionalInformation }}
          </div>
        </div>
      }
      <div class="input-row">
        <div class="form-input two-thirds-width">
          <div class="mea-label-m section-title">
            {{ 'APPOINTMENT_BOOKING.CUSTOMER_NAME' | translate }}
          </div>
          <app-input
            [maxlength]="42"
            autocapitalize="sentences"
            placeholder="Name eintragen"
            formControlName="customerName"
          ></app-input>
        </div>
        @if (appointmentFormService.appointmentFormGroup.controls.customerAge) {
          <div class="form-input">
            <div class="mea-label-m section-title">
              {{ 'APPOINTMENT_BOOKING.AGE' | translate }}
            </div>
            <app-input
              [clearButton]="false"
              [maxlength]="3"
              placeholder="Alter"
              formControlName="customerAge"
              type="number"
            >
            </app-input>
          </div>
        }
      </div>
      @if (appointmentFormService.appointmentFormGroup.controls.customerSex) {
        <div class="form-input two-thirds-width">
          <div class="mea-label-m section-title">
            {{ 'APPOINTMENT_BOOKING.SEX' | translate }}
          </div>
          <app-circular-select
            [availableOptions]="dropdownSexOptions"
            formControlName="customerSex"
          ></app-circular-select>
        </div>
      }
      <div>
        <div class="mea-label-m section-title">
          {{ 'APPOINTMENT_BOOKING.APPOINTMENT_TYPE' | translate }}
        </div>
        <div class="mea-headline-m">{{ appointmentType.name }}</div>
      </div>
      <div class="form-input half-width">
        <div class="mea-label-m section-title">
          {{ 'APPOINTMENT_BOOKING.PHONE' | translate }}
        </div>
        <app-input
          [placeholder]="'z.B. +491234567890'"
          class="mea-headline-m"
          formControlName="customerPhone"
          type="tel"
        >
        </app-input>
      </div>
      <div class="form-input">
        <div class="mea-label-m section-title">
          {{ 'APPOINTMENT_BOOKING.EMAIL_REQUIRED' | translate }}
        </div>
        <app-input
          [markAsTouchedOnValueChange]="false"
          [placeholder]="'z.B. name@mail.de'"
          class="mea-headline-m"
          formControlName="customerEmail"
          type="email"
        >
        </app-input>
      </div>
      @if (bookAppointmentRequestState !== 'success') {
        <mea-button [loading]="bookAppointmentRequestState === 'inProgress'" expand="block" type="submit">
          {{ 'APPOINTMENT.BOOK' | translate }}
        </mea-button>
      }
    </form>
    @if (bookAppointmentRequestState === 'success') {
      <mea-success>
        Der Termin wurde erfolgreich gebucht.
        <br />
        Sie sollten in Kürze eine Bestätigungsmail bekommen.
      </mea-success>
      <mea-button (buttonClick)="closeModal()" expand="block">OK</mea-button>
    }
    @if (bookAppointmentRequestState === 'error') {
      <mea-error> Fehler beim Buchen des Termins. Bitte versuchen Sie es erneut. </mea-error>
    }
    @if (showValidationErrorMessage && !appointmentFormService.appointmentFormGroup.valid) {
      <mea-error> Bitte überprüfen Sie Ihre Eingabe. </mea-error>
    }
    @if (pharmacy$ | async; as pharmacy) {
      <div class="mea-text-s">
        Die mit diesem Formular erhobenen Daten werden durch die {{ pharmacy.name }} zum Zwecke der Vorbereitung der
        Durchführung der Termine verarbeitet. Weitere Informationen finden Sie in
        @if (pharmacy.privacyPolicyLink; as privacyPolicyLink) {
          @if (privacyPolicyLink.startsWith('https://')) {
            der Apotheken
            <a [href]="privacyPolicyLink" rel="noopener noreferrer" target="_blank">Datenschutzerklärung</a>
            und
          }
        }
        der mea
        <a href="https://www.meineapotheke.de/service/datenschutz" rel="noopener noreferrer" target="_blank"
          >Datenschutzerklärung</a
        >.
      </div>
    }
  </div>
</ion-content>
