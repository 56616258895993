import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ShopProductSearchEffects } from '../../../../libs/common/resources/src/common-store/shop-product-search/effects/shop-product-search.effects';
import { AppointmentV2Effects } from '../../../../libs/pharmacy-only/src/store/appointments/appointmentV2.effects';
import {
  PharmacySearchEffects,
  SEARCH_ROUTES,
} from '../../../../libs/pharmacy-only/src/store/pharmacy-search/pharmacy-search.effects';
import { TextblockEffects } from '../../../../libs/pharmacy-only/src/store/textblocks/effects/textblock.effects';
import { UserConsentEffects } from '../../../../libs/pharmacy-only/src/store/user-consent/user-consent.effects';
import { appointmentV2Reducer } from '../../../../libs/store/src/pharmacy/appointmentsV2/appointmentV2.reducer';
import { pharmacySearchReducer } from '../../../../libs/store/src/pharmacy/pharmacy-search/pharmacy-search.reducer';
import { textblockReducer } from '../../../../libs/store/src/pharmacy/textblocks/reducers/textblock.reducer';
import { userConsentReducer } from '../../../../libs/store/src/pharmacy/user-consent/user-consent.reducer';
import { HomeEffects } from './home/store/effects/home.effects';
import { homeReducer } from './home/store/reducers/home.reducer';
import { PharmacyRoute, searchRoutes } from './pharmacy-routes';
import { authPageGuard, homePageGuard } from './route-guards/route-guards';

export const APP_ROUTES: Routes = [
  { path: '', redirectTo: PharmacyRoute.Auth, pathMatch: 'full' },
  {
    path: PharmacyRoute.Auth,
    canActivate: [authPageGuard],
    loadChildren: () => import('./auth/auth-routing'),
  },
  {
    path: PharmacyRoute.SsoRedirect,
    loadComponent: () => import('./sso-redirect/sso-redirect.page').then((c) => c.SsoRedirectPage),
  },
  {
    path: PharmacyRoute.Home,
    canActivate: [homePageGuard],
    providers: [
      importProvidersFrom(
        StoreModule.forFeature('userConsent', userConsentReducer),
        StoreModule.forFeature('appointmentV2', appointmentV2Reducer),
        StoreModule.forFeature('home', homeReducer),
        StoreModule.forFeature('pharmacySearch', pharmacySearchReducer),
        StoreModule.forFeature('textblockData', textblockReducer),
        EffectsModule.forFeature([
          AppointmentV2Effects,
          HomeEffects,
          PharmacySearchEffects,
          ShopProductSearchEffects,
          TextblockEffects,
          UserConsentEffects,
        ])
      ),
      { provide: SEARCH_ROUTES, useValue: searchRoutes },
    ],
    loadChildren: () => import('./home/home-routing'),
  },
  {
    path: '**',
    loadComponent: () => import('./page-not-found/page-not-found.page').then((c) => c.PageNotFoundPage),
  },
];
