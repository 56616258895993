import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatUser } from '../../../../../essentials/types/src/chatUser';
import { Logger } from '../../../../../essentials/util/src/logger';
import { CommonState } from '../../../../../store/src/common-store/common.state';
import { setUserOnLogin } from '../../../../../store/src/common-store/user-store/actions/user.actions';
import { StorageService } from '../storage.service';

const logger = new Logger('PrivateKeyStoreService');

@Injectable({
  providedIn: 'root',
})
export class PrivateKeyStoreService {
  constructor(
    private storageService: StorageService,
    private store: Store<CommonState>
  ) {}

  async updatePrivateKeyInStorageAndSetUser(user: ChatUser) {
    logger.info('Updating private key in storage and setting user in store');
    const privateKeyFromStorage = await this.getPrivateKey(user.cognitoId);
    if (user.privateKey && privateKeyFromStorage !== user.privateKey) {
      await this.setPrivateKey(user.cognitoId, user.privateKey);
    }
    this.store.dispatch(setUserOnLogin({ user }));
  }

  async getPrivateKey(cognitoId: string): Promise<string | null> {
    try {
      const storageKey = this.getStorageKeyForPrivateKey(cognitoId);
      const privateKeyFromStorage = await this.storageService.get(storageKey);
      if (privateKeyFromStorage) {
        return privateKeyFromStorage;
      } else {
        return await this.migratePrivateKeyFromLocalStorageToStorageService(storageKey, cognitoId);
      }
    } catch (e) {
      return null;
    }
  }

  async setPrivateKey(cognitoId: string, privateKey: string) {
    const storageKey = this.getStorageKeyForPrivateKey(cognitoId);
    await this.storageService.set(storageKey, privateKey);
  }

  async removePrivateKey(cognitoId?: string) {
    if (cognitoId) {
      const storageKey = this.getStorageKeyForPrivateKey(cognitoId);
      localStorage.removeItem(storageKey);
      await this.storageService.remove(storageKey);
    }
  }

  private async migratePrivateKeyFromLocalStorageToStorageService(storageKey: string, cognitoId: string) {
    const privateKeyFromLocalStorage = localStorage.getItem(storageKey);
    if (privateKeyFromLocalStorage) {
      await this.setPrivateKey(cognitoId, privateKeyFromLocalStorage);
      localStorage.removeItem(storageKey);
      return privateKeyFromLocalStorage;
    } else {
      return null;
    }
  }

  private getStorageKeyForPrivateKey(cognitoId: string): string {
    return `${cognitoId}.privKey`;
  }
}
