import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';
import { ChatUser } from '../../../../../essentials/types/src/chatUser';

export const startAppsyncSubscriptions = createAction(
  `${ActionType.Subscription}: Start appsync subscriptions`,
  props<{ user: ChatUser }>()
);

export const stopAppsyncSubscriptions = createAction(`${ActionType.Subscription}: Stop appsync subscriptions`);

export const markDataWithSubscriptionsAsStale = createAction(
  `${ActionType.Subscription}: Mark data with subscriptions as stale`
);

export const checkUserAndRestartSubscriptions = createAction(
  `${ActionType.Subscription}: Check user and restart subscriptions`
);
