<div class="dropdown">
  <ion-item [class.show-dropdown]="showDropdown()" lines="none">
    <ion-label>
      <div (click)="toggleDropdown()" [class.placeholder]="!value" class="dropdown-header mea-headline-m">
        {{ (value | labelName) || (availableOptions.length ? placeholder : emptyPlaceholder) }}
        <ion-icon
          [class.disabled]="!availableOptions.length"
          [@animateArrow]="showDropdown() ? 'expanded' : 'collapsed'"
          [src]="'arrow_dropdown' | iconName"
          slot="end"
        ></ion-icon>
      </div>
    </ion-label>
  </ion-item>

  @if (showDropdown() && availableOptions) {
    <div class="dropdown-items">
      @for (option of availableOptions; track option) {
        <div (click)="setValue(option)" class="dropdown-item mea-headline-m">
          {{ option | labelName }}
        </div>
      }
    </div>
  }
</div>
