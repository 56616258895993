import { Action, createReducer, on } from '@ngrx/store';
import update from 'immutability-helper';
import isNil from 'lodash-es/isNil';
import omit from 'lodash-es/omit';
import { LoadStatus } from '../../../../../essentials/types/src/loadStatus';
import { newOwnBackendMessage } from '../../chat-store/actions/chat-message.actions';
import { markDataWithSubscriptionsAsStale } from '../../other/actions/subscription.actions';
import {
  clearUserOnLogout,
  loadInitialUser,
  rehydrateUser,
  resetUserState,
  setPushNotificationRepetitionInterval,
  setUserLoadStatus,
  setUserOnLogin,
  updateMePharmacy,
  updateUser,
} from '../actions/user.actions';
import { UserState } from '../state/user.state';

export const initialUserState: UserState = {
  pushNotificationRepetitionInterval: null,
  user: null,
  userLoadStatus: LoadStatus.Init,
};

const _userReducer = createReducer(
  initialUserState,

  on(loadInitialUser, (state) => update(state, { userLoadStatus: { $set: LoadStatus.LoadingInitial } })),

  on(setUserOnLogin, rehydrateUser, (state, { user }) =>
    update(state, {
      pushNotificationRepetitionInterval: {
        $set: isNil(user.pushNotificationRepetitionInterval) ? null : user.pushNotificationRepetitionInterval,
      },
      user: { $set: omit(user, 'pushNotificationRepetitionInterval') },
      userLoadStatus: { $set: LoadStatus.UpToDate },
    })
  ),

  on(updateUser, (state, { partialUser }) =>
    update(state, {
      user: { $merge: partialUser },
    })
  ),

  on(updateMePharmacy, (state, { pharmacy }) => update(state, { user: { pharmacy: { $merge: pharmacy } } })),

  on(clearUserOnLogout, () => ({
    ...initialUserState,
    userLoadStatus: LoadStatus.UpToDate,
  })),

  on(resetUserState, () => initialUserState),

  on(setPushNotificationRepetitionInterval, (state, { pushNotificationRepetitionInterval }) => ({
    ...state,
    pushNotificationRepetitionInterval,
  })),

  on(setUserLoadStatus, (state, { userLoadStatus }) => ({
    ...state,
    userLoadStatus,
  })),

  on(newOwnBackendMessage, (state, { message }) => {
    const numberOfProductCardsSent = message.media?.filter((media) => media.mediaType === 'SHOP_PRODUCT').length;
    if (numberOfProductCardsSent) {
      return update(state, {
        user: {
          productCardsSentLast30Days: (currentValue) =>
            currentValue ? currentValue + numberOfProductCardsSent : numberOfProductCardsSent,
        },
      });
    } else {
      return state;
    }
  }),

  on(markDataWithSubscriptionsAsStale, (state) => {
    if (state.userLoadStatus === LoadStatus.UpToDate || state.userLoadStatus === LoadStatus.Revalidating) {
      return update(state, { userLoadStatus: { $set: LoadStatus.Stale } });
    } else {
      return state;
    }
  })
);

export function userReducer(state: UserState | undefined, action: Action): UserState {
  return _userReducer(state, action);
}
