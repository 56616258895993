import { Conversation } from '../../types/src/conversation';

export class ChatPartnerMetadataUtil {
  public static getChatPartnerIdAsEnduser(conversation: Conversation): string {
    return conversation.chatPartner.cognitoId;
  }

  public static getChatPartnerIdAsPharmacy(conversation: Conversation): string {
    const chatPartner = conversation.chatPartner;
    return ChatPartnerMetadataUtil.getUserId(chatPartner);
  }

  public static getUserId({ cognitoId }: { cognitoId: string }) {
    return `cognitoId_${cognitoId}`;
  }
}
