import gql from 'graphql-tag';
import { appointmentV2TypeAttributes } from '../../../../common/resources/src/graphql/attributes';

export default gql`
  subscription deletedAppointmentV2Type($pharmacyCognitoId: ID!) {
    deletedAppointmentV2Type(pharmacyCognitoId: $pharmacyCognitoId) {
        ${appointmentV2TypeAttributes}
    }
  }
`;
