import { Component, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonicModule, IonInput } from '@ionic/angular';
import { IconNamePipe } from '../../../../../essentials/util/src/pipes/icon-name.pipe';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [FormsModule, IonicModule, IconNamePipe],
  templateUrl: './app-input.component.html',
  styleUrl: './app-input.component.scss',
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: AppInputComponent }],
})
export class AppInputComponent implements ControlValueAccessor {
  @ViewChild('input') input?: IonInput;

  @Input() placeholder?: string;
  @Input() disabled = false;
  @Input() markAsTouchedOnValueChange = true;
  @Input() type = 'text';
  @Input() maxlength: number | null = null;
  @Input() iconLeft?: string;
  @Input() autocapitalize = 'off';
  @Input() clearButton = true;

  @Input() set autofocus(autofocus: boolean) {
    if (autofocus) {
      setTimeout(() => {
        this.input?.setFocus();
      }, 100);
    }
  }

  value = '';
  touched = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  onValueChange(value: string) {
    if (this.markAsTouchedOnValueChange) {
      this.markAsTouched();
    }
    this.value = value;
    this.onChange(value);
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
