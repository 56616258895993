import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DEFAULT_TIME_ZONE } from '../../../common/resources/src/global-variables';
import { BlockingAppointmentV2 } from '../../types/src/appointmentV2';
import { AppointmentV2Type, LeadTimeInput } from '../../types/src/appointmentV2Type';
import { ChatUser } from '../../types/src/chatUser';
import { AppointmentClosingDayUtil } from './appointment-closing-day.util';
import { AppointmentTimeslotUtil } from './appointment-timeslot.util';

dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(timezone); // dependent on utc plugin

export interface PharmacyOpeningData {
  openingHours: string[];
  pharmacyChatUser: ChatUser;
}

export class AppointmentAvailabilityUtil {
  public static getAvailableDatesAndTimes({
    selectedAppointmentType,
    pharmacy,
    blockingAppointments,
    leadTime,
  }: {
    selectedAppointmentType: AppointmentV2Type;
    pharmacy: PharmacyOpeningData;
    blockingAppointments: BlockingAppointmentV2[];
    leadTime: LeadTimeInput;
  }): Record<string, { date: Dayjs; times: string[] }> {
    const currentTime = dayjs().tz(DEFAULT_TIME_ZONE);
    const earliestBookableStartTime = leadTime.respectLeadTime
      ? currentTime.add(selectedAppointmentType.leadTimeInHours, 'hour')
      : currentTime;
    const selectedDate = selectedAppointmentType.startDate?.isAfter(earliestBookableStartTime, 'hour')
      ? selectedAppointmentType.startDate
      : earliestBookableStartTime;

    const availableDates: Record<string, { date: Dayjs; times: string[] }> = {};
    for (
      let date = selectedDate;
      this.isLessThanThreeMonthsInFutureAndBeforeEndDate(date, selectedAppointmentType);
      date = date.add(1, 'day').tz(DEFAULT_TIME_ZONE)
    ) {
      if (this.isNotClosed(date, pharmacy)) {
        const availableTimes = this.getAvailableTimes({
          pharmacy,
          selectedAppointmentType,
          blockingAppointments,
          leadTime,
          selectedDate: date,
        });
        if (availableTimes) {
          availableDates[date.format('DD.MM.YYYY')] = { date, times: availableTimes };
        }
      }
    }
    return availableDates;
  }

  public static getAvailableTimes({
    pharmacy,
    selectedAppointmentType,
    blockingAppointments,
    leadTime,
    selectedDate,
  }: {
    pharmacy: PharmacyOpeningData;
    selectedAppointmentType: AppointmentV2Type;
    blockingAppointments: BlockingAppointmentV2[];
    leadTime: LeadTimeInput;
    selectedDate?: Dayjs;
  }): string[] | undefined {
    if (!selectedDate) {
      return undefined;
    }
    if (!selectedAppointmentType.timeslots[AppointmentTimeslotUtil.getArrayIndexFromWeekday(selectedDate)]) {
      return undefined;
    }
    const openingHours = pharmacy.openingHours[AppointmentTimeslotUtil.getArrayIndexFromWeekday(selectedDate)];
    const blockingAppointmentsOnDay = blockingAppointments.filter((appointment) =>
      appointment.date.isSame(selectedDate, 'day')
    );
    const availableTimeslots = AppointmentTimeslotUtil.determineAvailableTimeslots({
      date: selectedDate,
      blockingAppointmentsOnDay,
      leadTime,
      openingHours,
      selectedAppointmentType,
    });
    return availableTimeslots
      .map((timeslot) =>
        AppointmentTimeslotUtil.getAvailableTimeslotTimesForEnduser(
          timeslot,
          selectedDate,
          selectedAppointmentType,
          openingHours
        )
      )
      .flat();
  }

  private static isNotClosed(date: Dayjs, pharmacy: PharmacyOpeningData) {
    return !AppointmentClosingDayUtil.isClosed(date, pharmacy.pharmacyChatUser);
  }

  private static isLessThanThreeMonthsInFutureAndBeforeEndDate(date: Dayjs, appointmentType: AppointmentV2Type) {
    const currentTime = dayjs().tz(DEFAULT_TIME_ZONE);
    return !date.isAfter(appointmentType.endDate) && !date.isAfter(currentTime.add(3, 'months'));
  }
}
