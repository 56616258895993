import gql from 'graphql-tag';
import { appointmentV2Attributes } from '../../../../../common/resources/src/graphql/attributes';

export default gql`
    mutation cancelAppointmentV2(
        $id: ID!,
        $isCancelled: Boolean
    ) {
        updateAppointmentV2(
            id: $id,
            appointmentV2Update: {
                isCancelled: $isCancelled,
            }
        ) {
            ${appointmentV2Attributes}
        }
    }
`;
