import { FormControl } from '@angular/forms';
import { Dayjs } from 'dayjs';
import { AppointmentV2Attachment, BookedAppointmentV2Type } from './appointmentV2Type';

export const SEX = ['weiblich', 'männlich', 'keine Angabe'] as const;
export type Sex = (typeof SEX)[number];

export interface DecryptedAppointmentV2
  extends Omit<AppointmentV2Input, 'customerName' | 'customerAge' | 'customerSex'> {
  id: string;
  appointmentType: BookedAppointmentV2Type;
  pharmacyCognitoId: string;
  enduserCognitoId?: string;
  conversationId?: string;
  customerName: DecryptedCustomerField<string>;
  customerAge?: DecryptedCustomerField<number>;
  customerSex?: DecryptedCustomerField<Sex>;
}

export type BlockingAppointmentV2 = Pick<
  DecryptedAppointmentV2,
  'id' | 'appointmentType' | 'date' | 'time' | 'isCancelled'
> & {
  isCancelled?: false;
};

export type AppointmentV2InputForm = {
  [P in keyof AppointmentV2Input]: FormControl<AppointmentV2Input[P]>;
};

export interface AppointmentV2Input {
  appointmentType: BookedAppointmentV2Type | null;
  customerName: string;
  date: Dayjs;
  time: string | null;
  customerPhone?: string | null;
  customerEmail?: string | null;
  customerAge?: number;
  customerSex?: Sex;
  isCancelled?: boolean;
}

export interface BackendAppointmentV2 extends BackendAppointmentV2Input {
  id: string;
  attachments?: AppointmentV2Attachment[];
}

export interface BackendAppointmentV2Input {
  pharmacyCognitoId: string;
  dateTime: string;
  telephone?: string;
  email?: string;
  appointmentTypeId: string;
  mandatoryCustomerFields: MandatoryAppointmentFields;
  additionalInformation?: string;
  durationInMinutes: number;
  name: string;
  enduserCognitoId?: string;
  conversationId?: string;
  isCancelled?: boolean;
}

export interface MandatoryAppointmentFields {
  name: EncryptedCustomerField;
  age?: EncryptedCustomerField;
  sex?: EncryptedCustomerField;
}

export interface EncryptedCustomerField {
  pharmacy: string;
  enduser?: string;
}

export type DecryptedCustomerField<T> =
  | { decryptionSuccess: true; value: T }
  | { decryptionSuccess: false; value?: undefined };
