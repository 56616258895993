import gql from 'graphql-tag';

export default gql`
  mutation updatePharmacyInitialMessages($cognitoId: ID!, $initialMessages: InitialMessagesInput) {
    updatePharmacyChatUser(cognitoId: $cognitoId, user: { initialMessages: $initialMessages }) {
      __typename
      cognitoId
      cognitoUsername
      initialMessages {
        askQuestion
        askQuestionClosed
        preorder
        preorderClosed
        productQuery
        productQueryClosed
      }
    }
  }
`;
