export interface ERezeptAttachment {
  eRezept: string[];
  mediaType: 'E_REZEPT';
}

export interface ImageBase64Attachment {
  image: string;
  text: string;
  mediaType: 'IMAGE_BASE64';
}

export interface AppointmentAttachment {
  text: string;
  calendarEvent: string;
  mediaType: 'APPOINTMENT';
}

export interface FileAttachment {
  mediaType: 'FILE' | 'IMAGE';
  file: File;
  thumbnailUrl?: string;
}

export interface FileToEncrypt {
  previewKey?: string;
  contentKey: string;
  filename: string;
  mediaType: 'FILE' | 'IMAGE';
}

export type ValidAttachment = FileAttachment | ERezeptAttachment | ImageBase64Attachment | AppointmentAttachment;
export type AttachmentsToSend = ValidAttachment[];

export type AttachmentToEncrypt = FileToEncrypt | ERezeptAttachment | ImageBase64Attachment | AppointmentAttachment;

export const isFileAttachment = (attachment: ValidAttachment): attachment is FileAttachment =>
  ['FILE', 'IMAGE'].includes(attachment.mediaType);

export const isERezeptAttachment = (attachment: ValidAttachment): attachment is ERezeptAttachment =>
  ['E_REZEPT'].includes(attachment.mediaType);

export const isPdfFileAttachment = (attachment: ValidAttachment): attachment is FileAttachment =>
  ['FILE'].includes(attachment.mediaType);

export const isImageFileAttachment = (attachment: ValidAttachment): attachment is FileAttachment =>
  ['IMAGE'].includes(attachment.mediaType);
