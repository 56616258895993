import { Injectable } from '@angular/core';
import Message from '../../../../../../../essentials/types/src/message';
import { AlertComponent } from '../../../../../../ui-components/src/ionic/alert/alert.component';
import { CustomModalController } from '../../../../../../ui-components/src/ionic/controllers/custom-modal.controller';
import { AppsyncMessageService } from '../../../../services/appsync/appsync-message.service';

@Injectable({
  providedIn: 'root',
})
export class DeleteMessageService {
  constructor(
    private appsyncMessageService: AppsyncMessageService,
    private modalController: CustomModalController
  ) {}

  async askForConfirmationAndDeleteMessage(message: Message, confirmDeletion = true) {
    const confirmed = confirmDeletion ? await this.askForConfirmation() : true;
    if (confirmed) {
      await this.appsyncMessageService.deleteMessage(message.id);
    }
  }

  private async askForConfirmation() {
    const modal = await this.modalController.create({
      component: AlertComponent,
      cssClass: 'mea-alert',
      componentProps: {
        header: 'Nachricht löschen',
        body: 'Wollen Sie diese Nachricht aus dem Chatverlauf entfernen?',
        buttons: [
          { type: 'dark-outline', text: 'Abbrechen', role: 'cancel' },
          { type: 'danger', text: 'Löschen', role: 'continue' },
        ],
      },
    });
    await modal.present();
    const result = await modal.onDidDismiss();

    return result.role === 'continue';
  }
}
