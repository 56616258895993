import gql from 'graphql-tag';
import { appointmentV2TypeAttributes } from '../../../../../common/resources/src/graphql/attributes';

export default gql`
  mutation deleteAppointmentV2Type($id: ID!) {
    deleteAppointmentV2Type(id: $id) {
        ${appointmentV2TypeAttributes}
    }
  }
`;
