import { inject, Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { MimeTypeEnum } from '../../../../../essentials/types/src/mimeTypeEnum';
import { DataUrlUtil } from '../../../../../essentials/util/src/chat/data-url.util';
import { EncryptionService } from '../encryption/encryption.service';
import { S3Service } from '../s3.service';

@Injectable({ providedIn: 'root' })
export class FileUploadService {
  private s3Service = inject(S3Service);
  private encryptionService = inject(EncryptionService);

  async encryptAndUpload(
    fileAsDataUrl: string,
    conversationPassword: string,
    keyOfAttachment: string,
    contentType: MimeTypeEnum
  ) {
    const encryptedContent = this.encryptDataUrlContent(fileAsDataUrl, conversationPassword);
    await this.uploadAttachment(keyOfAttachment, encryptedContent, contentType);
  }

  getKeyForAttachment(senderId: string, recipientId: string): string {
    return `${senderId}/${recipientId}/${new Date().toISOString()}_${uuid()}`;
  }

  getKeyForPreview(keyOfImage: string): string {
    return `${keyOfImage}_preview`;
  }

  private async uploadAttachment(key: string, data: Buffer | Blob, contentType: MimeTypeEnum) {
    await this.s3Service.put(key, data, {
      contentType,
    });
  }

  private encryptDataUrlContent(dataUrl: string, conversationPassword: string): Buffer {
    const contentString = DataUrlUtil.extractContentFromDataUrl(dataUrl);
    const encryptedContent = this.encryptionService.encryptUsingPassword(contentString, conversationPassword);
    return Buffer.from(encryptedContent, 'base64');
  }
}
