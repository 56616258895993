import gql from 'graphql-tag';

export default gql`
  subscription updatedUserConversation($ownerId: ID!) {
    updatedUserConversation(ownerId: $ownerId) {
      __typename
      id
      ownerId
      conversationId
      participants {
        __typename
        cognitoId
        chatname
        isGuest
        isAppUser
        userType
        userStatus
        publicKey
      }
      reminderNotification
      showReminder
      conversation {
        firstSegmentId
        archivedByEnduser
        deletionRecord {
          cognitoId
          deletedAt
        }
        earliestExpirationTimestamp
        encryptedAppointment
        encryptedShoppingCart
        appointmentId
        ticketHistory {
          timestamp
          updatedStatus
          updatedBy
          accepted
        }
      }
      conversationLink
      encryptedChatPartnerNickname
    }
  }
`;
