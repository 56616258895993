import { AsyncPipe } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppointmentFormService } from '../../../../../common/resources/src/services/appointmentsV2/appointment-form.service';
import { AppsyncCommonAppointmentService } from '../../../../../common/resources/src/services/appsync/appsync-common-appointment.service';
import { IconButtonComponent } from '../../../../../common/ui-components/src/buttons/icon-button/icon-button.component';
import { MeaButtonComponent } from '../../../../../common/ui-components/src/buttons/mea-button/mea-button.component';
import { ErrorComponent } from '../../../../../common/ui-components/src/errors/error/error.component';
import { AppCircularSelectComponent } from '../../../../../common/ui-components/src/inputs/app-circular-select/app-circular-select.component';
import { AppInputComponent } from '../../../../../common/ui-components/src/inputs/app-input/app-input.component';
import { InputComponent } from '../../../../../common/ui-components/src/inputs/input/input.component';
import { CustomModalController } from '../../../../../common/ui-components/src/ionic/controllers/custom-modal.controller';
import { LinkComponent } from '../../../../../common/ui-components/src/navigation/link/link.component';
import { SuccessComponent } from '../../../../../common/ui-components/src/styling/success/success.component';
import { BackendAppointmentV2Input, SEX } from '../../../../../essentials/types/src/appointmentV2';
import { AppointmentV2Type } from '../../../../../essentials/types/src/appointmentV2Type';
import { ChatUser } from '../../../../../essentials/types/src/chatUser';
import { RemoveCommonChatDuettFields } from '../../../../../essentials/types/src/common-chat-duett-fields';
import { BookAppointment } from '../../../../../essentials/types/src/duett-schema/de.meineapotheke.chat/book_appointment/jsonschema/1-2-0';
import { RequestState } from '../../../../../essentials/types/src/requestState';
import { AppointmentTimeslotUtil } from '../../../../../essentials/util/src/appointment-timeslot.util';
import { Logger } from '../../../../../essentials/util/src/logger';
import { BerlinTimePipe } from '../../../../../essentials/util/src/pipes/berlin-time.pipe';
import { IconNamePipe } from '../../../../../essentials/util/src/pipes/icon-name.pipe';
import { isNotNullOrUndefined } from '../../../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { selectActiveConversation } from '../../../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { CommonState } from '../../../../../store/src/common-store/common.state';
import { analyticsDuettEvent } from '../../../../../store/src/common-store/other/actions/common-analytics.actions';

const logger = new Logger('AppointmentConfirmationComponent');

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
  selector: 'appointment-confirmation',
  templateUrl: './appointment-confirmation.component.html',
  styleUrls: ['./appointment-confirmation.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    IconButtonComponent,
    InputComponent,
    FormsModule,
    ReactiveFormsModule,
    LinkComponent,
    MeaButtonComponent,
    SuccessComponent,
    ErrorComponent,
    AsyncPipe,
    TranslateModule,
    BerlinTimePipe,
    IconNamePipe,
    AppCircularSelectComponent,
    AppInputComponent,
  ],
  providers: [AppointmentFormService],
})
export class AppointmentConfirmationComponent implements OnInit {
  @Input({ required: true }) date!: string;
  @Input({ required: true }) time!: string;
  @Input({ required: true }) appointmentType!: AppointmentV2Type;

  protected appointmentFormService = inject(AppointmentFormService);
  private modalCtrl = inject(CustomModalController);
  private appsyncCommonAppointmentService = inject(AppsyncCommonAppointmentService);
  private store: Store<CommonState> = inject(Store);

  private chatPartner$ = this.store
    .select(selectActiveConversation)
    .pipe(map((conversation) => conversation?.chatPartner as ChatUser));
  protected pharmacy$ = this.chatPartner$.pipe(map((chatPartner) => chatPartner?.pharmacy));
  private pharmacyPublicKey$ = this.chatPartner$.pipe(map((chatPartner) => chatPartner?.publicKey));

  protected showValidationErrorMessage = false;
  protected bookAppointmentRequestState = RequestState.Init;
  protected dropdownSexOptions = [...SEX];

  ngOnInit(): void {
    this.appointmentFormService.adjustFormGroup(this.appointmentType);
    this.appointmentFormService.appointmentFormGroup.controls.appointmentType.patchValue(this.appointmentType);
    this.appointmentFormService.appointmentFormGroup.controls.date.patchValue(dayjs(this.date, 'DD.MM.YYYY'));
    this.appointmentFormService.appointmentFormGroup.controls.time.patchValue(this.time);
    this.appointmentFormService.requireCustomerEmail();
  }

  async bookAppointment() {
    this.showValidationErrorMessage = false;
    this.bookAppointmentRequestState = RequestState.Init;
    if (!this.appointmentFormService.appointmentFormGroup.valid) {
      this.appointmentFormService.highlightFormGroupErrorsAfterSubmitting();
      this.showValidationErrorMessage = true;
      return;
    }
    try {
      this.bookAppointmentRequestState = RequestState.InProgress;
      const form = this.appointmentFormService.appointmentFormGroup.controls;
      const pharmacyCognitoId = (await firstValueFrom(this.pharmacy$.pipe(isNotNullOrUndefined()))).cognitoId;
      const appointmentType = form.appointmentType.value as AppointmentV2Type;
      const dateTime = this.appointmentFormService.getDateTime();
      const publicKey = await firstValueFrom(this.pharmacyPublicKey$);
      const mandatoryCustomerFields = await this.appointmentFormService.encryptMandatoryCustomerFields(publicKey);
      if (!mandatoryCustomerFields) {
        logger.error('Encrypting mandatory customer fields failed');
        this.bookAppointmentRequestState = RequestState.Error;
        return;
      }

      const appointmentBookingInput: BackendAppointmentV2Input = {
        pharmacyCognitoId,
        dateTime,
        mandatoryCustomerFields,
        telephone: form.customerPhone?.value ?? undefined,
        email: form.customerEmail?.value ?? undefined,
        durationInMinutes: appointmentType.durationInMinutes,
        name: appointmentType.name,
        appointmentTypeId: appointmentType.id,
        additionalInformation: appointmentType.additionalInformation,
      };

      await this.appsyncCommonAppointmentService.bookAppointmentV2WithoutMapping(appointmentBookingInput);
      this.bookAppointmentRequestState = RequestState.Success;
      this.trackBookAppointmentEvent();
    } catch (e: any) {
      logger.error('error booking appointment', e);
      this.bookAppointmentRequestState = RequestState.Error;
    }
  }

  calculateEndTime(time: string, appointmentType: AppointmentV2Type) {
    return AppointmentTimeslotUtil.calculateEndTime(time, appointmentType);
  }

  async closeModal() {
    await this.modalCtrl.dismiss();
  }

  private trackBookAppointmentEvent() {
    const event: RemoveCommonChatDuettFields<BookAppointment> = {
      event_type: 'book_appointment',
    };
    this.store.dispatch(analyticsDuettEvent({ event }));
  }
}
