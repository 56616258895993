import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { navigateToLoginPage } from '../../../../../../libs/store/src/common-store/other/actions/navigation.actions';
import { PharmacyPage } from '../../pharmacy-routes';

@Injectable()
export class NavigationEffects {
  navigateToLoginPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateToLoginPage),
        tap(async ({ loginQueryParams }) => {
          await this.router.navigate(PharmacyPage.login, { queryParams: loginQueryParams });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router
  ) {}
}
