import { Dayjs } from 'dayjs';
import Address from './address';
import { BackendChatUser } from './backendChatUser';
import { ChatUser } from './chatUser';
import { EmergencyOpeningStatus } from './openingHours';

export interface BasePharmacy {
  // pharmacyId
  id?: string;
  cognitoId?: string;
  address: Address;
  contactEmail?: string;
  sanacorpCustomerId: string;
  // array of opening timings, e.g.
  // ['Closed', 'Closed', 'Closed', '08:00 -- 14:00, 15:00 -- 20:00', 'Closed', '08:00 -- 14:00', 'Closed'];
  openingHours: string[];
  notdienst?: NotdienstEntry[];
  contactName: string;
  description?: string;
  name: string;
  shippingDetails?: string;
  telephone?: string;
  website?: string;
  disabled: boolean;
  privacyPolicyLink?: string;
  onlineShop?: string;
  shopApiBaseUrl?: string;
  detailsPage?: string;
  logo?: string;
  streetView?: string;
  gedisaApothekenId?: string;
  cardLinkAktiv?: boolean;
  inventoryManagementConnection?: boolean;
}

export interface BackendPharmacy extends BasePharmacy {
  pharmacyChatUser?: BackendChatUser;
}

export interface Pharmacy extends BasePharmacy {
  pharmacyChatUser?: Omit<ChatUser, 'pharmacy'>;
  removed?: boolean;
  chatAddedOrRemoved?: boolean;
}

export interface PharmacyWithChatUser extends Pharmacy {
  id: string;
  cognitoId: string;
  pharmacyChatUser: Omit<ChatUser, 'pharmacy'>;
}

export interface BackendPharmacyWithChatUser extends BackendPharmacy {
  id: string;
  cognitoId: string;
  pharmacyChatUser: Omit<BackendChatUser, 'pharmacy'>;
}

export interface GroupedAppLocationsAndPharmacies {
  locations: AppLocation[];
  pharmacies: Pharmacy[];
}

export interface GroupedAppLocationsAndBackendPharmacies {
  locations: AppLocation[];
  pharmacies: BackendPharmacy[];
}

export interface AppLocation {
  description: string;
  placeId: string;
  types: string[];
}

export enum OpeningStatusClass {
  Open = 'status-open',
  Closed = 'status-closed',
}

export interface PharmacyOpeningStatus {
  text: string;
  class: OpeningStatusClass;
}

export interface PharmacyDynamicProperties {
  isOpen: boolean;
  wasOpen: boolean;
  willOpenSoon: boolean;
  currCloseDiff: number;
  openingHoursFormatted: OpeningHoursWeek;
  currRange: OpeningHoursRange | null;
  nextRange: OpeningHoursRange | null;
  isOnEmergencyDuty: boolean;
  emergencyDutyStartsSoon: boolean;
  emergencyDutyStartDiff: number;
  emergencyDutyEndsSoon: boolean;
  emergencyDutyToday: EmergencyHoursRange | null;
  emergencyHoursFormatted: UpcomingEmergencyHours;
  emergencyOpeningStatus: EmergencyOpeningStatus;
  isOnHoliday: boolean;
  vacationRanges: UpcomingVacationRanges;
  isOnVacation: boolean;
  vacationEndDate: Dayjs | null;
}

export type OpeningHoursWeek = OpeningHoursDay[];
export type OpeningHoursDay = OpeningHoursEntry[];

export interface OpeningHoursEntry {
  range: OpeningHoursRange;
  wasOpen: boolean;
  isOpen: boolean;
  willOpen: boolean;
  closeDiff: number;
}

export interface OpeningHoursRange {
  from: Dayjs;
  to: Dayjs;
}

export type UpcomingEmergencyHours = EmergencyHoursRange[];

export interface EmergencyHoursRange {
  from: Dayjs;
  to: Dayjs;
}

export type UpcomingVacationRanges = VacationRange[];

export interface VacationRange {
  from: Dayjs;
  to: Dayjs;
}

export interface NotdienstEntry {
  von: string;
  bis: string;
}

export interface PharmacyLoginDetails {
  id: string;
  contactName?: string;
  cognitoId?: string;
  logo?: string;
  streetView?: string;
}

export default Pharmacy;

export function isChatPharmacy(pharmacy?: Pharmacy): pharmacy is PharmacyWithChatUser {
  return !!pharmacy?.pharmacyChatUser && !!pharmacy.cognitoId;
}

export function isActiveChatPharmacy(pharmacy?: Pharmacy): pharmacy is PharmacyWithChatUser {
  return isChatPharmacy(pharmacy) && !pharmacy.disabled && !pharmacy.removed;
}
