import { Injectable } from '@angular/core';
import { AppContext } from '../../../../essentials/types/src/appContext';
import BackendUserConversation from '../../../../essentials/types/src/backendUserConversation';
import { ChatUser } from '../../../../essentials/types/src/chatUser';
import { ConversationIntent } from '../../../../essentials/types/src/conversationIntent';
import { AppsyncConversationService } from './appsync/appsync-conversation.service';
import { EncryptionService } from './encryption/encryption.service';

export interface ConversationKeyPair {
  myEncryptedConversationPassword: string;
  otherParticipantEncryptedConversationPassword: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConversationCreationService {
  constructor(
    private appsyncConversationService: AppsyncConversationService,
    private encryptionService: EncryptionService
  ) {}

  async createNewConversation(
    currentUser: ChatUser,
    chatPartner: ChatUser,
    variables: { intent?: ConversationIntent; appContext?: AppContext; earliestExpirationTimestamp?: number } = {}
  ): Promise<BackendUserConversation> {
    const createdConversation = await this.appsyncConversationService.createConversation(variables);
    return this.createUserConversations(currentUser, createdConversation.id, chatPartner);
  }

  createEncryptedConversationPasswords(ownPublicKey: string, chatPartnerPublicKey: string): ConversationKeyPair {
    const password = this.encryptionService.generateOneTimehashPassword();
    const myEncryptedConversationPassword = this.encryptionService.encryptUsingPublicKey(password, ownPublicKey);

    const otherParticipantEncryptedConversationPassword = this.encryptionService.encryptUsingPublicKey(
      password,
      chatPartnerPublicKey
    );
    if (myEncryptedConversationPassword === false || otherParticipantEncryptedConversationPassword === false) {
      throw new Error('Failed to create encrypted conversation passwords');
    }
    return { myEncryptedConversationPassword, otherParticipantEncryptedConversationPassword };
  }

  private async createUserConversations(
    currentUser: ChatUser,
    conversationId: string,
    chatPartner: ChatUser
  ): Promise<BackendUserConversation> {
    const { myEncryptedConversationPassword, otherParticipantEncryptedConversationPassword } =
      this.createEncryptedConversationPasswords(currentUser.publicKey, chatPartner.publicKey);

    const userConversations = await this.appsyncConversationService.createUserConversations({
      otherParticipantId: chatPartner.cognitoId,
      conversationId,
      myEncryptedConversationPassword,
      otherParticipantEncryptedConversationPassword,
    });

    if (userConversations && userConversations.userConversations) {
      const mine = userConversations.userConversations.find(
        (c: BackendUserConversation) => c.ownerId === currentUser.cognitoId
      );
      if (mine) {
        return mine;
      }
    }
    throw new Error('failed to create user conversations');
  }
}
