import { inject, Injectable } from '@angular/core';
import getShopCoopSecrets from '../../../common/resources/src/graphql/mutations/getShopCoopSecrets';
import { AppsyncService } from '../../../common/resources/src/services/appsync/appsync.service';
import { ShopCoopSecrets } from '../../../essentials/types/src/shopSale';
import { Logger } from '../../../essentials/util/src/logger';

const logger = new Logger('AppsyncShopCoopService');

@Injectable({ providedIn: 'root' })
export class AppsyncShopCoopService {
  private appsync = inject(AppsyncService);

  // ************* Mutations *************

  async getShopCoopSecrets(): Promise<ShopCoopSecrets | null> {
    const client = await this.appsync.getClient();
    const { data } = await client.mutate({ mutation: getShopCoopSecrets });
    if (data.getShopCoopSecrets) {
      return data.getShopCoopSecrets;
    } else {
      logger.error('Failed to get Shop Coop Secrets');
      return null;
    }
  }
}
