import gql from 'graphql-tag';
import { appointmentV2Attributes } from '../attributes';

export default gql`
  mutation bookAppointmentV2(
    $pharmacyCognitoId: ID!, 
    $dateTime: AWSDateTime!, 
    $telephone: String, 
    $email: String, 
    $appointmentTypeId: ID!, 
    $mandatoryCustomerFields: MandatoryCustomerFieldsInput!,
    $additionalInformation: String, 
    $durationInMinutes: Int!, 
    $name: String!
    $enduserCognitoId: ID,
    $conversationId: ID,
  ) {
    bookAppointmentV2(
      appointmentV2: { 
        pharmacyCognitoId: $pharmacyCognitoId, 
        dateTime: $dateTime, 
        telephone: $telephone, 
        email: $email, 
        appointmentTypeId: $appointmentTypeId, 
        mandatoryCustomerFields: $mandatoryCustomerFields,
        additionalInformation: $additionalInformation, 
        durationInMinutes: $durationInMinutes, 
        name: $name 
        enduserCognitoId: $enduserCognitoId,
        conversationId: $conversationId,
      }
    ) {
      ${appointmentV2Attributes}
    }
  }
`;
