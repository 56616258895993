interface IndividualAppsyncError {
  [key: string]: any;
}

interface AppsyncResponse {
  data: { [key: string]: any } | boolean | null;
  errors: IndividualAppsyncError[] | null;
}

interface AppsyncError {
  data: { [key: string]: any } | boolean | null;
  errors: IndividualAppsyncError[];
}

export class AppsyncErrorUtil {
  static isAppsyncError(response: unknown): response is AppsyncError {
    return this.isAppsyncResponse(response) && !!response.errors;
  }

  static isOpenSearchNotFoundError(error: IndividualAppsyncError) {
    return error['message'] === 'OpenSearch responded with an error: Not Found';
  }

  private static isAppsyncResponse(response: unknown): response is AppsyncResponse {
    return !!(typeof response === 'object' && response?.hasOwnProperty('data') && response.hasOwnProperty('errors'));
  }
}
