import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { debounceTime, filter, firstValueFrom, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs';
import { Dictionary } from 'ts-essentials';
import { DEFAULT_TIME_ZONE } from '../../../../common/resources/src/global-variables';
import { AppsyncCommonAppointmentService } from '../../../../common/resources/src/services/appsync/appsync-common-appointment.service';
import { AppointmentEncryptionService } from '../../../../common/resources/src/services/encryption/appointment-encryption.service';
import { SubscriptionManagementService } from '../../../../common/resources/src/services/subscriptions/subscription-management.service';
import { CustomToastController } from '../../../../common/ui-components/src/ionic/controllers/custom-toast.controller';
import { Appointment, AppointmentMigrateInput } from '../../../../essentials/types/src/appointment';
import { ChatUser } from '../../../../essentials/types/src/chatUser';
import { Conversation } from '../../../../essentials/types/src/conversation';
import { LoadStatus } from '../../../../essentials/types/src/loadStatus';
import { AppointmentUtil } from '../../../../essentials/util/src/appointment.util';
import { Logger } from '../../../../essentials/util/src/logger';
import { isNotNullOrUndefined } from '../../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { loadConversations } from '../../../../store/src/common-store/chat-store/actions/chat-conversation.actions';
import { selectConversationDictionary } from '../../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { CommonState } from '../../../../store/src/common-store/common.state';
import { startAppsyncSubscriptions } from '../../../../store/src/common-store/other/actions/subscription.actions';
import { selectCognitoId, selectUser } from '../../../../store/src/common-store/user-store/selectors/user.selectors';
import {
  deleteAppointmentV2Type,
  loadAppointmentsV2,
  loadAppointmentsV2Failure,
  loadAppointmentsV2Success,
  loadAppointmentV2,
  loadAppointmentV2Failure,
  loadAppointmentV2Initialized,
  loadAppointmentV2Success,
  loadAppointmentV2Types,
  loadAppointmentV2TypesFailure,
  loadAppointmentV2TypesSuccess,
  setAppointmentV2,
  setAppointmentV2Type,
} from '../../../../store/src/pharmacy/appointmentsV2/appointmentV2.actions';
import {
  pipeUpToDateMonths,
  selectActiveMonths,
  selectAppointmentsV2LoadStatusDictionary,
  selectAppointmentV2IdsLoadStatusDictionary,
  selectAppointmentV2TypesLoadStatus,
} from '../../../../store/src/pharmacy/appointmentsV2/appointmentV2.selectors';
import { AppointmentV2State } from '../../../../store/src/pharmacy/appointmentsV2/appointmentV2.state';
import { AppsyncPharmacyAppointmentService } from '../../services/appsync-pharmacy-appointment.service';

const logger = new Logger('AppointmentV2Effects');

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(timezone); // dependent on utc plugin

@Injectable()
export class AppointmentV2Effects {
  private actions$ = inject(Actions);
  private store: Store<CommonState & { appointmentV2: AppointmentV2State }> = inject(Store);
  private appointmentEncryptionService = inject(AppointmentEncryptionService);
  private appsyncPharmacyAppointmentService = inject(AppsyncPharmacyAppointmentService);
  private appsyncCommonAppointmentService = inject(AppsyncCommonAppointmentService);
  private subscriptionManagementService = inject(SubscriptionManagementService);
  private toastController = inject(CustomToastController);

  private readonly initialMonths = AppointmentUtil.getAppointmentMonthsForMonth(dayjs().tz(DEFAULT_TIME_ZONE));

  // ************* AppointmentV2Types *************

  initOrRevalidateAppointmentV2Types$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startAppsyncSubscriptions),
      withLatestFrom(this.store.select(selectAppointmentV2TypesLoadStatus)),
      filter(([_, appointmentV2TypesLoadStatus]) =>
        [LoadStatus.Init, LoadStatus.Error, LoadStatus.Stale].includes(appointmentV2TypesLoadStatus)
      ),
      map(() => loadAppointmentV2Types())
    )
  );

  loadAppointmentV2Types$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAppointmentV2Types),
      withLatestFrom(this.store.select(selectCognitoId).pipe(isNotNullOrUndefined())),
      switchMap(async ([_, cognitoId]) => {
        try {
          const appointmentV2Types = await this.appsyncCommonAppointmentService.getAppointmentV2Types(cognitoId);
          return loadAppointmentV2TypesSuccess({ appointmentV2Types });
        } catch (e) {
          logger.error('Error while loading appointmentV2Types', e);
          return loadAppointmentV2TypesFailure();
        }
      })
    )
  );

  subscribeToAppointmentV2TypesUpdates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadAppointmentV2TypesSuccess),
        withLatestFrom(this.store.select(selectCognitoId).pipe(isNotNullOrUndefined())),
        tap(([_, cognitoId]) => this.subscribeToAppointmentV2TypesUpdates(cognitoId))
      ),
    { dispatch: false }
  );

  // ************* AppointmentsV2 *************

  initOrRevalidateAppointmentsV2$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startAppsyncSubscriptions),
        withLatestFrom(
          this.store.select(selectAppointmentsV2LoadStatusDictionary),
          this.store
            .select(selectActiveMonths)
            .pipe(map((activeMonths) => new Set<string>([...this.initialMonths, ...activeMonths]))),
          this.store.select(selectUser).pipe(isNotNullOrUndefined())
        ),
        switchMap(async ([_, appointmentsV2LoadStatus, monthsToLoad, pharmacyUser]) => {
          if (!pharmacyUser.migratedToAppointmentsV2) {
            try {
              await this.migrateAppointmentsToV2(pharmacyUser);
              this.store.dispatch(loadAppointmentV2Types());
              this.store.dispatch(loadConversations({ conversationsLoadStatus: LoadStatus.Revalidating }));
            } catch (e) {
              logger.error('Error while migrating appointments to v2', e);
            }
          }
          const relevantLoadStatus = [LoadStatus.Init, LoadStatus.Error, LoadStatus.Stale, undefined];
          for (const month of monthsToLoad) {
            if (relevantLoadStatus.includes(appointmentsV2LoadStatus[month])) {
              this.store.dispatch(loadAppointmentsV2({ month }));
            }
          }
        })
      ),
    { dispatch: false }
  );

  loadAppointmentV2$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadAppointmentV2),
        withLatestFrom(this.store.select(selectAppointmentV2IdsLoadStatusDictionary)),
        mergeMap(async ([{ appointmentId }, loadStatusDictionary]) => {
          try {
            const appointmentLoadStatus = loadStatusDictionary[appointmentId];
            if (appointmentLoadStatus) {
              return;
            }
            this.store.dispatch(loadAppointmentV2Initialized({ appointmentId }));
            const appointmentV2 = await this.appsyncCommonAppointmentService.getAppointmentV2(appointmentId);
            this.store.dispatch(loadAppointmentV2Success({ appointmentV2 }));
          } catch (e) {
            logger.error('Error while loading appointmentV2', e);
            this.store.dispatch(loadAppointmentV2Failure({ appointmentId }));
          }
        })
      ),
    { dispatch: false }
  );

  loadAppointmentsV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAppointmentsV2),
      withLatestFrom(this.store.select(selectCognitoId).pipe(isNotNullOrUndefined())),
      mergeMap(async ([{ month }, cognitoId]) => {
        try {
          const appointmentsV2 = await this.appsyncCommonAppointmentService.getAppointmentsV2(cognitoId, month);
          return loadAppointmentsV2Success({ appointmentsV2, month });
        } catch (e) {
          logger.error('Error while loading appointmentsV2', e);
          return loadAppointmentsV2Failure({ month });
        }
      })
    )
  );

  handleAppointmentsV2Failure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadAppointmentsV2Failure),
        debounceTime(500),
        tap(async () => {
          const message = 'APPOINTMENT_BOOKING.LOADING_ERROR';
          const buttons = [
            {
              text: 'Erneut versuchen',
              handler: async () => {
                await this.retryLoadingAppointments();
              },
            },
          ];
          if (!(await this.toastController.getTop())) {
            await this.toastController.createAndPresentToast({ message, buttons });
          }
        })
      ),
    { dispatch: false }
  );

  subscribeToAppointmentV2Updates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadAppointmentsV2Success),
        withLatestFrom(
          this.store.pipe(pipeUpToDateMonths),
          this.store.select(selectCognitoId).pipe(isNotNullOrUndefined())
        ),
        tap(([_, upToDateMonths, cognitoId]) => {
          if (upToDateMonths.length === this.initialMonths.length) {
            this.subscribeToAppointmentsV2Updates(cognitoId);
          }
        })
      ),
    { dispatch: false }
  );

  private async retryLoadingAppointments() {
    const loadStatusDictionary = await firstValueFrom(this.store.select(selectAppointmentsV2LoadStatusDictionary));
    const failedMonths = Object.entries(loadStatusDictionary)
      .filter(([_, loadStatus]) => loadStatus === LoadStatus.Error)
      .map(([month]) => month);
    failedMonths.forEach((month) => this.store.dispatch(loadAppointmentsV2({ month })));
  }

  private async migrateAppointmentsToV2(pharmacyUser: ChatUser) {
    logger.info('Migrate appointments to v2');
    const conversations = await firstValueFrom(
      this.store.select(selectConversationDictionary).pipe(isNotNullOrUndefined())
    );
    const bookedAppointmentsV1 = (await this.appsyncPharmacyAppointmentService.getMyAppointments()).filter(
      (appointment) =>
        appointment.isBooked &&
        dayjs(appointment.dateTime).isSameOrAfter(dayjs().tz(DEFAULT_TIME_ZONE).subtract(2, 'week')) &&
        appointment.bookedByName !== 'NAME_REMOVED'
    );
    const decryptedAppointmentsV1 = this.appointmentEncryptionService.decryptAppointmentsV1(
      bookedAppointmentsV1,
      pharmacyUser.privateKey
    );
    const mappedAppointments = this.mapAppointmentsToV2(decryptedAppointmentsV1, conversations, pharmacyUser.publicKey);
    await this.appsyncPharmacyAppointmentService.migrateAppointmentsToV2(mappedAppointments);
  }

  private mapAppointmentsToV2(
    decryptedAppointmentsV1: Appointment[],
    conversations: Dictionary<Conversation>,
    pharmacyPublicKey: string
  ): AppointmentMigrateInput[] {
    return decryptedAppointmentsV1.map((appointment) => {
      let enduserPublicKey: string | undefined;
      const conversation = appointment.decryptedConversationId
        ? conversations[appointment.decryptedConversationId]
        : undefined;
      if (conversation) {
        enduserPublicKey = conversation.chatPartner.publicKey;
      }
      const mandatoryCustomerFields = appointment.bookedByName
        ? this.appointmentEncryptionService.encryptMandatoryCustomerFields(
            appointment.bookedByName,
            pharmacyPublicKey,
            enduserPublicKey
          )
        : { name: { pharmacy: '' } };

      return {
        pharmacyCognitoId: appointment.pharmacyCognitoId,
        dateTime: appointment.dateTime,
        durationMinutes: appointment.durationMinutes,
        selectedAppointmentType: appointment.selectedAppointmentType,
        mandatoryCustomerFields,
        bookedByEmail: appointment.bookedByEmail,
        conversationId: appointment.decryptedConversationId,
      };
    });
  }

  private subscribeToAppointmentV2TypesUpdates(cognitoId: string) {
    this.subscriptionManagementService.subscribe(
      'createdOrUpdatedAppointmentV2Type',
      this.appsyncPharmacyAppointmentService.createdOrUpdatedAppointmentV2Type(cognitoId),
      async (appointmentV2Type) => {
        this.store.dispatch(setAppointmentV2Type({ appointmentV2Type }));
      }
    );
    this.subscriptionManagementService.subscribe(
      'deletedAppointmentV2Type',
      this.appsyncPharmacyAppointmentService.deletedAppointmentV2Type(cognitoId),
      ({ id }) => this.store.dispatch(deleteAppointmentV2Type({ id }))
    );
  }

  private subscribeToAppointmentsV2Updates(cognitoId: string) {
    this.subscriptionManagementService.subscribe(
      'createdOrUpdatedAppointmentV2',
      this.appsyncPharmacyAppointmentService.createdOrUpdatedAppointmentV2(cognitoId),
      async (appointmentV2) => {
        const month = AppointmentUtil.getCurrentAppointmentMonth(appointmentV2.date);
        this.store.dispatch(setAppointmentV2({ appointmentV2, month }));
      }
    );
  }
}
