import gql from 'graphql-tag';
import { pharmacyAttributes, pharmacyPharmacyChatUserAttributes } from '../attributes';

export default gql`
  subscription osPharmacyUpdated($sanacorpCustomerId: ID!) {
    osPharmacyUpdated(sanacorpCustomerId: $sanacorpCustomerId) {
      ${pharmacyAttributes}
      pharmacyChatUser {
        ${pharmacyPharmacyChatUserAttributes}
      }
    }
  }
`;
