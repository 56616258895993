/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Dictionary } from 'ts-essentials';
import { ExifOrientation } from '../../../types/src/messageMedia';

@Injectable({
  providedIn: 'root',
})
export class ImageTransformationUtil {
  private static readonly THUMBNAIL_SIZE = 200;

  private static readonly counterclockwiseExifRotation: Dictionary<ExifOrientation, ExifOrientation> = {
    1: 6,
    8: 1,
    3: 8,
    6: 3,
    2: 7,
    7: 4,
    4: 5,
    5: 2,
  };

  static rotateAndDrawImage(
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    exifOrientation: ExifOrientation = 1,
    options: { maxWidth?: number; maxHeight?: number; removeTransparency?: boolean } = {}
  ) {
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    const { width, height } = this.setCanvasSizeForImageRotation(canvas, image, exifOrientation, options);
    this.rotate(exifOrientation, ctx, { width, height });

    if (options.removeTransparency) {
      this.removeTransparency(ctx, { width, height });
    }
    if (ctx) {
      ctx.drawImage(image, 0, 0, width, height);
    }
  }

  static getRotatedExifOrientation(exifOrientation: ExifOrientation | undefined): ExifOrientation {
    if (!exifOrientation) {
      return this.counterclockwiseExifRotation[1];
    }
    return this.counterclockwiseExifRotation[exifOrientation];
  }

  static drawThumbnail(canvas: any, vImage: HTMLImageElement) {
    const ctx = canvas.getContext('2d');
    const size = this.THUMBNAIL_SIZE;
    canvas.width = size;
    canvas.height = size;
    const { cropX, cropY, cropSize } = this.calculateCropDimensionsForThumbnail(vImage.height, vImage.width);
    ctx.drawImage(vImage, cropX, cropY, cropSize, cropSize, 0, 0, size, size);
  }

  static isSidewaysRotation(exifOrientation?: ExifOrientation): boolean {
    return !!exifOrientation && [5, 6, 7, 8].includes(exifOrientation);
  }

  private static setCanvasSizeForImageRotation(
    canvas: HTMLCanvasElement,
    image: HTMLImageElement,
    exifOrientation: ExifOrientation,
    {
      maxWidth,
      maxHeight,
    }: {
      maxWidth?: number;
      maxHeight?: number;
    } = {}
  ) {
    const isSidewaysRotation = this.isSidewaysRotation(exifOrientation);
    let canvasWidth = isSidewaysRotation ? image.height : image.width;
    let canvasHeight = isSidewaysRotation ? image.width : image.height;
    const ratio = canvasHeight / canvasWidth;

    if (maxWidth && canvasWidth > maxWidth) {
      canvasWidth = maxWidth;
      canvasHeight = maxWidth * ratio;
    }
    if (maxHeight && canvasHeight > maxHeight) {
      canvasHeight = maxHeight;
      canvasWidth = maxHeight / ratio;
    }
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    return {
      width: isSidewaysRotation ? canvasHeight : canvasWidth,
      height: isSidewaysRotation ? canvasWidth : canvasHeight,
    };
  }

  private static calculateCropDimensionsForThumbnail(
    height: number,
    width: number
  ): { cropX: number; cropY: number; cropSize: number } {
    let cropX;
    let cropY;
    let cropSize;
    if (height > width) {
      cropX = 0;
      cropY = (height - width) / 2;
      cropSize = width;
    } else {
      cropX = (width - height) / 2;
      cropY = 0;
      cropSize = height;
    }
    return { cropX, cropY, cropSize };
  }

  private static rotate(
    exifOrientation: ExifOrientation,
    ctx: CanvasRenderingContext2D,
    {
      width,
      height,
    }: {
      width: number;
      height: number;
    }
  ) {
    switch (exifOrientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, width, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, width, height);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, height);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, height, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, height, width);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, width);
        break;
      default:
        ctx.transform(1, 0, 0, 1, 0, 0);
    }
  }

  private static removeTransparency(
    ctx: CanvasRenderingContext2D,
    {
      width,
      height,
    }: {
      width: number;
      height: number;
    }
  ) {
    const imgData = ctx.getImageData(0, 0, width, height);
    const data = imgData.data;
    for (let i = 0; i < data.length; i += 4) {
      if ((data[i + 3] as number) < 255) {
        data[i] = 255;
        data[i + 1] = 255;
        data[i + 2] = 255;
        data[i + 3] = 255;
      }
    }
    ctx.putImageData(imgData, 0, 0);
  }
}
