import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { ChatUser } from '../../types/src/chatUser';
import { HolidayUtil } from './holiday.util';

dayjs.extend(isBetween);

export class AppointmentClosingDayUtil {
  static isClosed(date: Dayjs, pharmacy?: ChatUser) {
    return this.isSunday(date) || this.isOnHoliday(date, pharmacy) || this.isOnVacation(date, pharmacy);
  }

  private static isSunday(date: Dayjs) {
    return date.day() === 0;
  }

  private static isOnHoliday(date: Dayjs, pharmacy?: ChatUser) {
    const holidaySetting = pharmacy?.holidays;
    const postalCode = pharmacy?.pharmacy?.address.postalCode;
    return HolidayUtil.checkForHolidays(holidaySetting, postalCode, date);
  }

  private static isOnVacation(date: Dayjs, pharmacy?: ChatUser) {
    const vacationSetting = pharmacy?.vacation;
    if (!vacationSetting) {
      return false;
    }
    return !!HolidayUtil.isOnVacation(vacationSetting, date);
  }
}
