import gql from 'graphql-tag';

export interface GetPharmacyLoginDetailsByCustomerIdResult {
  getPharmacyLoginDetailsByCustomerId: { id: string; contactName?: string };
}

export default gql`
  query getPharmacyLoginDetailsByCustomerId($customerId: String!) {
    getPharmacyLoginDetailsByCustomerId(customerId: $customerId) {
      id
      contactName
      cognitoId
      logo
      streetView
    }
  }
`;
