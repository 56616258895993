import { Injectable } from '@angular/core';
import { Conversation } from '../../../../essentials/types/src/conversation';
import { MimeTypeEnum } from '../../../../essentials/types/src/mimeTypeEnum';
import {
  isShoppingCartFileMedia,
  isShoppingCartImageMedia,
  ShoppingCartMedia,
  SubmittedShoppingCart,
} from '../../../../essentials/types/src/shoppingCart';
import { Logger } from '../../../../essentials/util/src/logger';
import { AppsyncConversationService } from './appsync/appsync-conversation.service';
import { EncryptionService } from './encryption/encryption.service';
import { AttachmentPreparationService } from './message/attachment-preparation.service';
import { FileUploadService } from './message/file-upload.service';

const logger = new Logger('ShoppingCartSubmitService');

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartSubmitService {
  constructor(
    private appsyncConversationService: AppsyncConversationService,
    private encryptionService: EncryptionService,
    private fileUploadService: FileUploadService
  ) {}

  async updateShoppingCartInConversation(conversation: Conversation, shoppingCart: SubmittedShoppingCart) {
    const conversationPassword = conversation.segments[0]?.decryptedConversationPassword;
    if (!conversationPassword) {
      return false;
    }
    const encryptedShoppingCart = await this.encryptShoppingCartToSubmit(conversationPassword, shoppingCart);
    if (!encryptedShoppingCart) {
      return false;
    }
    try {
      await this.appsyncConversationService.updateShoppingCart({
        conversationId: conversation.id,
        encryptedShoppingCart,
      });
      return true;
    } catch (e) {
      logger.error('Error updating shopping cart in conversation', e);
      return false;
    }
  }

  private async encryptShoppingCartToSubmit(conversationPassword: string, shoppingCart: SubmittedShoppingCart) {
    for (const product of shoppingCart.products) {
      if (product.imageLinkS3) {
        product.imageUrl = undefined;
      }
    }
    for (const productImage of shoppingCart.productImages) {
      const uploadSuccess = await this.uploadImageOrFile(productImage, shoppingCart, conversationPassword);
      if (!uploadSuccess) {
        return undefined;
      }
    }
    for (const prescription of shoppingCart.prescriptions) {
      const uploadSuccess = await this.uploadImageOrFile(prescription, shoppingCart, conversationPassword);
      if (!uploadSuccess) {
        return undefined;
      }
    }
    const shoppingCartToSubmit: SubmittedShoppingCart = {
      enduserCognitoId: shoppingCart.enduserCognitoId,
      pharmacyCognitoId: shoppingCart.pharmacyCognitoId,
      prescriptions: shoppingCart.prescriptions,
      productImages: shoppingCart.productImages,
      products: shoppingCart.products,
      shopApiBaseUrl: shoppingCart.shopApiBaseUrl,
      sentToShopTimestamp: shoppingCart.sentToShopTimestamp,
    };
    return (
      this.encryptionService.encryptUsingPassword(JSON.stringify(shoppingCartToSubmit), conversationPassword) ||
      undefined
    );
  }

  private async uploadImageOrFile(
    shoppingCartMedia: ShoppingCartMedia,
    shoppingCart: SubmittedShoppingCart,
    conversationPassword: string
  ) {
    if (isShoppingCartImageMedia(shoppingCartMedia) && shoppingCartMedia.attachment.imageDataUrl) {
      const keyOfAttachment = this.fileUploadService.getKeyForAttachment(
        shoppingCart.enduserCognitoId,
        shoppingCart.pharmacyCognitoId
      );
      try {
        await this.fileUploadService.encryptAndUpload(
          shoppingCartMedia.attachment.imageDataUrl,
          conversationPassword,
          keyOfAttachment,
          AttachmentPreparationService.imageType
        );
      } catch (e) {
        logger.error('Error encrypting shopping cart image', e);
        return false;
      }
      shoppingCartMedia.attachment.imageDataUrl = undefined;
      shoppingCartMedia.attachment.imageDataKey = keyOfAttachment;
    }
    if (isShoppingCartFileMedia(shoppingCartMedia) && shoppingCartMedia.attachment.fileDataUrl) {
      const keyOfAttachment = this.fileUploadService.getKeyForAttachment(
        shoppingCart.enduserCognitoId,
        shoppingCart.pharmacyCognitoId
      );
      try {
        await this.fileUploadService.encryptAndUpload(
          shoppingCartMedia.attachment.fileDataUrl,
          conversationPassword,
          keyOfAttachment,
          MimeTypeEnum.PDF
        );
      } catch (e) {
        logger.error('Error encrypting shopping cart file', e);
        return false;
      }
      shoppingCartMedia.attachment.fileDataUrl = undefined;
      shoppingCartMedia.attachment.fileDataKey = keyOfAttachment;

      if (shoppingCartMedia.attachment.thumbnailUrl) {
        const keyOfThumbnail = this.fileUploadService.getKeyForPreview(keyOfAttachment);
        try {
          await this.fileUploadService.encryptAndUpload(
            shoppingCartMedia.attachment.thumbnailUrl,
            conversationPassword,
            keyOfThumbnail,
            AttachmentPreparationService.imageType
          );
        } catch (e) {
          logger.error('Error encrypting thumbnail for shopping cart file ', e);
          return false;
        }
        shoppingCartMedia.attachment.thumbnailUrl = undefined;
        shoppingCartMedia.attachment.thumbnailKey = keyOfThumbnail;
      }
    }
    return true;
  }
}
