import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { CustomAmplifyService } from '../../../common/resources/src/services/amplify.service';
import { AppsyncUserService } from '../../../common/resources/src/services/appsync/appsync-user.service';
import { AuthStateService } from '../../../common/resources/src/services/auth-state.service';
import { EncryptionService } from '../../../common/resources/src/services/encryption/encryption.service';
import { PrivateKeyAlertService } from '../../../common/resources/src/services/encryption/private-key-alert.service';
import {
  EncryptionResult,
  PrivateKeyBackupService,
} from '../../../common/resources/src/services/encryption/private-key-backup.service';
import { PrivateKeyStoreService } from '../../../common/resources/src/services/encryption/private-key-store.service';
import { PrivateKeyValidationService } from '../../../common/resources/src/services/encryption/private-key-validation.service';
import { ResetKeyPairService } from '../../../common/resources/src/services/encryption/reset-key-pair.service';
import { ChatUser } from '../../../essentials/types/src/chatUser';
import { PharmacyChatUserCreateInput } from '../../../essentials/types/src/pharmacyChatUserCreateInput';
import { Logger } from '../../../essentials/util/src/logger';
import { CommonState } from '../../../store/src/common-store/common.state';
import { deleteAllChatPartnerMetadata } from '../../../store/src/common-store/other/actions/chat-partner.actions';
import {
  analyticsCompletedResetKeyPair,
  analyticsLoginUser,
  analyticsStartResetKeyPair,
  analyticsUserLoginComplete,
} from '../../../store/src/common-store/other/actions/common-analytics.actions';
import { AppsyncPharmacyService } from './appsync-pharmacy.service';

const logger = new Logger('PharmacySignInService');

export const LOGIN_ROUTES = new InjectionToken<LoginRoutesInterface>('LOGIN_ROUTES');

export interface LoginRoutesInterface {
  home: string[];
  login: string[];
  backup: string[];
  restore: string[];
  getChatPage: (conversationId: string) => string[];
}

@Injectable({ providedIn: 'root' })
export class PharmacySignInService {
  private signInCache: { dbUserData: ChatUser | undefined; cognitoUser?: any } | null = null;

  constructor(
    private amplifyService: CustomAmplifyService,
    private appsyncPharmacyService: AppsyncPharmacyService,
    private appsyncUserService: AppsyncUserService,
    private authStateService: AuthStateService,
    private encryptionService: EncryptionService,
    private privateKeyAlertService: PrivateKeyAlertService,
    private privateKeyBackupService: PrivateKeyBackupService,
    private privateKeyStoreService: PrivateKeyStoreService,
    private privateKeyValidationService: PrivateKeyValidationService,
    private resetKeyPairService: ResetKeyPairService,
    private router: Router,
    private store: Store<CommonState>,
    @Inject(LOGIN_ROUTES) private loginRoutes: LoginRoutesInterface
  ) {}

  public isSigningIn() {
    return this.signInCache !== null;
  }

  public async signIn(username: string, password: string): Promise<any> {
    logger.info('Signing in pharmacy using Amplify');
    const cognitoUser = await this.amplifyService.auth().signIn(username.toLowerCase().trim(), password);
    this.store.dispatch(analyticsLoginUser());
    if (cognitoUser?.challengeName) {
      return cognitoUser;
    }
    await this.retrieveUserFromBackendAndRedirect(cognitoUser);
    this.store.dispatch(analyticsUserLoginComplete());
    return undefined;
  }

  public async retrieveUserFromBackendAndRedirect(cognitoUser: any, conversationId?: string) {
    const dbUserData = await this.appsyncUserService.getUserFromBackend('PHARMACY');
    if (!dbUserData) {
      this.signInCache = { cognitoUser, dbUserData };
      await this.router.navigate(this.loginRoutes.backup);
    } else {
      await this.signingInExistingDynamoDbUser(dbUserData, conversationId);
    }
  }

  public async signInContinue(password: string) {
    logger.info('Continue signIn');
    const signInCache = this.signInCache;
    if (!signInCache) {
      await this.router.navigate(this.loginRoutes.login);
      return;
    }

    let { dbUserData } = signInCache;
    if (!dbUserData) {
      dbUserData = await this.createNewPharmacyUser(signInCache.cognitoUser, password);
    } else {
      if (this.shouldResetKeyPair(dbUserData)) {
        this.store.dispatch(analyticsStartResetKeyPair());
        const { publicKey, privateKey, encryptionResult } = this.createKeyPairAndBackup(password);
        await this.resetKeyPairService.createNewConversationsForKeyPairResetPharmacy(publicKey, encryptionResult);
        this.store.dispatch(deleteAllChatPartnerMetadata());
        this.store.dispatch(analyticsCompletedResetKeyPair());
        dbUserData.privateKey = privateKey;
        dbUserData.publicKey = publicKey;
      } else if (!dbUserData.privateKey && dbUserData.encryptedPrivateKey && dbUserData.encryptionSalt) {
        logger.info('No private key, restoring backup');
        dbUserData.privateKey = await this.restorePrivateKeyPharmacy(
          dbUserData.encryptedPrivateKey,
          dbUserData.encryptionSalt,
          password
        );
        if (!this.privateKeyValidationService.isPrivateKeyValid(dbUserData.privateKey, dbUserData.publicKey)) {
          logger.error('Restored private key is not valid');
          await this.privateKeyAlertService.showNoPrivateKeyFoundToast();
          return;
        }
      } else if (dbUserData.privateKey && !dbUserData.encryptedPrivateKey) {
        logger.info('No backup yet, backing up private key');
        await this.backupPrivateKeyPharmacy(dbUserData.cognitoId, dbUserData.privateKey, password);
      }
    }

    await this.setUserInStoreAndRedirect(dbUserData);
  }

  public async setNewPasswordAndSignIn(password: string) {
    const cognitoUser = await firstValueFrom(this.authStateService.cognitoUser$);
    await this.amplifyService
      .auth()
      .completeNewPassword(cognitoUser, password, cognitoUser.challengeParam.requiredAttributes);
    await this.signIn(cognitoUser.username, password);
  }

  public async resendInitialPharmacyPassword(username: string): Promise<boolean> {
    try {
      return await this.appsyncPharmacyService.resendInitialPharmacyPassword(username);
    } catch (error) {
      logger.error('Failed to resend initial password', error);
      return false;
    }
  }

  private async signingInExistingDynamoDbUser(dbUserData: ChatUser, conversationId?: string) {
    const privateKeyFromStorage = await this.privateKeyStoreService.getPrivateKey(dbUserData.cognitoId);
    const privateKeyIsValid = this.privateKeyValidationService.isPrivateKeyValid(
      privateKeyFromStorage,
      dbUserData.publicKey
    );
    if (privateKeyFromStorage && privateKeyIsValid) {
      logger.info('Private key is valid');
      dbUserData.privateKey = privateKeyFromStorage;
    }

    this.signInCache = { dbUserData };
    if (this.shouldResetKeyPair(dbUserData)) {
      logger.info('No private key, resetting key pair');

      await this.router.navigate(this.loginRoutes.backup);
    } else if (!dbUserData.privateKey && dbUserData.encryptedPrivateKey) {
      logger.info('No private key, redirect to restore page');

      await this.router.navigate(this.loginRoutes.restore);
    } else if (dbUserData.privateKey && !dbUserData.encryptedPrivateKey) {
      logger.info('No backup yet, redirect to backup page');

      await this.router.navigate(this.loginRoutes.backup);
    } else if (!dbUserData.privateKey) {
      logger.errorWithFingerprint('No private key and no backup', 'signin');
      await this.privateKeyAlertService.showNoPrivateKeyFoundToast();
    } else {
      await this.setUserInStoreAndRedirect(dbUserData, conversationId);
    }
  }

  private async setUserInStoreAndRedirect(dbUserData: ChatUser, conversationId?: string) {
    await this.privateKeyStoreService.updatePrivateKeyInStorageAndSetUser(dbUserData);
    this.signInCache = null;
    if (conversationId) {
      await this.router.navigate(this.loginRoutes.getChatPage(conversationId));
    } else {
      await this.router.navigate(this.loginRoutes.home);
    }
  }

  private async createNewPharmacyUser(cognitoUser: any, password: string) {
    const cognitoUsername = this.getCognitoUserNameFromCognitoUserData(cognitoUser);
    logger.info('First login, generating key and creating pharmacy user');
    const generatedKeyAndPharmacyUser = await this.generateKeyAndCreatePharmacyUser(cognitoUsername, password);
    logger.info('Deleting custom:dynamoDbTransfer attribute in cognito');
    this.cleanupCognitoAttributes(cognitoUser);
    return generatedKeyAndPharmacyUser;
  }

  private shouldResetKeyPair = (dbUserData: ChatUser) => dbUserData.resetKeyPairOnNextLogin;

  private cleanupCognitoAttributes(cognitoUser: any) {
    cognitoUser.deleteAttributes(['custom:dynamoDbTransfer'], (err: any) => {
      if (err) {
        logger.error(`Could not delete dynamoDbTransfer from user ${cognitoUser}`, err);
      }
    });
  }

  private getCognitoUserNameFromCognitoUserData = (userData: any): string => {
    const cognitoAttributes = userData.signInUserSession.idToken.payload;

    return cognitoAttributes['cognito:username'];
  };

  private async generateKeyAndCreatePharmacyUser(cognitoUsername: string, backupPassword: string): Promise<ChatUser> {
    const { attributes } = await this.amplifyService.auth().currentAuthenticatedUser();
    const pharmacyId = attributes['custom:pharmacy_id'];

    const { publicKey, privateKey, encryptionResult } = this.createKeyPairAndBackup(backupPassword);
    const encryptedPrivateKey = encryptionResult.encryptedPrivateKey;
    const encryptionSalt = encryptionResult.salt;

    const user = await this.createPharmacyChatUser({
      pharmacyId,
      cognitoUsername,
      publicKey,
      encryptedPrivateKey,
      encryptionSalt,
    });
    await this.updatePharmacyCognitoId(pharmacyId, cognitoUsername, user.cognitoId);
    return { ...user, privateKey };
  }

  private createKeyPairAndBackup(backupPassword: string) {
    const keyPair = this.encryptionService.createKeyPair();
    const encryptionResult = this.privateKeyBackupService.encryptPrivateKey(backupPassword, keyPair.privateKey);

    return { ...keyPair, encryptionResult };
  }

  private async createPharmacyChatUser(user: PharmacyChatUserCreateInput): Promise<ChatUser> {
    try {
      return await this.appsyncPharmacyService.createPharmacyChatUser(user);
    } catch (err) {
      logger.error('Error registering pharmacy user', err);
      throw err;
    }
  }

  private async updatePharmacyCognitoId(pharmacyId: string, sanacorpCustomerId: string, cognitoId: string) {
    try {
      await this.appsyncPharmacyService.updatePharmacyCognitoId(pharmacyId, cognitoId);
    } catch (err) {
      logger.error('Error updating ES pharmacy cognito id user', err);
    }
    try {
      await this.appsyncPharmacyService.updateOSPharmacyCognitoId(sanacorpCustomerId, cognitoId);
    } catch (err) {
      logger.error('Error updating OS pharmacy cognito id user', err);
    }
  }

  private async backupPrivateKeyPharmacy(cognitoId: string, privateKey: string, backupPassword: string) {
    const encryptionResult = this.privateKeyBackupService.encryptPrivateKey(backupPassword, privateKey);
    await this.updatePharmacyEncryptedPrivateKey(cognitoId, encryptionResult);
  }

  private async restorePrivateKeyPharmacy(
    encryptedPrivateKey: string,
    encryptionSalt: string,
    restorePassword: string
  ): Promise<string> {
    const privateKey = this.privateKeyBackupService.decryptPrivateKey(restorePassword, {
      encryptedPrivateKey,
      salt: encryptionSalt,
    });
    if (!privateKey) {
      throw new Error('PHARMACY_FRONTEND.BACKUP_RESTORE.RESTORE_ERROR');
    }

    return privateKey;
  }

  private async updatePharmacyEncryptedPrivateKey(cognitoId: string, encryptionResult: EncryptionResult) {
    try {
      await this.appsyncPharmacyService.updatePharmacyEncryptedPrivateKey(cognitoId, encryptionResult);
    } catch (err) {
      logger.error('Error updating pharmacy cognito id user', err);
    }
  }
}
