import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';
import { Appointment } from '../../../../../essentials/types/src/appointment';
import { Conversation } from '../../../../../essentials/types/src/conversation';
import Message from '../../../../../essentials/types/src/message';
import { SubmittedShoppingCart } from '../../../../../essentials/types/src/shoppingCart';

export type DecryptionResult = { segmentId: string } & (
  | { decryptionStatus: 'decrypted'; decryptedPassword: string }
  | { decryptionStatus: 'failed' }
);

export const decryptMessages = createAction(
  `${ActionType.Chat}: Decrypt messages`,
  props<{ conversationId: string; messages: Message[] }>()
);

export const decryptUndecryptedMessages = createAction(
  `${ActionType.Chat}: Decrypt undecrypted messages of conversation`,
  props<{ conversationId: string; messages: Message[] }>()
);

export const decryptConversationSegments = createAction(
  `${ActionType.Chat}: Decrypt conversation segments`,
  props<{ conversation: Conversation }>()
);

export const updateDecryptedConversationSegments = createAction(
  `${ActionType.Chat}: Update decrypted conversation segments`,
  props<{
    decryptionResults: DecryptionResult[];
    conversationId: string;
  }>()
);

export const decryptConversationSegmentsAndMessage = createAction(
  `${ActionType.Chat}: Decrypt conversation segments and message`,
  props<{ conversation: Conversation; message: Message }>()
);

export const updatedBackendShoppingCartInChat = createAction(
  `${ActionType.Chat}: Updated backend shopping cart in chat`,
  props<{ conversationId: string; encryptedShoppingCart: string }>()
);

export const newShoppingCartInChat = createAction(`${ActionType.Chat}: New shopping cart in chat`);

export const updateShoppingCartInChat = createAction(
  `${ActionType.Chat}: Update Shopping Cart in chat`,
  props<{ conversationId: string; encryptedShoppingCart: string }>()
);

export const updateDecryptedShoppingCartInChat = createAction(
  `${ActionType.Chat}: Update decrypted Shopping Cart in chat`,
  props<{ conversationId: string; shoppingCart: SubmittedShoppingCart }>()
);

export const updatedBackendAppointment = createAction(
  `${ActionType.Chat}: Updated backend appointment`,
  props<{ conversationId: string; encryptedAppointment: string }>()
);

export const newAppointment = createAction(`${ActionType.Chat}: New appointment`);

export const updateAppointment = createAction(
  `${ActionType.Chat}: Update Appointment`,
  props<{ conversationId: string; encryptedAppointment: string }>()
);

export const updateDecryptedAppointment = createAction(
  `${ActionType.Chat}: Update decrypted Appointment`,
  props<{ conversationId: string; appointment: Appointment }>()
);
