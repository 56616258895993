import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'labelName', standalone: true })
export class LabelNamePipe implements PipeTransform {
  transform(item: unknown): string | null | undefined {
    if (item === null || item === undefined) {
      return item;
    }
    if (item && typeof item === 'object' && 'name' in item) {
      return item.name as string;
    }
    return `${item}`;
  }
}
