import { Injectable } from '@angular/core';
import imageCompression from 'browser-image-compression';
import * as pdfjsLib from 'pdfjs-dist';
import { FileAttachment, isPdfFileAttachment } from '../../../../../essentials/types/src/attachment';
import { MimeTypeEnum } from '../../../../../essentials/types/src/mimeTypeEnum';
import { ImageTransformationUtil } from '../../../../../essentials/util/src/chat/image-transformation.util';
import { Logger } from '../../../../../essentials/util/src/logger';

const logger = new Logger('AttachmentPreparationService');

@Injectable({
  providedIn: 'root',
})
export class AttachmentPreparationService {
  public static readonly imageType = MimeTypeEnum.JPEG;
  public static readonly imageQuality = 0.9;

  constructor() {
    pdfjsLib.GlobalWorkerOptions.workerSrc = 'assets/pdf.worker.mjs';
  }

  public async createImageAsDataUrl(file: File): Promise<string> {
    const compressedImage = await imageCompression(file, { maxSizeMB: 0.5, preserveExif: true });
    return imageCompression.getDataUrlFromFile(compressedImage);
  }

  public async createThumbnailForPdf(file: File) {
    try {
      const pdfDocument = await pdfjsLib.getDocument(await file.arrayBuffer()).promise;
      const page = await pdfDocument.getPage(1);
      const canvas = document.createElement('canvas');
      const canvasContext = canvas.getContext('2d');
      if (!canvasContext) {
        return undefined;
      }
      const viewport = page.getViewport({ scale: 1 });
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      const renderContext = { canvasContext, viewport };
      await page.render(renderContext).promise;
      return canvas.toDataURL(AttachmentPreparationService.imageType, AttachmentPreparationService.imageQuality);
    } catch (e) {
      logger.error('Error while creating Thumbnail', e);
      return undefined;
    }
  }

  public mapFilesToAttachments(files: FileList | File[]): Promise<FileAttachment[]> {
    const fileAttachments = Object.values(files).map((file) => {
      const mediaType: 'FILE' | 'IMAGE' = file.type === MimeTypeEnum.PDF ? 'FILE' : 'IMAGE';
      return { file, mediaType };
    });
    return this.addThumbnailsToFiles(fileAttachments);
  }

  public async createThumbnailAsDataUrl(image: string): Promise<string> {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    await this.drawThumbnailToCanvas(image, canvas);
    return canvas.toDataURL(AttachmentPreparationService.imageType, AttachmentPreparationService.imageQuality);
  }

  public async createThumbnailAsBlob(file: File, sizeInkB: number): Promise<Blob> {
    return imageCompression(file, { maxSizeMB: sizeInkB / 1024, maxIteration: 30 });
  }

  private addThumbnailsToFiles(attachments: FileAttachment[]) {
    const attachmentPromises = attachments.map(async (attachment) => {
      if (isPdfFileAttachment(attachment)) {
        const thumbnailUrl = await this.createThumbnailForPdf(attachment.file);
        return { ...attachment, thumbnailUrl };
      }
      return attachment;
    });
    return Promise.all(attachmentPromises);
  }

  private drawThumbnailToCanvas(image: string, canvas: any) {
    return new Promise<void>((resolve) => {
      const vImage = new Image();
      vImage.src = image;
      vImage.onload = () => {
        ImageTransformationUtil.drawThumbnail(canvas, vImage);
        resolve();
      };
    });
  }
}
