import gql from 'graphql-tag';
import { pharmacyAttributes, pharmacyPharmacyChatUserAttributes } from '../attributes';

export default gql`
  query getPharmacy($id: ID!) {
    getPharmacy(id: $id) {
      ${pharmacyAttributes}
      pharmacyChatUser {
        ${pharmacyPharmacyChatUserAttributes}
      }
    }
  }
`;
