import { createSelector, select } from '@ngrx/store';
import equal from 'fast-deep-equal';
import first from 'lodash-es/first';
import flatMap from 'lodash-es/flatMap';
import isNil from 'lodash-es/isNil';
import last from 'lodash-es/last';
import { concat, Observable, of, pairwise, pipe, UnaryFunction } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { Dictionary } from 'ts-essentials';
import { DeletionEntry } from '../../../../../essentials/types/src/backendConversation';
import { ChatUser } from '../../../../../essentials/types/src/chatUser';
import {
  Conversation,
  ConversationAndLastMessage,
  ConversationSegment,
} from '../../../../../essentials/types/src/conversation';
import { LoadStatus } from '../../../../../essentials/types/src/loadStatus';
import Message from '../../../../../essentials/types/src/message';
import PaginatedMessages from '../../../../../essentials/types/src/paginatedMessages';
import { isNotNullOrUndefined } from '../../../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { CommonState } from '../../common.state';
import { selectCognitoId, selectUser } from '../../user-store/selectors/user.selectors';
import { findConversationForSegment } from '../reducers/util/chat-reducer.util';
import { ChatState, TypingStatus } from '../state/chat.state';

export const selectChatData = (state: CommonState) => state.chatData;

export const selectConversationsLoadStatus = createSelector(
  selectChatData,
  ({ conversationsLoadStatus }) => conversationsLoadStatus
);

export const selectConversations = createSelector(selectChatData, ({ conversations }: ChatState) =>
  Object.values(conversations)
);

export const selectConversationDictionary = createSelector(
  selectChatData,
  ({ conversations }: ChatState) => conversations
);

export const selectConversation = (conversationId: string) =>
  createSelector(
    selectConversationDictionary,
    (conversations: Dictionary<Conversation>) => conversations[conversationId]
  );

export const selectActiveConversationId = createSelector(
  selectChatData,
  ({ activeConversationId }: ChatState) => activeConversationId
);

export const selectActiveConversation = createSelector(
  selectChatData,
  selectActiveConversationId,
  ({ conversations }: ChatState, activeConversationId) => {
    if (!activeConversationId) {
      return null;
    }
    return conversations[activeConversationId];
  }
);

export const selectIsConversationDeletedByUser = (conversationId: string) =>
  createSelector(
    selectConversation(conversationId),
    selectLastMessageOfConversation(conversationId),
    selectUser,
    (conversation: Conversation | undefined, lastMessage: Message | undefined, user: ChatUser | null) => {
      const deletionRecord = conversation?.deletionRecord;
      if (deletionRecord) {
        const hasUserEverDeletedConversation = deletionRecord.find(
          (deletionEntry) => deletionEntry.cognitoId === user?.cognitoId
        );
        if (hasUserEverDeletedConversation && !lastMessage) {
          return true;
        }
      }
      return false;
    }
  );

export const selectIsConversationDeletedByChatPartner = (conversationId: string) =>
  createSelector(
    selectConversation(conversationId),
    selectLastMessageOfConversation(conversationId),
    (conversation: Conversation | undefined, lastMessage: Message | undefined) => {
      const deletionRecord = conversation?.deletionRecord;
      if (!deletionRecord) {
        return false;
      }
      const chatPartner = conversation?.chatPartner;
      if (isNil(chatPartner)) {
        return false;
      }
      const chatPartnerDeletionTimestamp = deletionRecord.find(
        (deletionEntry) => deletionEntry.cognitoId === chatPartner.cognitoId
      )?.deletedAt;
      const lastMessageTimestamp = lastMessage?.createdAt;
      return (
        lastMessageTimestamp && chatPartnerDeletionTimestamp && chatPartnerDeletionTimestamp > lastMessageTimestamp
      );
    }
  );

export const selectMessages = createSelector(selectChatData, ({ messages }: ChatState) => messages);

export const selectMessagesOfConversation = (conversationId: string) =>
  createSelector(
    selectMessages,
    selectCognitoId,
    selectConversation(conversationId),
    (messages, cognitoId, conversation: Conversation | undefined): Message[] => {
      if (!conversation || !cognitoId) {
        return [];
      }
      return getMessagesOfConversation(conversation, messages, cognitoId);
    }
  );

function getMessagesOfConversation(
  conversation: Conversation,
  messages: Dictionary<PaginatedMessages>,
  cognitoId: string
) {
  const myMessages: Message[] = flatMap(conversation.segments, (segment) => messages[segment.id]?.messages || []);
  const myDeletionEntry = getDeletionEntry(conversation, cognitoId);
  if (!myDeletionEntry) {
    return myMessages;
  }

  return myMessages.filter((message) => message.createdAt > myDeletionEntry.deletedAt);
}

function getLastMessageOfConversation(
  cognitoId: string,
  conversation: Conversation,
  messages: Dictionary<PaginatedMessages>
): Message | undefined {
  return last(getMessagesOfConversation(conversation, messages, cognitoId));
}

const selectConversationsAndLastMessages = createSelector(
  selectCognitoId,
  selectConversations,
  selectMessages,
  (cognitoId, conversations, messages) => {
    if (!cognitoId) {
      return [];
    }
    return conversations.map(
      (conversation): ConversationAndLastMessage => ({
        conversation,
        lastMessage: getLastMessageOfConversation(cognitoId, conversation, messages),
      })
    );
  }
);

export const pipeConversationsAndLastMessages: UnaryFunction<
  Observable<CommonState>,
  Observable<ConversationAndLastMessage[]>
> = pipe(select(selectConversationsAndLastMessages), distinctUntilChanged(equal));

export const selectLastMessageOfConversation = (conversationId: string) =>
  createSelector(selectMessagesOfConversation(conversationId), (messages) => last(messages));

export const pipeSelectLastMessageOfConversation: (
  conversationId: string
) => UnaryFunction<Observable<CommonState>, Observable<Message | undefined>> = (conversationId) =>
  pipe(select(selectLastMessageOfConversation(conversationId)), distinctUntilChanged(equal));

export const selectInitializedMessages = (conversationId: string) =>
  createSelector(
    selectMessagesInitialized(conversationId),
    selectMessagesOfConversation(conversationId),
    (messagesInitialized, messages) => (messagesInitialized ? messages : null)
  );

export const pipeInitializedMessages: (
  conversationId: string
) => UnaryFunction<Observable<CommonState>, Observable<Message[]>> = (conversationId) =>
  pipe(select(selectInitializedMessages(conversationId)), isNotNullOrUndefined());

export const selectMessagesInitializationStatus = (conversationId: string) =>
  createSelector(selectConversation(conversationId), (conversation) =>
    conversation ? conversation.messagesInitializationStatus : null
  );

export const selectMessagesInitialized = (conversationId: string) =>
  createSelector(selectConversation(conversationId), (conversation) =>
    conversation
      ? ![LoadStatus.Init, LoadStatus.LoadingInitial].includes(conversation.messagesInitializationStatus)
      : true
  );

export const selectConversationSegment = (segmentId: string) =>
  createSelector(selectChatData, ({ conversations }): ConversationSegment | null => {
    if (!conversations) {
      return null;
    }

    const conversation = findConversationForSegment(conversations, segmentId);
    if (conversation) {
      const conversationSegment = conversation.segments.find((segment) => segment.id === segmentId);
      if (conversationSegment) {
        return conversationSegment;
      }
    }
    return null;
  });

export const selectConversationForSegment = (segmentId: string) =>
  createSelector(
    selectChatData,
    ({ conversations }): Conversation | null => findConversationForSegment(conversations, segmentId) ?? null
  );

export const pipeConversationForSegment = (segmentId: string) =>
  pipe(
    select(selectChatData),
    map(({ conversations, conversationsLoadStatus }) => {
      const conversationForSegment = findConversationForSegment(conversations, segmentId) ?? null;
      return { conversationForSegment, conversationsLoadStatus };
    }),
    filter(
      ({ conversationForSegment, conversationsLoadStatus }) =>
        !!conversationForSegment || conversationsLoadStatus === LoadStatus.UpToDate
    ),
    map(({ conversationForSegment }) => conversationForSegment),
    distinctUntilChanged(equal)
  );

export const selectConversationPassword = (segmentId: string) =>
  createSelector(
    selectConversationSegment(segmentId),
    (segment): string | undefined => segment?.decryptedConversationPassword
  );

export const selectHasMoreMessages = (conversationId: string) =>
  createSelector(
    selectConversation(conversationId),
    selectMessages,
    selectCognitoId,
    (conversation, messages, cognitoId) => {
      if (conversation && cognitoId) {
        const myDeletionEntry = getDeletionEntry(conversation, cognitoId);
        return conversation.segments.reduce((acc, segment) => {
          const paginatedMessagesOfSegment = messages[segment.id];
          const firstMessageCreatedAt = first(paginatedMessagesOfSegment?.messages)?.createdAt;
          const hasMoreMessages = !!paginatedMessagesOfSegment?.hasMoreMessages;
          const noDeletionOrNoDeletedMessages =
            !myDeletionEntry || !firstMessageCreatedAt || firstMessageCreatedAt > myDeletionEntry.deletedAt;
          return acc || (hasMoreMessages && noDeletionOrNoDeletedMessages);
        }, false);
      } else {
        return false;
      }
    }
  );

const selectConversationsAndConversationsLoadStatus = createSelector(
  selectConversations,
  selectConversationsLoadStatus,
  (conversations, conversationsLoadStatus) => ({ conversations, conversationsLoadStatus })
);

export const pipeConversationForPharmacy = (pharmacyId: string) =>
  pipe(
    select(selectConversationsAndConversationsLoadStatus),
    map(({ conversations, conversationsLoadStatus }) => {
      const conversationForPharmacy = conversations.find((conversation) => {
        const pharmacy = conversation.chatPartner.pharmacy;
        return pharmacy ? pharmacy.id === pharmacyId : false;
      });
      return { conversationForPharmacy, conversationsLoadStatus };
    }),
    filter(
      ({ conversationForPharmacy, conversationsLoadStatus }) =>
        !!conversationForPharmacy || conversationsLoadStatus === LoadStatus.UpToDate
    ),
    map(({ conversationForPharmacy }) => conversationForPharmacy),
    distinctUntilChanged(equal)
  );

export const pipeActiveConversation: UnaryFunction<
  Observable<CommonState>,
  Observable<Conversation | undefined>
> = pipe(
  select(selectChatData),
  map(({ activeConversationId, conversations, conversationsLoadStatus }) => {
    const activeConversation = activeConversationId && conversations[activeConversationId];
    return { activeConversation, conversationsLoadStatus };
  }),
  filter(
    ({ activeConversation, conversationsLoadStatus }) =>
      !!activeConversation || conversationsLoadStatus === LoadStatus.UpToDate
  ),
  map(({ activeConversation }) => activeConversation),
  distinctUntilChanged(equal)
);

export const pipeConversationByIdForPharmacy = (conversationId: string) =>
  pipe(
    select(selectChatData),
    map(({ conversations, conversationsLoadStatus }) => {
      const conversationForPharmacy: Conversation | undefined = conversations[conversationId];
      return { conversationForPharmacy, conversationsLoadStatus };
    }),
    filter(
      ({ conversationForPharmacy, conversationsLoadStatus }) =>
        !!conversationForPharmacy || conversationsLoadStatus === LoadStatus.UpToDate
    ),
    map(({ conversationForPharmacy }) => conversationForPharmacy),
    distinctUntilChanged(equal)
  );

export const pipeTicketStatusHasChanged = (conversationId: string) =>
  pipe(
    select(selectConversation(conversationId)),
    isNotNullOrUndefined(),
    map((conversation) => conversation?.ticketHistory?.at(-1)?.updatedStatus),
    pairwise(),
    filter(([oldStatus, newStatus]) => newStatus !== oldStatus),
    switchMap(() => concat(of(true), of(false).pipe(delay(1300))))
  );

export const selectSelfTypingDictionary = createSelector(selectChatData, ({ selfTyping }: ChatState) => selfTyping);

export const selectSelfTypingStatus = (conversationId: string) =>
  createSelector(
    selectSelfTypingDictionary,
    (selfTypingDictionary: Dictionary<TypingStatus>) => selfTypingDictionary[conversationId]
  );

export const selectIsSelfTyping = (conversationId: string) =>
  createSelector(
    selectSelfTypingStatus(conversationId),
    (selfTypingStatus) => !!selfTypingStatus && selfTypingStatus.isTyping
  );

export const selectConversationsThatOpenImageOnInit = createSelector(
  selectChatData,
  ({ conversationsThatOpenImagePickerOnInit }: ChatState) => conversationsThatOpenImagePickerOnInit
);
export const selectOpenImageOnInit = (conversationId: string) =>
  createSelector(selectConversationsThatOpenImageOnInit, (conversationsThatOpenImageOnInit) =>
    conversationsThatOpenImageOnInit.has(conversationId)
  );

const getDeletionEntry = (conversation: Conversation, cognitoId: string): DeletionEntry | undefined =>
  conversation?.deletionRecord?.find((entry) => entry.cognitoId === cognitoId);
