import gql from 'graphql-tag';

export default gql`
  mutation updateAppointmentId($conversationId: ID!, $appointmentId: ID!) {
    updateAppointmentId(id: $conversationId, appointmentId: $appointmentId) {
      id
      appointmentId
    }
  }
`;
