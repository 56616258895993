import { Action, createReducer, on } from '@ngrx/store';
import update, { Spec } from 'immutability-helper';
import cloneDeep from 'lodash-es/cloneDeep';
import isNil from 'lodash-es/isNil';
import { Conversation, ConversationSegment } from '../../../../../essentials/types/src/conversation';
import { LoadStatus } from '../../../../../essentials/types/src/loadStatus';
import Message, { DecryptedMessageWithMedia } from '../../../../../essentials/types/src/message';
import { areSameMedia, DecryptedMessageMedia } from '../../../../../essentials/types/src/messageMedia';
import PaginatedMessages from '../../../../../essentials/types/src/paginatedMessages';
import { MessageUtil } from '../../../../../essentials/util/src/chat/message.util';
import { ConversationMappingUtil } from '../../../../../essentials/util/src/conversation-mapping.util';
import { markDataWithSubscriptionsAsStale } from '../../other/actions/subscription.actions';
import { clearUserOnLogout, setUserOnLogin } from '../../user-store/actions/user.actions';
import {
  updateAppointmentId,
  updateArchivedConversation,
  updateConversationChatPartner,
  updateConversationLinkForConversation,
  updateDeletionRecordForConversation,
  updateOpenTicketReminderNotification,
  updateOpenTicketReminderNotificationForConversation,
  updateTicketHistory,
  updateTicketHistoryForConversation,
} from '../actions/chat-conversation-update.actions';
import {
  clearActiveConversation,
  loadConversations,
  loadConversationsFailure,
  newConversation,
  newOrUpdateConversation,
  openImagePickerOnInit,
  populateChatStateFromStorage,
  setActiveConversation,
  setConversations,
  setRecentConversations,
  stopOpeningImagePickerOnInit,
} from '../actions/chat-conversation.actions';
import {
  updateAppointment,
  updateDecryptedAppointment,
  updateDecryptedConversationSegments,
  updateDecryptedShoppingCartInChat,
  updateShoppingCartInChat,
} from '../actions/chat-decryption.actions';
import {
  addInitialMessagesForConversation,
  addMoreMessagesForConversation,
  loadInitialMessagesForConversation,
  loadInitialMessagesForConversationFailure,
  loadInitialMessagesForConversationSuccess,
  newBackendMessage,
  newLocalMessage,
  newOwnBackendMessage,
  readMessage,
  readOwnMessage,
  updateMessage,
  updateMessageLocally,
  updateMessageMedia,
  updateMessages,
} from '../actions/chat-message.actions';
import { startSelfTyping, stopSelfTyping } from '../actions/chat-typing.actions';
import { ChatState } from '../state/chat.state';
import { findConversationForSegment, MessageUpdate, performMessageUpdate } from './util/chat-reducer.util';
import { mergeMessage, performSetConversations } from './util/set-conversations.util';

export const initialChatState: ChatState = {
  conversations: {},
  conversationsLoadStatus: LoadStatus.Init,
  messages: {},
  activeConversationId: null,
  selfTyping: {},
  conversationsThatOpenImagePickerOnInit: new Set<string>(),
};

const _chatReducer = createReducer(
  initialChatState,

  on(populateChatStateFromStorage, (state, { persistedChatState: { conversations, messages } }) => {
    if (conversations) {
      for (const key of Object.keys(conversations)) {
        conversations = update(conversations, { [key]: { messagesInitializationStatus: { $set: LoadStatus.Stale } } });
      }
    }
    return {
      ...state,
      conversations,
      messages,
      conversationsLoadStatus: LoadStatus.Stale,
    };
  }),
  on(loadConversations, (state, { conversationsLoadStatus }) =>
    update(state, { conversationsLoadStatus: { $set: conversationsLoadStatus } })
  ),
  on(loadConversationsFailure, (state) => update(state, { conversationsLoadStatus: { $set: LoadStatus.Error } })),
  on(markDataWithSubscriptionsAsStale, (state) => {
    if (state.conversationsLoadStatus === LoadStatus.UpToDate) {
      let updatedState = update(state, { conversationsLoadStatus: { $set: LoadStatus.Stale } });

      for (const key of Object.keys(state.conversations)) {
        updatedState = update(updatedState, {
          conversations: { [key]: { messagesInitializationStatus: { $set: LoadStatus.Stale } } },
        });
      }

      return updatedState;
    } else {
      return state;
    }
  }),
  on(setConversations, (state, { conversationsAndLastMessages }) =>
    performSetConversations(state, conversationsAndLastMessages)
  ),
  on(setRecentConversations, (state, { conversationsAndLastMessages }) =>
    performSetConversations(state, conversationsAndLastMessages, {
      removeNonExistingConversations: false,
      newLoadStatus: LoadStatus.Stale,
    })
  ),
  on(setActiveConversation, (state, { id }) => ({ ...state, activeConversationId: id })),
  on(clearActiveConversation, (state) => ({ ...state, activeConversationId: initialChatState.activeConversationId })),
  on(newConversation, (state, { conversation }) => {
    if (!state.conversations || !state.messages) {
      return state;
    }
    const firstSegmentId = conversation.conversation.firstSegmentId;
    if (firstSegmentId) {
      const conversationToUpdate = state.conversations[firstSegmentId];
      if (conversationToUpdate) {
        const { conversation: updatedConversation, lastMessage: lastMessageOfNewSegment } =
          ConversationMappingUtil.mapNewBackendUserConversationToExistingConversation(
            conversation,
            conversationToUpdate
          );
        const messagesOfNewSegment = !isNil(lastMessageOfNewSegment) ? [lastMessageOfNewSegment] : [];

        return update(state, {
          conversations: { [firstSegmentId]: { $set: updatedConversation } },
          messages: {
            [conversation.conversation.id]: {
              $set: { messages: messagesOfNewSegment, hasMoreMessages: true, nextToken: '' },
            },
          },
        });
      }
    }
    const { conversation: newConversationToStore, lastMessage } =
      ConversationMappingUtil.mapNewBackendUserConversationToNewConversation(conversation);
    const messages = !isNil(lastMessage) ? [lastMessage] : [];

    return update(state, {
      conversations: { [newConversationToStore.id]: { $set: newConversationToStore } },
      messages: { [newConversationToStore.id]: { $set: { messages, hasMoreMessages: true, nextToken: '' } } },
    });
  }),
  on(newOrUpdateConversation, (state, { conversation }) => {
    const firstSegmentId = conversation.conversation.firstSegmentId;
    const firstSegmentConversation = firstSegmentId && state.conversations?.[firstSegmentId];

    const conversationId = conversation.conversation.id;

    if (firstSegmentId && firstSegmentConversation) {
      const existingSegment = firstSegmentConversation.segments.find((segment) => segment.id === conversationId);
      if (existingSegment) {
        const updatedConversation = ConversationMappingUtil.mapUpdatedBackendUserConversationToExistingConversation(
          conversation,
          firstSegmentConversation
        );
        return update(state, {
          conversations: { [updatedConversation.id]: { $set: updatedConversation } },
        });
      } else {
        const { conversation: updatedConversation, lastMessage } =
          ConversationMappingUtil.mapNewBackendUserConversationToExistingConversation(
            conversation,
            firstSegmentConversation
          );
        const messages = !isNil(lastMessage) ? [lastMessage] : [];

        return update(state, {
          conversations: { [updatedConversation.id]: { $set: updatedConversation } },
          messages: { [conversationId]: { $set: { messages, hasMoreMessages: true, nextToken: '' } } },
        });
      }
    } else if (conversationId && state.conversations?.[conversationId]) {
      const updatedConversation = ConversationMappingUtil.mapUpdatedBackendUserConversationToExistingConversation(
        conversation,
        state.conversations[conversationId] as Conversation
      );
      return update(state, {
        conversations: { [updatedConversation.id]: { $set: updatedConversation } },
      });
    } else {
      const { conversation: newConversationToStore, lastMessage } =
        ConversationMappingUtil.mapNewBackendUserConversationToNewConversation(conversation);
      const messages = !isNil(lastMessage) ? [lastMessage] : [];

      return update(state, {
        conversations: { [newConversationToStore.id]: { $set: newConversationToStore } },
        messages: { [newConversationToStore.id]: { $set: { messages, hasMoreMessages: true, nextToken: '' } } },
      });
    }
  }),
  on(updateTicketHistory, (state, { conversationId, ticketEvent }) => {
    if (state.conversations?.[conversationId]) {
      if (state.conversations?.[conversationId]?.ticketHistory) {
        return update(state, {
          conversations: {
            [conversationId]: {
              ticketHistory: { $push: [ticketEvent] },
              showReminder: { $set: false },
              reminderNotification: { $set: false },
            },
          },
        });
      } else {
        return update(state, {
          conversations: {
            [conversationId]: {
              ticketHistory: { $set: [ticketEvent] },
              showReminder: { $set: false },
              reminderNotification: { $set: false },
            },
          },
        });
      }
    }
    return state;
  }),
  on(
    updateOpenTicketReminderNotification,
    updateOpenTicketReminderNotificationForConversation,
    (state, { conversationId, reminderNotification, showReminder }) => {
      if (state.conversations?.[conversationId]) {
        const conversationUpdates: { reminderNotification: { $set: boolean }; showReminder?: { $set: boolean } } = {
          reminderNotification: { $set: reminderNotification },
        };
        if (!isNil(showReminder)) {
          conversationUpdates.showReminder = { $set: showReminder };
        }
        return update(state, { conversations: { [conversationId]: conversationUpdates } });
      }
      return state;
    }
  ),
  on(updateDeletionRecordForConversation, (state, { conversationId, deletionRecord }) => {
    if (state.conversations?.[conversationId]) {
      return update(state, {
        conversations: { [conversationId]: { deletionRecord: { $set: deletionRecord } } },
      });
    }
    return state;
  }),
  on(updateArchivedConversation, (state, { conversationId, archivedByEnduser }) => {
    if (state.conversations?.[conversationId]) {
      return update(state, {
        conversations: { [conversationId]: { archivedByEnduser: { $set: archivedByEnduser } } },
      });
    }
    return state;
  }),
  on(updateAppointmentId, (state, { conversationId, appointmentId }) => {
    if (state.conversations?.[conversationId]) {
      return update(state, {
        conversations: { [conversationId]: { appointmentId: { $set: appointmentId } } },
      });
    }
    return state;
  }),
  on(updateTicketHistoryForConversation, (state, { conversationId, ticketHistory }) => {
    if (state.conversations?.[conversationId]) {
      return update(state, {
        conversations: { [conversationId]: { ticketHistory: { $set: ticketHistory } } },
      });
    }
    return state;
  }),
  on(updateConversationLinkForConversation, (state, { conversationId, conversationLink }) => {
    if (state.conversations[conversationId]) {
      return update(state, {
        conversations: { [conversationId]: { conversationLink: { $set: conversationLink } } },
      });
    }
    return state;
  }),

  on(updateConversationChatPartner, (state, { conversationId, chatPartner }) => {
    if (state.conversations?.[conversationId]) {
      return update(state, {
        conversations: { [conversationId]: { chatPartner: { $set: chatPartner } } },
      });
    }
    return state;
  }),
  on(updateShoppingCartInChat, (state, { conversationId, encryptedShoppingCart }) => {
    if (state.conversations[conversationId]) {
      return update(state, {
        conversations: { [conversationId]: { encryptedShoppingCart: { $set: encryptedShoppingCart } } },
      });
    } else {
      return state;
    }
  }),
  on(updateDecryptedShoppingCartInChat, (state, { conversationId, shoppingCart }) => {
    if (state.conversations[conversationId]) {
      return update(state, { conversations: { [conversationId]: { shoppingCart: { $set: shoppingCart } } } });
    } else {
      return state;
    }
  }),
  on(updateAppointment, (state, { conversationId, encryptedAppointment }) => {
    if (state.conversations[conversationId]) {
      return update(state, {
        conversations: { [conversationId]: { encryptedAppointment: { $set: encryptedAppointment } } },
      });
    } else {
      return state;
    }
  }),
  on(updateDecryptedAppointment, (state, { conversationId, appointment }) => {
    if (state.conversations[conversationId]) {
      return update(state, { conversations: { [conversationId]: { appointment: { $set: appointment } } } });
    } else {
      return state;
    }
  }),

  on(clearUserOnLogout, setUserOnLogin, () => cloneDeep(initialChatState)),
  on(loadInitialMessagesForConversation, (state, { conversationId }) => {
    const conversation = state.conversations[conversationId];
    if (conversation) {
      return update(state, {
        conversations: {
          [conversationId]: {
            messagesInitializationStatus: {
              $set:
                conversation.messagesInitializationStatus === LoadStatus.Init
                  ? LoadStatus.LoadingInitial
                  : LoadStatus.Revalidating,
            },
          },
        },
      });
    } else {
      return state;
    }
  }),
  on(loadInitialMessagesForConversationSuccess, (state, { conversationId }) => {
    if (state.conversations[conversationId]) {
      return update(state, {
        conversations: { [conversationId]: { messagesInitializationStatus: { $set: LoadStatus.UpToDate } } },
      });
    } else {
      return state;
    }
  }),
  on(loadInitialMessagesForConversationFailure, (state, { conversationId }) => {
    if (state.conversations[conversationId]) {
      return update(state, {
        conversations: { [conversationId]: { messagesInitializationStatus: { $set: LoadStatus.Error } } },
      });
    } else {
      return state;
    }
  }),
  on(addMoreMessagesForConversation, (state, { segmentMessages }) => {
    let newState = state;
    segmentMessages.forEach(({ segmentId, messages: { messages, nextToken } }) => {
      const newMessagesForSegment = messages.map((message) => MessageUtil.hydrateBackendMessage(message));

      const existingMessages = state.messages?.[segmentId]?.messages || [];
      const idsOfExistingMessages = existingMessages.map(({ id }) => id);

      const unknownMessages = newMessagesForSegment.filter(({ id }) => !idsOfExistingMessages.includes(id));

      const paginatedMessages: Spec<PaginatedMessages> = isNil(nextToken)
        ? {
            messages: { $unshift: unknownMessages },
            hasMoreMessages: { $set: false },
            nextToken: { $set: nextToken },
          }
        : {
            messages: { $unshift: unknownMessages },
            hasMoreMessages: { $set: true },
            nextToken: { $set: nextToken },
          };

      newState = update(newState, {
        messages: {
          [segmentId]: paginatedMessages,
        },
      });
    });
    return newState;
  }),
  on(addInitialMessagesForConversation, (state, { segmentMessages }) => {
    let newState = state;
    segmentMessages.forEach(({ segmentId, messages: { messages, nextToken } }) => {
      const cachedMessages = state.messages[segmentId]?.messages;

      const newMessagesForSegment = messages
        .map((message) => MessageUtil.hydrateBackendMessage(message))
        .map((messageFromBackend) => {
          const cachedMessage = cachedMessages?.find((message) => message.id === messageFromBackend.id);

          if (cachedMessage) {
            return update(cachedMessage, mergeMessage(messageFromBackend));
          } else {
            return messageFromBackend;
          }
        });

      const paginatedMessagesForSegment: PaginatedMessages = isNil(nextToken)
        ? {
            messages: newMessagesForSegment,
            hasMoreMessages: false,
            nextToken,
          }
        : {
            messages: newMessagesForSegment,
            hasMoreMessages: true,
            nextToken,
          };
      newState = update(newState, {
        messages: {
          [segmentId]: {
            $set: paginatedMessagesForSegment,
          },
        },
      });
    });
    return newState;
  }),
  on(newBackendMessage, (state, { message }) => {
    const newMessage = MessageUtil.setDecryptionStatusForMessage(message);

    const { conversationId: segmentId } = newMessage;
    const conversations = state.conversations;
    if (!conversations) {
      return state;
    }
    const conversationToUpdate = findConversationForSegment(conversations, segmentId);
    if (!conversationToUpdate) {
      return state;
    }
    return update(state, {
      messages: { [segmentId]: { messages: { $push: [newMessage] } } },
    });
  }),
  on(newOwnBackendMessage, newLocalMessage, (state, { message }) => {
    const newMessage = MessageUtil.setDecryptionStatusForMessage(message);

    const { conversationId: segmentId } = newMessage;
    const messageWithSameId = state.messages?.[segmentId]?.messages.find(
      (existingMessage) => existingMessage.id === newMessage.id
    );
    if (messageWithSameId) {
      return state;
    }
    const conversations = state.conversations;
    if (!conversations) {
      return state;
    }
    const conversationToUpdate = findConversationForSegment(conversations, segmentId);
    if (!conversationToUpdate) {
      return state;
    }
    return update(state, {
      messages: { [segmentId]: { messages: { $push: [newMessage] } } },
    });
  }),
  on(updateMessageMedia, (state, { message, media }) => {
    const mediaIndex = message.media?.findIndex((m) => areSameMedia(m, media));
    if (mediaIndex === undefined) {
      return state;
    }
    return performMessageUpdate<DecryptedMessageWithMedia>(state, message.conversationId, [
      {
        messageId: message.id,
        messageUpdate: { media: { [mediaIndex]: { $merge: media } } as Spec<DecryptedMessageMedia[]> },
      },
    ]);
  }),
  on(updateMessages, (state, { segmentUpdates }) => {
    let updatedState = state;
    segmentUpdates
      .filter(
        (segmentUpdate): segmentUpdate is { segmentId: string; messageUpdates: MessageUpdate[] } =>
          !!(segmentUpdate && segmentUpdate.segmentId && segmentUpdate.messageUpdates)
      )
      .forEach(({ segmentId, messageUpdates }) => {
        updatedState = performMessageUpdate(updatedState, segmentId, messageUpdates);
      });
    return updatedState;
  }),
  on(updateMessage, (state, { message }) => {
    let messageUpdate: Partial<Message> = message;
    if (message.isDeleted) {
      messageUpdate = MessageUtil.mapToDeletedMessageUpdate(message);
    }
    return performMessageUpdate(state, message.conversationId, [
      {
        messageId: message.id,
        messageUpdate: { $merge: messageUpdate },
      },
    ]);
  }),
  on(updateMessageLocally, (state, { message }) =>
    performMessageUpdate(state, message.conversationId, [
      {
        messageId: message.id,
        messageUpdate: { $merge: message },
      },
    ])
  ),
  on(readMessage, (state, { message }) =>
    performMessageUpdate(state, message.conversationId, [
      {
        messageId: message.id,
        messageUpdate: {
          readByRecipient: { $set: true },
          readByRecipientAt: { $set: Math.floor(Date.now() / 1000) },
        },
      },
    ])
  ),
  on(readOwnMessage, (state, { message }) =>
    performMessageUpdate(state, message.conversationId, [
      {
        messageId: message.id,
        messageUpdate: { displayOwnAsUnread: { $set: false } },
      },
    ])
  ),
  on(updateDecryptedConversationSegments, (state, { decryptionResults, conversationId }) => {
    if (state.conversations?.[conversationId]) {
      const addDecryptionResultToSegment = (segment: ConversationSegment): ConversationSegment => {
        const decryptionResult = decryptionResults.find(({ segmentId }) => segmentId === segment.id);
        if (decryptionResult) {
          const decryptedConversationPassword =
            decryptionResult.decryptionStatus === 'decrypted' ? decryptionResult.decryptedPassword : undefined;
          return update(segment, {
            decryptedConversationPassword: {
              $set: decryptedConversationPassword,
            },
            decryptionStatus: { $set: decryptionResult.decryptionStatus },
          });
        }
        return segment;
      };
      return update(state, {
        conversations: {
          [conversationId]: {
            segments: (segments) => segments.map(addDecryptionResultToSegment),
          },
        },
      });
    }
    return state;
  }),
  on(startSelfTyping, (state, { conversationId, timestamp }) =>
    update(state, {
      selfTyping: { [conversationId]: { $set: { isTyping: true, timestamp } } },
    })
  ),
  on(stopSelfTyping, (state, { conversationId, startTimestamp }) => {
    const currentStatus = state.selfTyping[conversationId];
    const isSameTypingEvent = currentStatus && currentStatus.isTyping && currentStatus.timestamp === startTimestamp;
    if (isSameTypingEvent) {
      return update(state, { selfTyping: { [conversationId]: { $set: { isTyping: false } } } });
    } else {
      return state;
    }
  }),
  on(openImagePickerOnInit, (state, { conversationId }) =>
    update(state, { conversationsThatOpenImagePickerOnInit: { $add: [conversationId] } })
  ),
  on(stopOpeningImagePickerOnInit, (state, { conversationId }) =>
    update(state, { conversationsThatOpenImagePickerOnInit: { $remove: [conversationId] } })
  )
);

export function chatReducer(state: ChatState | undefined, action: Action): ChatState {
  return _chatReducer(state, action);
}
