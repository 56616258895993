import gql from 'graphql-tag';
import { appointmentV2Attributes } from '../attributes';

export default gql`
    query getAppointmentV2($id: ID!) {
        getAppointmentV2(id: $id) {
            __typename
            ${appointmentV2Attributes}
        }
    }
`;
