<ion-input
  #input
  (ngModelChange)="onValueChange($event)"
  [disabled]="disabled"
  [maxlength]="maxlength"
  [ngModel]="value"
  [placeholder]="placeholder"
  [autocapitalize]="autocapitalize"
  [type]="type"
  fill="outline"
  mode="md"
  shape="round"
>
  @if (iconLeft) {
    <ion-icon slot="start" [src]="iconLeft | iconName" aria-hidden="true"></ion-icon>
  }
  @if (value && clearButton) {
    <ion-button (click)="onValueChange('')" fill="clear" slot="end" aria-label="Clear">
      <ion-icon [src]="'close' | iconName" slot="icon-only" aria-hidden="true"></ion-icon>
    </ion-button>
  }
</ion-input>
