import update, { Spec } from 'immutability-helper';
import { BackendMessage } from '../../types/src/backendMessage';
import BackendUserConversation from '../../types/src/backendUserConversation';
import { ChatUser } from '../../types/src/chatUser';
import { Conversation, ConversationAndLastMessage, ConversationSegment } from '../../types/src/conversation';
import { LoadStatus } from '../../types/src/loadStatus';
import Message from '../../types/src/message';
import { ChatUserMappingUtil } from './chat-user-mapping.util';
import { MessageUtil } from './chat/message.util';

type BackendUserConversationGroup = BackendUserConversation[];

export class ConversationMappingUtil {
  public static mapUserConversations(userConversations: BackendUserConversation[]): ConversationAndLastMessage[] {
    const sortedUserConversations = userConversations.toSorted(
      (a, b) => Number(a.conversation.createdAt) - Number(b.conversation.createdAt)
    );
    const groupedUserConversations = ConversationMappingUtil.groupUserConversationsByGroupId(sortedUserConversations);

    return groupedUserConversations.map((group) => ConversationMappingUtil.mapGroupToConversation(group));
  }

  public static mapNewBackendUserConversationToExistingConversation(
    newUserConversation: BackendUserConversation,
    existingConversation: Conversation
  ): ConversationAndLastMessage {
    const conversationSpec: Spec<Conversation> = {};

    const lastMessageFromBackend = newUserConversation.conversation.lastMessage;
    const lastMessage = lastMessageFromBackend ? MessageUtil.hydrateBackendMessage(lastMessageFromBackend) : undefined;

    const newSegment = ConversationMappingUtil.mapUserConversationToSegment(newUserConversation);
    conversationSpec.segments = { $push: [newSegment] };

    const conversation = update(existingConversation, conversationSpec);
    return { conversation, lastMessage };
  }

  public static mapUpdatedBackendUserConversationToExistingConversation(
    updatedUserConversation: BackendUserConversation,
    existingConversation: Conversation
  ): Conversation {
    const updatedChatPartner = this.getChatPartner(updatedUserConversation);
    const updatedSegment = ConversationMappingUtil.mapUserConversationToSegment(updatedUserConversation);
    const segmentIndexToUpdate = existingConversation.segments.findIndex((segment) => segment.id === updatedSegment.id);

    const existingConversationSegment = existingConversation.segments[segmentIndexToUpdate];
    if (existingConversationSegment?.decryptionStatus) {
      updatedSegment.decryptionStatus = existingConversationSegment.decryptionStatus;
    }

    return update(existingConversation, {
      segments: { [segmentIndexToUpdate]: { $merge: updatedSegment } },
      chatPartner: { $set: updatedChatPartner },
    });
  }

  public static mapNewBackendUserConversationToNewConversation(
    userConversation: BackendUserConversation
  ): ConversationAndLastMessage {
    return ConversationMappingUtil.mapGroupToConversation([userConversation]);
  }

  public static getChatPartner(userConversation: BackendUserConversation): ChatUser {
    const participants = userConversation.participants;
    const chatPartner = participants.find((participant) => participant.cognitoId !== userConversation.ownerId);
    if (!chatPartner) {
      throw new Error('Could not find chat partner');
    }
    return ChatUserMappingUtil.mapBackendChatUserToChatUser(chatPartner);
  }

  private static groupUserConversationsByGroupId(
    userConversations: BackendUserConversation[]
  ): BackendUserConversationGroup[] {
    const groupedUserConversations: BackendUserConversation[][] = [];

    for (const userConversation of userConversations) {
      if (userConversation.conversation.firstSegmentId) {
        const groupIndex = groupedUserConversations.findIndex(
          (group) => group[0]?.conversationId === userConversation.conversation.firstSegmentId
        );
        if (groupIndex >= 0) {
          (groupedUserConversations[groupIndex] as BackendUserConversation[]).push(userConversation);
        } else {
          groupedUserConversations.push([userConversation]);
        }
      } else {
        groupedUserConversations.push([userConversation]);
      }
    }
    return groupedUserConversations;
  }

  private static mapGroupToConversation(group: BackendUserConversationGroup): ConversationAndLastMessage {
    const oldestBackendUserConversation = group[0] as BackendUserConversation;

    const chatPartner = this.getChatPartner(oldestBackendUserConversation);

    const segments = group.map((userConversation) =>
      ConversationMappingUtil.mapUserConversationToSegment(userConversation)
    );

    const id =
      oldestBackendUserConversation.conversation.firstSegmentId && group.length === 1
        ? oldestBackendUserConversation.conversation.firstSegmentId
        : oldestBackendUserConversation.conversationId;

    const lastBackendMessage = this.getLastMessage(group);
    let lastMessage: Message | undefined;
    if (lastBackendMessage) {
      lastMessage = MessageUtil.hydrateBackendMessage(lastBackendMessage);
    }
    const intent = oldestBackendUserConversation.conversation.intent;
    const appContext = oldestBackendUserConversation.conversation.appContext;
    const earliestExpirationTimestamp = oldestBackendUserConversation.conversation.earliestExpirationTimestamp;
    const encryptedShoppingCart = oldestBackendUserConversation.conversation.encryptedShoppingCart;
    const encryptedAppointment = oldestBackendUserConversation.conversation.encryptedAppointment;
    const appointmentId = oldestBackendUserConversation.conversation.appointmentId;
    const ticketHistory = this.getTicketHistory(group, id);
    const deletionRecord = this.getDeletionRecord(group, id);
    const archivedByEnduser = this.getArchivedByEnduser(group, id);
    const reminderNotification = oldestBackendUserConversation.reminderNotification;
    const showReminder = oldestBackendUserConversation.showReminder;
    const baseConversation: Omit<Conversation, 'chatPartner'> = {
      id,
      segments,
      intent,
      appContext,
      earliestExpirationTimestamp,
      encryptedShoppingCart,
      encryptedAppointment,
      appointmentId,
      ticketHistory,
      deletionRecord,
      archivedByEnduser,
      reminderNotification,
      showReminder,
      messagesInitializationStatus: LoadStatus.Init,
      conversationLink: oldestBackendUserConversation.conversationLink,
    };

    return { conversation: { ...baseConversation, chatPartner }, lastMessage };
  }

  private static getTicketHistory(group: BackendUserConversation[], id: string) {
    const firstBackendConversation = group.find((userConversation) => userConversation.conversationId === id);
    return firstBackendConversation?.conversation?.ticketHistory;
  }

  private static getArchivedByEnduser(group: BackendUserConversation[], id: string) {
    const firstBackendConversation = group.find((userConversation) => userConversation.conversationId === id);
    return firstBackendConversation?.conversation?.archivedByEnduser;
  }

  private static getDeletionRecord(group: BackendUserConversation[], id: string) {
    const firstBackendConversation = group.find((userConversation) => userConversation.conversationId === id);
    return firstBackendConversation?.conversation?.deletionRecord;
  }

  private static getLastMessage(group: BackendUserConversationGroup): BackendMessage | undefined {
    const conversationWithLastMessage = [...group]
      .reverse()
      .find((backendUserConversation) => !!backendUserConversation.conversation.lastMessage);
    return conversationWithLastMessage ? conversationWithLastMessage.conversation.lastMessage : undefined;
  }

  private static mapUserConversationToSegment({
    conversation: { createdAt, id },
    encryptedChatPartnerNickname,
    encryptedConversationPassword,
    id: backendUserConversationId,
  }: BackendUserConversation): ConversationSegment {
    return {
      id,
      createdAt,
      encryptedConversationPassword,
      encryptedChatPartnerNickname,
      decryptionStatus: 'encrypted',
      backendUserConversationId,
    };
  }
}
