import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import groupBy from 'lodash-es/groupBy';
import isNil from 'lodash-es/isNil';
import pick from 'lodash-es/pick';
import { firstValueFrom } from 'rxjs';
import { filter, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Dictionary } from 'ts-essentials';
import {
  decryptConversationSegments,
  decryptConversationSegmentsAndMessage,
  DecryptionResult,
  decryptMessages,
  decryptUndecryptedMessages,
  updateAppointment,
  updateDecryptedAppointment,
  updateDecryptedConversationSegments,
  updateDecryptedShoppingCartInChat,
  updateShoppingCartInChat,
} from '../../../../../../store/src/common-store/chat-store/actions/chat-decryption.actions';
import { updateMessages } from '../../../../../../store/src/common-store/chat-store/actions/chat-message.actions';
import { MessageUpdate } from '../../../../../../store/src/common-store/chat-store/reducers/util/chat-reducer.util';
import {
  selectConversation,
  selectConversationPassword,
  selectMessagesOfConversation,
} from '../../../../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { CommonState } from '../../../../../../store/src/common-store/common.state';
import { selectCognitoId } from '../../../../../../store/src/common-store/user-store/selectors/user.selectors';
import { Appointment } from '../../../../../../essentials/types/src/appointment';
import { ConversationSegment } from '../../../../../../essentials/types/src/conversation';
import Message from '../../../../../../essentials/types/src/message';
import { SubmittedShoppingCart } from '../../../../../../essentials/types/src/shoppingCart';
import { isMessageDecrypted } from '../../../../../../essentials/util/src/message-decryption.util';
import { isNotNullOrUndefined } from '../../../../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { EncryptionService } from '../../../services/encryption/encryption.service';
import { PrivateKeyStoreService } from '../../../services/encryption/private-key-store.service';
import { ChatStoreMessageDecryptionService } from './services/chat-store-message-decryption.service';

@Injectable()
export class ChatDecryptionEffects {
  decryptConversationSegments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(decryptConversationSegments),
        withLatestFrom(this.store.select(selectCognitoId)),
        mergeMap(async ([{ conversation }, cognitoId]) => {
          if (!cognitoId) {
            return;
          }

          const privateKey = await this.privateKeyStoreService.getPrivateKey(cognitoId);
          if (!privateKey) {
            return;
          }

          const decryptionResults: DecryptionResult[] = conversation.segments
            .filter((segment) => segment.decryptionStatus === 'encrypted')
            .map((segment) => this.decryptConversationSegment(segment, privateKey));
          this.store.dispatch(
            updateDecryptedConversationSegments({
              decryptionResults,
              conversationId: conversation.id,
            })
          );
          if (
            decryptionResults[0] &&
            decryptionResults[0].segmentId === conversation.segments[0]?.id &&
            decryptionResults[0].decryptionStatus === 'decrypted'
          ) {
            if (conversation.encryptedShoppingCart) {
              this.store.dispatch(
                updateShoppingCartInChat({
                  conversationId: conversation.id,
                  encryptedShoppingCart: conversation.encryptedShoppingCart,
                })
              );
            }
            if (conversation.encryptedAppointment) {
              this.store.dispatch(
                updateAppointment({
                  conversationId: conversation.id,
                  encryptedAppointment: conversation.encryptedAppointment,
                })
              );
            }
          }
        })
      ),
    { dispatch: false }
  );

  retryDecryptingMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDecryptedConversationSegments),
      mergeMap(async ({ conversationId }) => {
        const messages = await firstValueFrom(this.store.select(selectMessagesOfConversation(conversationId)));
        const messagesWithFailedDecryption = messages.filter((message) => message.decryptionStatus === 'failed');
        return { conversationId, messagesWithFailedDecryption };
      }),
      filter(({ messagesWithFailedDecryption }) => messagesWithFailedDecryption.length > 0),
      map(({ messagesWithFailedDecryption, conversationId }) =>
        decryptMessages({ conversationId, messages: messagesWithFailedDecryption })
      )
    )
  );

  decryptUndecryptedMessagesOfConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(decryptUndecryptedMessages),
      map(({ messages, conversationId }) => {
        const undecryptedMessages = messages.filter((message) => !isMessageDecrypted(message));
        return { undecryptedMessages, conversationId };
      }),
      filter(({ undecryptedMessages }) => undecryptedMessages.length > 0),
      map(({ undecryptedMessages, conversationId }) =>
        decryptMessages({ conversationId, messages: undecryptedMessages })
      )
    )
  );

  decryptConversationSegmentsAndMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(decryptConversationSegmentsAndMessage),
      tap(({ conversation }) => this.store.dispatch(decryptConversationSegments({ conversation }))),
      mergeMap(({ conversation, message }) =>
        this.store.select(selectConversation(conversation.id)).pipe(
          filter((currentConversation) => {
            const segmentWithMessage = currentConversation?.segments.find(
              (segment) => segment.id === message.conversationId
            );
            return !isNil(segmentWithMessage) && segmentWithMessage.decryptionStatus !== 'encrypted';
          }),
          take(1),
          map(() => decryptMessages({ conversationId: conversation.id, messages: [message] }))
        )
      )
    )
  );

  decryptMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(decryptMessages),
      mergeMap(({ conversationId, messages }) =>
        this.store.select(selectConversation(conversationId)).pipe(
          isNotNullOrUndefined(),
          take(1),
          map((conversation) => ({ conversation, messages }))
        )
      ),
      withLatestFrom(this.store.select(selectCognitoId)),
      mergeMap(async ([{ conversation, messages }, cognitoId]) => {
        const messagesBySegment: Dictionary<Message[]> = groupBy(messages, 'conversationId');

        const updates: { segmentId: string; messageUpdates: MessageUpdate[] }[] = await Promise.all(
          conversation.segments.map(async (segment) => {
            const messagesInSegment = messagesBySegment[segment.id];
            if (cognitoId && messagesInSegment) {
              const decryptedMessages = await this.chatStoreMessageDecryptionService.decryptMessages(
                messagesInSegment,
                segment.decryptedConversationPassword
              );
              return {
                segmentId: segment.id,
                messageUpdates: decryptedMessages.map((message) => ({
                  messageId: message.id,
                  messageUpdate: {
                    $merge: pick(message, ['decryptedTextContent', 'decryptionStatus', 'media']),
                  },
                })),
              };
            }
            return { segmentId: segment.id, messageUpdates: [] };
          })
        );

        return updateMessages({ segmentUpdates: updates });
      })
    )
  );

  decryptShoppingCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateShoppingCartInChat),
      mergeMap(async ({ conversationId, encryptedShoppingCart }) => {
        const conversationPassword = await firstValueFrom(
          this.store.select(selectConversationPassword(conversationId)).pipe(isNotNullOrUndefined())
        );
        const decryptedShoppingCartString = this.encryptionService.decryptUsingPassword(
          encryptedShoppingCart,
          conversationPassword
        );
        const shoppingCart: SubmittedShoppingCart = JSON.parse(decryptedShoppingCartString);
        return updateDecryptedShoppingCartInChat({ conversationId, shoppingCart });
      })
    )
  );

  decryptAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAppointment),
      mergeMap(async ({ conversationId, encryptedAppointment }) => {
        const conversationPassword = await firstValueFrom(
          this.store.select(selectConversationPassword(conversationId)).pipe(isNotNullOrUndefined())
        );
        const decryptedAppointmentString = this.encryptionService.decryptUsingPassword(
          encryptedAppointment,
          conversationPassword
        );
        const appointment: Appointment = JSON.parse(decryptedAppointmentString);
        return updateDecryptedAppointment({ conversationId, appointment });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private chatStoreMessageDecryptionService: ChatStoreMessageDecryptionService,
    private encryptionService: EncryptionService,
    private privateKeyStoreService: PrivateKeyStoreService,
    private store: Store<CommonState>
  ) {}

  private decryptConversationSegment(
    { encryptedConversationPassword, id }: ConversationSegment,
    privateKey: string
  ): DecryptionResult {
    if (!encryptedConversationPassword) {
      return { segmentId: id, decryptionStatus: 'failed' };
    }
    const decryptedPassword = this.encryptionService.decryptUsingPrivateKey(encryptedConversationPassword, privateKey);
    if (!decryptedPassword) {
      return { segmentId: id, decryptionStatus: 'failed' };
    }
    return {
      segmentId: id,
      decryptedPassword,
      decryptionStatus: 'decrypted',
    };
  }
}
