import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import UserKey from '../../../../../essentials/types/src/userKey';
import { CommonState } from '../../../../../store/src/common-store/common.state';
import { setLoadingStatus } from '../../../../../store/src/common-store/loading-status-store/actions/loading-status.actions';
import resetKeyPairForEndUser, { ResetKeyPairEndUserInput } from '../../graphql/mutations/resetKeyPairEndUser';
import resetKeyPairForPharmacy, { ResetKeyPairPharmacyInput } from '../../graphql/mutations/resetKeyPairPharmacy';
import { AppsyncConversationService } from '../appsync/appsync-conversation.service';
import { AppsyncService } from '../appsync/appsync.service';
import { ConversationCreationService, ConversationKeyPair } from '../conversation-creation.service';
import { EncryptionService } from './encryption.service';
import { EncryptionResult, PrivateKeyBackupService } from './private-key-backup.service';

export type CreateConversationInput = ConversationKeyPair & { conversationId: string; otherParticipantId: string };

@Injectable({
  providedIn: 'root',
})
export class ResetKeyPairService {
  constructor(
    private appSync: AppsyncService,
    private appsyncConversationService: AppsyncConversationService,
    private conversationCreationService: ConversationCreationService,
    private encryptionService: EncryptionService,
    private privateKeyBackupService: PrivateKeyBackupService,
    private store: Store<CommonState>
  ) {}

  async createNewConversationsForKeyPairResetPharmacy(
    newPublicKey: string,
    privateKeyEncryptionResult: EncryptionResult
  ): Promise<any> {
    this.setLoadingStatusToPrepareRestore();
    const conversationsToCreate = await this.getConversationsToCreate(newPublicKey);

    const chunkSize = 50;
    for (let i = 0; i < conversationsToCreate.length; i += chunkSize) {
      this.setLoadingStatus(i, conversationsToCreate, chunkSize);
      const partialConversationsToCreate = conversationsToCreate.slice(i, i + chunkSize);
      const variables: ResetKeyPairPharmacyInput = {
        conversationsToCreate: partialConversationsToCreate,
        publicKey: newPublicKey,
        privateKeyEncryptionResult,
      };

      const client = await this.appSync.getClient();
      await client.mutate({
        mutation: resetKeyPairForPharmacy,
        variables,
      } as any);
    }
  }

  async createNewConversationsForKeyPairResetEndUser(
    newPublicKey: string,
    userKey: UserKey,
    encryptionSalt: string
  ): Promise<any> {
    const conversationsToCreate = await this.getConversationsToCreate(newPublicKey);
    const variables: ResetKeyPairEndUserInput = {
      conversationsToCreate,
      publicKey: newPublicKey,
      userKey,
      encryptionSalt,
    };

    const client = await this.appSync.getClient();
    await client.mutate({
      mutation: resetKeyPairForEndUser,
      variables,
    } as any);
  }

  private async getConversationsToCreate(currentUserPublicKey: string): Promise<CreateConversationInput[]> {
    const conversations = (await this.appsyncConversationService.getAllConversations()).map(
      ({ conversation }) => conversation
    );
    return conversations.map((conversation) => {
      const chatPartner = conversation.chatPartner;
      const chatPartnerPublicKey = chatPartner.publicKey;
      const conversationKeyPair = this.conversationCreationService.createEncryptedConversationPasswords(
        currentUserPublicKey,
        chatPartnerPublicKey
      );
      return { ...conversationKeyPair, conversationId: conversation.id, otherParticipantId: chatPartner.cognitoId };
    });
  }

  private setLoadingStatus(i: number, conversationsToCreate: CreateConversationInput[], chunkSize: number) {
    this.store.dispatch(
      setLoadingStatus({
        requestKey: 'backupRestore',
        message: this.getProgressMessage(i, conversationsToCreate.length, chunkSize),
      })
    );
  }

  private setLoadingStatusToPrepareRestore() {
    this.store.dispatch(
      setLoadingStatus({
        requestKey: 'backupRestore',
        message: 'Zurücksetzen des Sicherheitsschlüssels wird vorbereitet',
      })
    );
  }

  private getProgressMessage(progress: number, numberOfItems: number, chunkSize: number) {
    if (numberOfItems < chunkSize) {
      return 'Zurücksetzen des Sicherheitsschlüssels wird durchgeführt';
    }
    const lowerBound = Math.floor((progress / numberOfItems) * 100);
    const upperBound = Math.min(Math.floor(((progress + chunkSize) / numberOfItems) * 100), 100);
    const average = Math.floor((lowerBound + upperBound) / 2);
    return `Fortschritt: ${average}%`;
  }
}
