import { BackendMessage } from '../../types/src/backendMessage';
import Message from '../../types/src/message';

type AnyMessage = Message | BackendMessage;

export class UnreadMessagesUtil {
  public static getUnreadMessagesIds(cognitoId: string, messages: AnyMessage[]) {
    return messages.filter((message) => UnreadMessagesUtil.countMessageAsUnread(cognitoId, message)).map((m) => m.id);
  }

  public static countMessageAsUnread(cognitoId: string, message: AnyMessage) {
    return (
      UnreadMessagesUtil.messageIsUnread(cognitoId, message) && !UnreadMessagesUtil.isIgnoredInitialMessage(message)
    );
  }

  public static messageIsUnread(cognitoId: string, message: AnyMessage) {
    return (
      (message.recipientId === cognitoId && !message.readByRecipient) ||
      (message.senderId === cognitoId && !!message.displayOwnAsUnread)
    );
  }

  public static isIgnoredInitialMessage(message: AnyMessage) {
    return (
      !!message.initialMessage &&
      !message.conversation?.encryptedAppointment &&
      !message.conversation?.encryptedShoppingCart &&
      !message.conversation?.appointmentId
    );
  }
}
