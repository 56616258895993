import { InjectionToken } from '@angular/core';
import { SubmittedShoppingCart } from '../shoppingCart';

export const SHOP_COOP_SERVICE = new InjectionToken<ShopCoopServiceInterface>('SHOP_COOP_SERVICE');

export interface ShopCoopServiceInterface {
  sendShoppingCartToShop(shoppingCart: SubmittedShoppingCart): Promise<boolean>;
  updateSubmittedShoppingCart(shoppingCart: SubmittedShoppingCart): Promise<SubmittedShoppingCart | undefined>;
  trackSendShoppingCartToWawi(shoppingCart: SubmittedShoppingCart): Promise<void>;
}
