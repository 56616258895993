import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../essentials/types/src/actionType';
import { DecryptedAppointmentV2 } from '../../../../essentials/types/src/appointmentV2';
import { AppointmentV2Type } from '../../../../essentials/types/src/appointmentV2Type';

export const loadAppointmentV2Types = createAction(`${ActionType.AppointmentV2}: Load appointmentV2 types`);
export const loadAppointmentV2TypesSuccess = createAction(
  `${ActionType.AppointmentV2}: Load appointmentV2 types success`,
  props<{ appointmentV2Types: AppointmentV2Type[] }>()
);
export const setAppointmentV2Type = createAction(
  `${ActionType.AppointmentV2}: Set appointmentV2 type`,
  props<{ appointmentV2Type: AppointmentV2Type }>()
);
export const deleteAppointmentV2Type = createAction(
  `${ActionType.AppointmentV2}: Delete appointmentV2 type`,
  props<{ id: string }>()
);
export const loadAppointmentV2TypesFailure = createAction(
  `${ActionType.AppointmentV2}: Load appointmentV2 types failure`
);

export const loadAppointmentV2 = createAction(
  `${ActionType.AppointmentV2}: Load appointmentV2`,
  props<{ appointmentId: string }>()
);
export const loadAppointmentV2Initialized = createAction(
  `${ActionType.AppointmentV2}: Load appointmentV2 initialized`,
  props<{ appointmentId: string }>()
);
export const loadAppointmentV2Success = createAction(
  `${ActionType.AppointmentV2}: Load appointmentV2 success`,
  props<{ appointmentV2: DecryptedAppointmentV2 }>()
);
export const loadAppointmentV2Failure = createAction(
  `${ActionType.AppointmentV2}: Load appointmentV2 failure`,
  props<{ appointmentId: string }>()
);

export const loadAppointmentsV2 = createAction(
  `${ActionType.AppointmentV2}: Load appointmentsV2`,
  props<{ month: string }>()
);
export const loadAppointmentsV2Success = createAction(
  `${ActionType.AppointmentV2}: Load appointmentsV2 success`,
  props<{ appointmentsV2: DecryptedAppointmentV2[]; month: string }>()
);
export const loadAppointmentsV2Failure = createAction(
  `${ActionType.AppointmentV2}: Load appointmentsV2 failure`,
  props<{ month: string }>()
);
export const setAppointmentV2 = createAction(
  `${ActionType.AppointmentV2}: Set appointmentV2`,
  props<{ appointmentV2: DecryptedAppointmentV2; month: string }>()
);
export const setActiveMonths = createAction(
  `${ActionType.AppointmentV2}: Set active months`,
  props<{ activeMonths: string[] }>()
);
