import { AsyncPipe, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { IonicModule, IonNav } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { combineLatest, firstValueFrom, Observable, ReplaySubject, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppsyncCommonAppointmentService } from '../../../../../common/resources/src/services/appsync/appsync-common-appointment.service';
import { IconButtonComponent } from '../../../../../common/ui-components/src/buttons/icon-button/icon-button.component';
import { ErrorComponent } from '../../../../../common/ui-components/src/errors/error/error.component';
import { CustomModalController } from '../../../../../common/ui-components/src/ionic/controllers/custom-modal.controller';
import { BlockingAppointmentV2 } from '../../../../../essentials/types/src/appointmentV2';
import { AppointmentV2Type } from '../../../../../essentials/types/src/appointmentV2Type';
import {
  AppointmentAvailabilityUtil,
  PharmacyOpeningData,
} from '../../../../../essentials/util/src/appointment-availability.util';
import { AppointmentTimeslotUtil } from '../../../../../essentials/util/src/appointment-timeslot.util';
import { BerlinTimePipe } from '../../../../../essentials/util/src/pipes/berlin-time.pipe';
import { selectActiveConversation } from '../../../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { CommonState } from '../../../../../store/src/common-store/common.state';
import { AppointmentConfirmationComponent } from '../appointment-confirmation/appointment-confirmation.component';

dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);

interface AvailableAppointment {
  appointmentType: AppointmentV2Type;
  availableDatesAndTimes: {
    date: string;
    times: { startTime: string; endTime?: string }[];
  }[];
}

@Component({
  templateUrl: './appointment-selection.component.html',
  styleUrls: ['./appointment-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, IconButtonComponent, ErrorComponent, AsyncPipe, DatePipe, TranslateModule, BerlinTimePipe],
})
export class AppointmentSelectionComponent implements OnInit {
  pharmacy$ = new ReplaySubject<PharmacyOpeningData>(1);
  availableAppointmentTypes$ = new ReplaySubject<AppointmentV2Type[]>(1);
  blockingAppointments$ = new ReplaySubject<BlockingAppointmentV2[]>(1);

  groupedAvailableAppointments$: Observable<AvailableAppointment[]> = combineLatest([
    this.pharmacy$,
    this.availableAppointmentTypes$,
    this.blockingAppointments$,
  ]).pipe(
    map(([pharmacy, availableAppointmentTypes, blockingAppointments]) =>
      availableAppointmentTypes.map((appointmentType) => {
        const availableDatesAndTimes = AppointmentAvailabilityUtil.getAvailableDatesAndTimes({
          selectedAppointmentType: appointmentType,
          pharmacy,
          blockingAppointments,
          leadTime: { respectLeadTime: true, leadTimeInHours: appointmentType.leadTimeInHours },
        });
        const formattedDatesAndTimes = Object.entries(availableDatesAndTimes).map(([date, dateAndTimes]) => ({
          date,
          times: dateAndTimes.times.map((startTime) => ({
            startTime,
            endTime: this.calculateEndTime(startTime, appointmentType),
          })),
        }));
        return { appointmentType, availableDatesAndTimes: formattedDatesAndTimes };
      })
    ),
    shareReplay(1)
  );

  constructor(
    public modalCtrl: CustomModalController,
    private nav: IonNav,
    private appsyncCommonAppointmentsService: AppsyncCommonAppointmentService,
    private store: Store<CommonState>
  ) {}

  async ngOnInit() {
    const chatPartner = await firstValueFrom(
      this.store.select(selectActiveConversation).pipe(map((conversation) => conversation?.chatPartner))
    );
    if (!chatPartner?.pharmacy) {
      return;
    }

    const { pharmacyChatUser, openingHours } = chatPartner.pharmacy;
    this.pharmacy$.next({ pharmacyChatUser, openingHours });

    const availableAppointmentV2Types = (
      await this.appsyncCommonAppointmentsService.getAppointmentV2Types(chatPartner.cognitoId)
    ).filter((appointmentType) => appointmentType.isActive);
    this.availableAppointmentTypes$.next(availableAppointmentV2Types);

    const blockingAppointments = await this.appsyncCommonAppointmentsService.getBlockingAppointmentsV2(
      chatPartner.cognitoId
    );
    this.blockingAppointments$.next(blockingAppointments);
  }

  async selectAppointment(date: string, time: string, appointmentType: AppointmentV2Type) {
    await this.nav.push(AppointmentConfirmationComponent, { date, time, appointmentType });
  }

  calculateEndTime(time: string, appointmentType: AppointmentV2Type) {
    return AppointmentTimeslotUtil.calculateEndTime(time, appointmentType);
  }
}
