import { MimeTypeEnum } from '../../../types/src/mimeTypeEnum';

export class FileUtil {
  static convertBase64ContentToObjectUrl(base64Content: string): { objectUrl: string; fileString: string } {
    const buffer = Buffer.from(base64Content, 'base64');
    const blob = new Blob([buffer], { type: MimeTypeEnum.PDF });
    return {
      objectUrl: URL.createObjectURL(blob),
      fileString: buffer.toString('base64'),
    };
  }

  static convertBase64ContentToFile(base64Content: string, fileName: string): File {
    const buffer = Buffer.from(base64Content, 'base64');
    const blob = new Blob([buffer], { type: MimeTypeEnum.PDF });
    return new File([blob], fileName, { type: MimeTypeEnum.PDF });
  }

  static convertDataUrlToFile(dataUrl: string, fileBaseName: string): File {
    const dataArray = dataUrl.split(',');
    const mimeType = (dataArray[0]?.match(/:(.*?);/) as Array<string>)[1];
    const bitString = atob(dataArray[1] as string);
    let index = bitString.length;
    const uint8Array = new Uint8Array(index);
    while (index--) {
      uint8Array[index] = bitString.charCodeAt(index);
    }
    return new File([uint8Array], `${fileBaseName}.${mimeType?.split('/').pop()}`, { type: mimeType });
  }

  static readFileOrBlobAsDataURL(file: File | Blob): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (fileLoadedEvent: any) => {
        resolve(fileLoadedEvent.target.result);
      };
    });
  }

  static validateSizeIsLessThanMB = (file: File | { size: number }, mb: number): boolean =>
    FileUtil.getFileSizeInMB(file) <= mb;

  static getFileSizeInMB = (file: File | { size: number }): number => file.size / 1024 / 1024;

  static getShortenedFilename(maxLength: number, filename: string | undefined): string {
    if (!filename) {
      return '';
    }
    if (filename.length < maxLength) {
      return filename;
    }
    const shortenedFilename = filename.slice(0, maxLength);
    return `${shortenedFilename}...`;
  }
}
