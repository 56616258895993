import {
  isShoppingCartEHealthPrescription,
  isShoppingCartERezeptMedia,
  isShoppingCartFileMedia,
  isShoppingCartImageMedia,
  ShoppingCartProduct,
  SubmittedShoppingCart,
} from '../../types/src/shoppingCart';

export type PriceCategory = '0' | '0-5' | '5-15' | '15-25' | '25-50' | '50+';

export class ShoppingCartUtil {
  static getShoppingCartDescription(submittedShoppingCart: SubmittedShoppingCart) {
    const numberOfProducts = submittedShoppingCart.products.length + submittedShoppingCart.productImages.length;
    const numberOfPrescriptions = submittedShoppingCart.prescriptions.length;
    const productText =
      numberOfProducts > 1 ? `${numberOfProducts} Produkte` : numberOfProducts === 1 ? '1 Produkt' : undefined;
    const prescriptionText =
      numberOfPrescriptions > 1
        ? `${numberOfPrescriptions} Rezepte`
        : numberOfPrescriptions === 1
          ? '1 Rezept'
          : undefined;
    return productText && prescriptionText
      ? `${productText}, ${prescriptionText}`
      : productText || prescriptionText || undefined;
  }

  static sortShoppingCartPrescriptionItems(shoppingCart: SubmittedShoppingCart) {
    const eHealthPrescriptions = shoppingCart.prescriptions
      .filter(isShoppingCartERezeptMedia)
      .filter(isShoppingCartEHealthPrescription);
    const eRezepts = shoppingCart.prescriptions
      .filter(isShoppingCartERezeptMedia)
      .filter((media) => !isShoppingCartEHealthPrescription(media));
    const prescriptionImages = shoppingCart.prescriptions.filter(isShoppingCartImageMedia);
    const prescriptionFiles = shoppingCart.prescriptions.filter(isShoppingCartFileMedia);
    return { eHealthPrescriptions, eRezepts, prescriptionImages, prescriptionFiles };
  }

  static getNumberOfPrescriptionItems(shoppingCart: SubmittedShoppingCart) {
    const { eHealthPrescriptions, eRezepts, prescriptionImages, prescriptionFiles } =
      ShoppingCartUtil.sortShoppingCartPrescriptionItems(shoppingCart);
    return {
      eHealthPrescriptions: eHealthPrescriptions.length,
      eRezepts: eRezepts.length,
      prescriptionImages: prescriptionImages.length,
      prescriptionFiles: prescriptionFiles.length,
    };
  }

  static getTotalNumberOfProductItems(products: ShoppingCartProduct[]) {
    let totalNumberOfProductItems = 0;
    for (const product of products) {
      if (product.selectedAmount) {
        totalNumberOfProductItems += product.selectedAmount;
      }
    }
    return totalNumberOfProductItems;
  }

  static getTotalPriceCategory(products: ShoppingCartProduct[]): PriceCategory {
    const totalPrice = this.getTotalPrice(products);
    if (totalPrice === 0) {
      return '0';
    } else if (totalPrice <= 5) {
      return '0-5';
    } else if (totalPrice <= 15) {
      return '5-15';
    } else if (totalPrice <= 25) {
      return '15-25';
    } else if (totalPrice <= 50) {
      return '25-50';
    } else {
      return '50+';
    }
  }

  static getFlooredTotalPrice(products: ShoppingCartProduct[]): number | undefined {
    const totalPrice = this.getTotalPrice(products);
    if (totalPrice === 0) {
      return undefined;
    } else if (totalPrice <= 1) {
      return 1;
    } else {
      return Math.floor(totalPrice);
    }
  }

  private static getTotalPrice(products: ShoppingCartProduct[]): number {
    let totalPrice = 0;
    for (const product of products) {
      if (product.price && product.selectedAmount) {
        totalPrice += product.price * product.selectedAmount;
      }
    }
    return totalPrice;
  }
}
