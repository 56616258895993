import { Action, createReducer, on } from '@ngrx/store';
import update from 'immutability-helper';
import cloneDeep from 'lodash-es/cloneDeep';
import { LoadStatus } from '../../../../essentials/types/src/loadStatus';
import { markDataWithSubscriptionsAsStale } from '../../common-store/other/actions/subscription.actions';
import { clearUserOnLogout, setUserOnLogin } from '../../common-store/user-store/actions/user.actions';
import {
  deleteAppointmentV2Type,
  loadAppointmentsV2,
  loadAppointmentsV2Failure,
  loadAppointmentsV2Success,
  loadAppointmentV2Failure,
  loadAppointmentV2Initialized,
  loadAppointmentV2Success,
  loadAppointmentV2Types,
  loadAppointmentV2TypesFailure,
  loadAppointmentV2TypesSuccess,
  setActiveMonths,
  setAppointmentV2,
  setAppointmentV2Type,
} from './appointmentV2.actions';
import { AppointmentV2State } from './appointmentV2.state';

export const initialAppointmentV2State: AppointmentV2State = {
  appointmentV2Types: {},
  appointmentV2TypesLoadStatus: LoadStatus.Init,
  appointmentsV2: {},
  appointmentsV2LoadStatus: {},
  appointmentV2IdsLoadStatus: {},
  activeMonths: [],
};

const _appointmentV2Reducer = createReducer(
  initialAppointmentV2State,

  // ************* AppointmentV2Types *************

  on(loadAppointmentV2Types, (state) =>
    update(state, {
      appointmentV2TypesLoadStatus: {
        $set:
          state.appointmentV2TypesLoadStatus === LoadStatus.Init ? LoadStatus.LoadingInitial : LoadStatus.Revalidating,
      },
    })
  ),

  on(loadAppointmentV2TypesSuccess, (state, { appointmentV2Types }) => {
    const appointmentV2TypesDictionary = appointmentV2Types.reduce(
      (dict, appointmentV2Type) => ({ ...dict, [appointmentV2Type.id]: appointmentV2Type }),
      {}
    );
    return update(state, {
      appointmentV2Types: { $set: appointmentV2TypesDictionary },
      appointmentV2TypesLoadStatus: { $set: LoadStatus.UpToDate },
    });
  }),

  on(setAppointmentV2Type, (state, { appointmentV2Type }) =>
    update(state, { appointmentV2Types: { $merge: { [appointmentV2Type.id]: appointmentV2Type } } })
  ),

  on(deleteAppointmentV2Type, (state, { id }) => update(state, { appointmentV2Types: { $unset: [id] } })),

  on(loadAppointmentV2TypesFailure, (state) =>
    update(state, { appointmentV2TypesLoadStatus: { $set: LoadStatus.Error } })
  ),

  on(markDataWithSubscriptionsAsStale, (state) => {
    if (state.appointmentV2TypesLoadStatus === LoadStatus.UpToDate) {
      return update(state, { appointmentV2TypesLoadStatus: { $set: LoadStatus.Stale } });
    } else {
      return state;
    }
  }),

  // ************* AppointmentsV2 *************

  on(loadAppointmentsV2, (state, { month }) => {
    let updatedLoadStatus: LoadStatus;
    if (!state.appointmentsV2LoadStatus[month] || state.appointmentsV2LoadStatus[month] === LoadStatus.Init) {
      updatedLoadStatus = LoadStatus.LoadingInitial;
    } else {
      updatedLoadStatus = LoadStatus.Revalidating;
    }
    return update(state, { appointmentsV2LoadStatus: { $merge: { [month]: updatedLoadStatus } } });
  }),

  on(loadAppointmentsV2Success, (state, { appointmentsV2, month }) => {
    const appointmentsV2Dictionary = appointmentsV2.reduce(
      (dict, appointmentV2) => ({ ...dict, [appointmentV2.id]: appointmentV2 }),
      {}
    );
    return update(state, {
      appointmentsV2: { $merge: { [month]: appointmentsV2Dictionary } },
      appointmentsV2LoadStatus: { $merge: { [month]: LoadStatus.UpToDate } },
    });
  }),

  on(loadAppointmentV2Initialized, (state, { appointmentId }) => {
    return update(state, {
      appointmentV2IdsLoadStatus: { $merge: { [appointmentId]: LoadStatus.LoadingInitial } },
    });
  }),
  on(loadAppointmentV2Failure, (state, { appointmentId }) => {
    return update(state, {
      appointmentV2IdsLoadStatus: { $merge: { [appointmentId]: LoadStatus.Error } },
    });
  }),
  on(loadAppointmentV2Success, (state, { appointmentV2 }) => {
    const month = appointmentV2.date.format('YYYY-MM');
    return update(state, {
      appointmentsV2: { [month]: { $set: { ...state.appointmentsV2[month], [appointmentV2.id]: appointmentV2 } } },
      appointmentV2IdsLoadStatus: { $merge: { [appointmentV2.id]: LoadStatus.UpToDate } },
    });
  }),

  on(setAppointmentV2, (state, { appointmentV2, month }) => {
    if (state.appointmentsV2.hasOwnProperty(month)) {
      return update(state, { appointmentsV2: { [month]: { $merge: { [appointmentV2.id]: appointmentV2 } } } });
    }
    return update(state, { appointmentsV2: { [month]: { $set: { [appointmentV2.id]: appointmentV2 } } } });
  }),

  on(loadAppointmentsV2Failure, (state, { month }) =>
    update(state, { appointmentsV2LoadStatus: { $merge: { [month]: LoadStatus.Error } } })
  ),

  on(setActiveMonths, (state, { activeMonths }) => update(state, { activeMonths: { $set: activeMonths } })),

  on(markDataWithSubscriptionsAsStale, (state) => {
    const loadStatusUpdate = Object.keys(state.appointmentsV2LoadStatus).reduce(
      (acc, key) => {
        acc[key] = { $set: LoadStatus.Stale };
        return acc;
      },
      {} as { [key: string]: any }
    );
    return update(state, { appointmentsV2LoadStatus: loadStatusUpdate });
  }),

  on(clearUserOnLogout, setUserOnLogin, () => cloneDeep(initialAppointmentV2State))
);

export function appointmentV2Reducer(state: AppointmentV2State | undefined, action: Action): AppointmentV2State {
  return _appointmentV2Reducer(state, action);
}
