import { EHealthCardBasePrescription } from './eHealthCard';
import { ExifOrientation } from './messageMedia';
import { ProductDetails } from './product';

export interface SubmittedShoppingCart {
  enduserCognitoId: string;
  pharmacyCognitoId: string;
  shopApiBaseUrl?: string;
  sentToShopTimestamp?: string;

  products: ShoppingCartProduct[];
  productImages: ShoppingCartImageMedia[];
  prescriptions: ShoppingCartMedia[];
}

export interface ShoppingCart extends SubmittedShoppingCart {
  storageDate: number;
  productsHaveBecomeUnavailable: boolean;
  pricesHaveChanged: boolean;
}

export type ShoppingCartItem = ShoppingCartProduct | ShoppingCartMedia;

export interface ShoppingCartProduct extends ProductDetails {
  selectedAmount: number;
  available: boolean;
  type?: ShoppingCartProductType;
}

export const enum ShoppingCartProductType {
  MonthlyOffer = 'MonthlyOffer',
}

export const enum ShoppingCartMediaType {
  ProductImage = 'ProductImage',
  Prescription = 'Prescription',
}

export type ShoppingCartMedia = ShoppingCartImageMedia | ShoppingCartFileMedia | ShoppingCartERezeptMedia;

export interface ShoppingCartImageMedia {
  type: ShoppingCartMediaType;
  id: string;
  attachment: ShoppingCartImageAttachment;
  note?: string;
}

export interface ShoppingCartFileMedia {
  type: ShoppingCartMediaType;
  id: string;
  attachment: ShoppingCartFileAttachment;
  note?: string;
}

export interface ShoppingCartERezeptMedia {
  type: ShoppingCartMediaType;
  id: string;
  attachment: ShoppingCartERezeptAttachment;
  note?: string;
}

export type ShoppingCartAttachment =
  | ShoppingCartImageAttachment
  | ShoppingCartFileAttachment
  | ShoppingCartERezeptAttachment;

export interface ShoppingCartImageAttachment {
  mediaType: 'IMAGE';
  name: string;
  imageDataUrl?: string;
  imageDataKey?: string;
  exifOrientation?: ExifOrientation;
}

export interface ShoppingCartFileAttachment {
  mediaType: 'FILE';
  name: string;
  fileDataUrl?: string;
  fileDataKey?: string;
  thumbnailUrl?: string;
  thumbnailKey?: string;
}

export interface ShoppingCartERezeptAttachment {
  mediaType: 'E_REZEPT';
  eRezept: string[];
  prescriptionInformation?: EHealthCardBasePrescription;
}

export function isShoppingCartImageMedia(media: ShoppingCartMedia): media is ShoppingCartImageMedia {
  return media.attachment.mediaType === 'IMAGE';
}

export function isShoppingCartFileMedia(media: ShoppingCartMedia): media is ShoppingCartFileMedia {
  return media.attachment.mediaType === 'FILE';
}

export function isShoppingCartERezeptMedia(media: ShoppingCartMedia): media is ShoppingCartERezeptMedia {
  return media.attachment.mediaType === 'E_REZEPT';
}

export function isShoppingCartEHealthPrescription(media: ShoppingCartERezeptMedia) {
  return !!media.attachment.prescriptionInformation;
}
