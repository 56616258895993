import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, inject, Input, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CONFIG, MeaConfig } from '../../../../../essentials/types/src/mea-config';
import { IconNamePipe } from '../../../../../essentials/util/src/pipes/icon-name.pipe';
import { LabelNamePipe } from '../../../../../essentials/util/src/pipes/label-name.pipe';

@Component({
  selector: 'app-circular-select',
  standalone: true,
  imports: [CommonModule, IonicModule, ReactiveFormsModule, TranslateModule, IconNamePipe, LabelNamePipe],
  templateUrl: './app-circular-select.component.html',
  styleUrls: ['./app-circular-select.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: AppCircularSelectComponent }],
  animations: [
    trigger('animateArrow', [
      state('collapsed', style({ transform: 'rotate(0)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed', animate('300ms')),
    ]),
  ],
})
export class AppCircularSelectComponent<T> implements ControlValueAccessor {
  @Input() availableOptions: T[] = [];

  private element = inject(ElementRef);

  value: T | null = null;

  protected readonly placeholder = 'Bitte wählen';
  protected readonly emptyPlaceholder = 'Nicht verfügbar';
  protected showDropdown = signal(false);

  private readonly featureFlags = this.config.featureFlags;

  private touched = false;
  protected disabled = false;

  constructor(@Inject(CONFIG) private config: MeaConfig) {}

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    if (!this.featureFlags.requiresShadowRoot && this.showDropdown() && !this.touchedInside(event.target)) {
      this.showDropdown.set(false);
    }
  }

  onChange = (_value: T) => {};
  onTouched = () => {};

  writeValue(value: T) {
    this.value = value;
  }

  registerOnChange(fn: (value: T) => any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setValue(value: T) {
    this.markAsTouched();
    if (!this.disabled) {
      this.value = value;
      this.onChange(value);
    }
    this.toggleDropdown();
  }

  toggleDropdown() {
    if (!this.disabled) {
      this.showDropdown.update((value) => {
        if (!value && !this.availableOptions.length) {
          return value;
        }
        return !value;
      });
    }
  }

  private touchedInside(elementToCheck: HTMLElement): boolean {
    return elementToCheck === this.element.nativeElement || this.element.nativeElement.contains(elementToCheck);
  }
}
