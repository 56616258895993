import { Injectable } from '@angular/core';
import escapeRegExp from 'lodash-es/escapeRegExp';
import isNil from 'lodash-es/isNil';
import Pharmacy from '../../../../essentials/types/src/pharmacy';
import { PlaceholderVariables } from '../../../../essentials/types/src/placeholderVariables';
import { OpeningHoursService } from './opening-hours.service';

@Injectable({ providedIn: 'root' })
export class VariableReplacementService {
  constructor(private openingHoursService: OpeningHoursService) {}

  public replaceVariables(
    text: string,
    { chatPartnerName, pharmacy }: { chatPartnerName?: string; pharmacy?: Pharmacy }
  ): string {
    if (!isNil(pharmacy)) {
      text = this.replaceVariablesInText(
        text,
        PlaceholderVariables.OPENING_HOURS_GENERAL,
        this.openingHoursService.getAndFormatWeeklyOpeningHours(pharmacy)
      );

      text = this.replaceVariablesInText(
        text,
        PlaceholderVariables.OPENING_HOURS_TODAY,
        this.openingHoursService.getAndFormatTodaysOpeningHours(pharmacy)
      );

      if (pharmacy.telephone) {
        text = this.replaceVariablesInText(text, PlaceholderVariables.PHONE_NUMBER, pharmacy.telephone);
      }
    }

    if (!isNil(chatPartnerName)) {
      text = this.replaceVariablesInText(text, PlaceholderVariables.CHAT_PARTNER_NAME, chatPartnerName);
    }

    return text;
  }

  private replaceVariablesInText = (text: string, variable: string, replacement: string) => {
    return text.replace(new RegExp(escapeRegExp(variable), 'g'), replacement);
  };
}
