import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppointmentNavigationServiceInterface } from '../../../essentials/types/src/service-interfaces/appointment-navigation.service.interface';

@Injectable()
export class AppointmentNavigationService implements AppointmentNavigationServiceInterface {
  private router = inject(Router);

  async navigateToAppointment(appointmentId?: string) {
    if (appointmentId) {
      await this.router.navigate(['home', 'termine', appointmentId]);
    } else {
      await this.router.navigate(['home', 'termine']);
    }
  }
}
