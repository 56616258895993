import gql from 'graphql-tag';
import { appointmentV2Attributes } from '../../../../common/resources/src/graphql/attributes';

export default gql`
  subscription createdOrUpdatedAppointmentV2($pharmacyCognitoId: ID!) {
      createdOrUpdatedAppointmentV2(pharmacyCognitoId: $pharmacyCognitoId) {
        ${appointmentV2Attributes}
    }
  }
`;
