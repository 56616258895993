import { inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  AppointmentV2Input,
  AppointmentV2InputForm,
  MandatoryAppointmentFields,
} from '../../../../../essentials/types/src/appointmentV2';
import { BookedAppointmentV2Type } from '../../../../../essentials/types/src/appointmentV2Type';
import { ValidationRegex } from '../../../../../essentials/util/src/validation-regex.util';
import { DEFAULT_TIME_ZONE } from '../../global-variables';
import { EncryptionService } from '../encryption/encryption.service';

dayjs.extend(utc);
dayjs.extend(timezone); // dependent on utc plugin

@Injectable()
export class AppointmentFormService {
  private encryptionService = inject(EncryptionService);

  appointmentFormGroup = this.setNewFormGroup();
  customerNamePlaceholder = 'Name';
  customerAgePlaceholder = '';

  async encryptMandatoryCustomerFields(pharmacyPublicKey: string, enduserPublicKey?: string) {
    const form = this.appointmentFormGroup.controls;
    const mandatoryFields = {
      name: form.customerName.value,
      age: form.customerAge?.value?.toString(),
      sex: form.customerSex?.value,
    };
    const mandatoryAppointmentFields: MandatoryAppointmentFields = enduserPublicKey
      ? { name: { pharmacy: '', enduser: '' } }
      : { name: { pharmacy: '' } };
    for (const [key, value] of Object.entries(mandatoryFields)) {
      if (!value) {
        continue;
      }
      const encryptedPharmacyProperty = this.encryptionService.encryptUsingPublicKey(value, pharmacyPublicKey);
      if (!encryptedPharmacyProperty) {
        return undefined;
      }
      if (enduserPublicKey) {
        const encryptedEnduserProperty = this.encryptionService.encryptUsingPublicKey(value, enduserPublicKey);
        if (!encryptedEnduserProperty) {
          return undefined;
        }
        mandatoryAppointmentFields[key as keyof MandatoryAppointmentFields] = {
          pharmacy: encryptedPharmacyProperty,
          enduser: encryptedEnduserProperty,
        };
      } else {
        mandatoryAppointmentFields[key as keyof MandatoryAppointmentFields] = { pharmacy: encryptedPharmacyProperty };
      }
    }
    return mandatoryAppointmentFields;
  }

  setNewFormGroup() {
    return new FormGroup<AppointmentV2InputForm>({
      appointmentType: new FormControl(null, { nonNullable: true, validators: [Validators.required] }),
      customerName: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.pattern(ValidationRegex.moreThanWhitespace)],
      }),
      customerPhone: new FormControl(null, { validators: [Validators.pattern(ValidationRegex.phone)] }),
      customerEmail: new FormControl(null, {
        validators: [Validators.email, Validators.pattern(ValidationRegex.email)],
      }),
      date: new FormControl(dayjs().tz(DEFAULT_TIME_ZONE), { nonNullable: true }),
      time: new FormControl(null, { validators: [Validators.required] }),
    });
  }

  requireCustomerEmail() {
    this.appointmentFormGroup.controls.customerEmail?.addValidators(Validators.required);
  }

  getDateTime() {
    const form = this.appointmentFormGroup.controls;
    const appointmentTime = form.time.value as string; // assertion as null is filtered by validators
    return form.date.value
      .tz(DEFAULT_TIME_ZONE)
      .set('hour', Number(appointmentTime.substring(0, 2)))
      .set('minutes', Number(appointmentTime.substring(3, 5)))
      .set('seconds', 0)
      .set('milliseconds', 0)
      .toISOString();
  }

  highlightFormGroupErrorsAfterSubmitting() {
    const controls = this.appointmentFormGroup.controls;
    if (controls.customerName.invalid) {
      controls.customerName.markAsTouched();
      this.appointmentFormGroup.patchValue({ customerName: '' });
      this.customerNamePlaceholder = 'Bitte ausfüllen';
    }
    if (controls.customerAge?.invalid) {
      controls.customerAge.markAsTouched();
      this.customerAgePlaceholder = 'Bitte ausfüllen';
    }
    if (controls.customerSex?.invalid) {
      controls.customerSex.markAsTouched();
    }
    if (controls.time.invalid) {
      controls.time.markAsTouched();
    }
    if (controls.customerEmail?.invalid) {
      controls.customerEmail.markAsTouched();
    }
  }

  adjustFormGroup(appointmentType?: BookedAppointmentV2Type | null) {
    if (!appointmentType) {
      return;
    }
    if (!appointmentType.mandatoryCustomerFields?.has('Geschlecht')) {
      this.appointmentFormGroup.removeControl('customerSex', { emitEvent: false });
    } else {
      this.appointmentFormGroup.addControl(
        'customerSex',
        new FormControl<AppointmentV2Input['customerSex']>(undefined, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        { emitEvent: false }
      );
    }

    if (!appointmentType.mandatoryCustomerFields?.has('Alter')) {
      this.appointmentFormGroup.removeControl('customerAge', { emitEvent: false });
    } else {
      this.appointmentFormGroup.addControl(
        'customerAge',
        new FormControl<AppointmentV2Input['customerAge']>(undefined, {
          nonNullable: true,
          validators: [Validators.required, Validators.pattern(ValidationRegex.positiveNumber)],
        }),
        { emitEvent: false }
      );
    }
  }
}
