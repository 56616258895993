import gql from 'graphql-tag';
import { appointmentV2TypeAttributes } from '../attributes';

export default gql`
    query getAppointmentV2Types($pharmacyCognitoId: ID!, $nextToken: String) {
        getAppointmentV2Types(pharmacyCognitoId: $pharmacyCognitoId, nextToken: $nextToken) {
            __typename
            appointmentTypes {
                ${appointmentV2TypeAttributes}
            }
            nextToken
        }
    }
`;
