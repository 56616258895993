import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { IconNamePipe } from '../../../../../essentials/util/src/pipes/icon-name.pipe';

type ButtonStyle =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'danger-outline'
  | 'clear-danger'
  | 'clear'
  | 'clear-bold'
  | 'clear-white'
  | 'sanacorp'
  | 'sanacorp-inverted'
  | 'sanacorp-outline'
  | 'clear-black-outline';

@Component({
  selector: 'mea-button',
  templateUrl: './mea-button.component.html',
  styleUrls: ['./mea-button.component.scss'],
  standalone: true,
  imports: [IonicModule, NgClass, IconNamePipe],
})
export class MeaButtonComponent {
  @Input() shadow = false;
  @Input() icon: string | undefined;
  @Input() expand: 'block' | 'full' | undefined;
  @Input() disabled = false;
  @Input() loading = false;
  @Input() type: 'submit' | 'button' | 'reset' = 'button';
  @Output() buttonClick = new EventEmitter<MouseEvent>();

  @Input() set buttonStyle(type: ButtonStyle | undefined) {
    if (type) {
      this._buttonStyle = type;
    }
    if (type === 'primary' || type === 'danger' || type === 'sanacorp' || type === 'sanacorp-inverted') {
      this.fill = 'solid';
    } else if (
      type === 'secondary' ||
      type === 'danger-outline' ||
      type === 'sanacorp-outline' ||
      type === 'clear-black-outline'
    ) {
      this.fill = 'outline';
    } else {
      this.fill = 'clear';
    }
  }

  protected fill = 'solid';
  protected _buttonStyle = 'primary';

  onClick(event: MouseEvent) {
    event.stopPropagation();
    this.buttonClick.emit(event);
  }
}
