import gql from 'graphql-tag';
import { appointmentV2Attributes } from '../attributes';

export default gql`
    query getAppointmentsV2($pharmacyCognitoId: ID!, $startDate: AWSDateTime!, $endDate: AWSDateTime!, $nextToken: String) {
        getAppointmentsV2(pharmacyCognitoId: $pharmacyCognitoId, startDate: $startDate, endDate: $endDate, nextToken: $nextToken) {
            __typename
            appointments {
                ${appointmentV2Attributes}
            }
            nextToken
        }
    }
`;
