import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DEFAULT_TIME_ZONE } from '../../../common/resources/src/global-variables';
import { BlockingAppointmentV2, DecryptedAppointmentV2 } from '../../types/src/appointmentV2';
import {
  AppointmentV2Type,
  BookableTime,
  BookedAppointmentV2Type,
  LeadTimeInput,
  Time,
  Timeslot,
  TimeslotWithStatus,
} from '../../types/src/appointmentV2Type';

dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(timezone); // dependent on utc plugin
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

export class AppointmentTimeslotUtil {
  private static readonly blockingTimeslot: Timeslot = { from: '00:00', until: '23:59' };

  static determineAvailableTimeslotsForPharmacy({
    date,
    blockingAppointmentsOnDay,
    currentAppointmentId,
  }: {
    date: Dayjs;
    blockingAppointmentsOnDay: DecryptedAppointmentV2[] | BlockingAppointmentV2[];
    currentAppointmentId?: string;
  }): Timeslot[] {
    const blockedTimeslots = [
      ...this.getBlockedTimesByCurrentTimeOfDay(date, { respectLeadTime: false }),
      ...this.getBlockedTimesByBookedAppointments(blockingAppointmentsOnDay, currentAppointmentId),
    ];
    return this.invertTimeslotCollection(blockedTimeslots);
  }

  static determineAvailableTimeslots({
    date,
    blockingAppointmentsOnDay = [],
    leadTime = { respectLeadTime: false },
    currentAppointmentId,
    openingHours,
    selectedAppointmentType,
    ignoreCurrentDate,
  }: {
    date: Dayjs;
    blockingAppointmentsOnDay?: DecryptedAppointmentV2[] | BlockingAppointmentV2[];
    leadTime?: LeadTimeInput;
    currentAppointmentId?: string;
    openingHours?: string;
    selectedAppointmentType?: AppointmentV2Type;
    ignoreCurrentDate?: boolean;
  }): Timeslot[] {
    const blockedTimeslots = [
      ...this.getBlockedTimesByOpeningHours(openingHours, selectedAppointmentType),
      ...(ignoreCurrentDate ? [] : this.getBlockedTimesByCurrentTimeOfDay(date, leadTime)),
      ...this.getBlockedTimesByBookedAppointments(blockingAppointmentsOnDay, currentAppointmentId),
      ...(selectedAppointmentType ? this.getBlockedTimesByAppointmentType(selectedAppointmentType, date) : []),
    ];
    return this.invertTimeslotCollection(blockedTimeslots);
  }

  static getSortedOpenedAndClosedTimeslotsFromOpeningHours(openingHours?: string): TimeslotWithStatus[] {
    const openedTimeslots = this.getTimeslotsFromOpeningHours(openingHours).sort((a, b) => (a.from > b.from ? 1 : -1));
    return openedTimeslots.reduce((acc: TimeslotWithStatus[], currentTimeslot, currentIndex, entireArray) => {
      if (currentIndex === 0 && currentTimeslot.from !== this.blockingTimeslot.from) {
        acc.push({ from: this.blockingTimeslot.from, until: currentTimeslot.from, closed: true });
      }
      acc.push(currentTimeslot);
      if (currentIndex < entireArray.length - 1) {
        const nextTimeslot = entireArray[currentIndex + 1] as Timeslot;
        if (currentTimeslot.until !== nextTimeslot.from) {
          acc.push({ from: currentTimeslot.until, until: nextTimeslot.from, closed: true });
        }
      } else if (currentTimeslot.until !== this.blockingTimeslot.until) {
        acc.push({ from: currentTimeslot.until, until: this.blockingTimeslot.until, closed: true });
      }
      return acc;
    }, []);
  }

  static getAvailableTimeslotTimesForPharmacy(
    timeslot: Timeslot,
    date: Dayjs,
    appointmentType: BookedAppointmentV2Type
  ) {
    const availableDateTimes = this.getAvailableAppointmentTimesInTimeslot(timeslot, date, appointmentType);
    return availableDateTimes.map((dateTime) => `${dateTime.format('HH:mm')} Uhr`);
  }

  static getAvailableTimeslotTimesForEnduser(
    timeslot: Timeslot,
    date: Dayjs,
    appointmentType: AppointmentV2Type,
    openingHours?: string
  ) {
    const availableDateTimes = this.getAvailableAppointmentTimesInTimeslot(timeslot, date, appointmentType);
    const allowedAppointmentTimesToBook = this.getAllowedAppointmentTimesToBook(date, appointmentType, openingHours);
    return availableDateTimes
      .map((dateTime) => dateTime.format('HH:mm'))
      .filter((time) => allowedAppointmentTimesToBook.has(time));
  }

  static getAllowedAppointmentTimesToBook(
    date: Dayjs,
    selectedAppointmentType: AppointmentV2Type,
    openingHours?: string
  ) {
    const appointmentTimeslotsDayjs: Set<string> = new Set();
    const appointmentTimeslots = this.determineAvailableTimeslots({ date, selectedAppointmentType, openingHours });
    let firstStartTime = appointmentTimeslots.at(0)?.from;
    if (!firstStartTime) {
      return appointmentTimeslotsDayjs;
    }
    firstStartTime = this.roundUpToBookableMinutes(firstStartTime);
    const startTime = date.hour(Number(firstStartTime.slice(0, 2))).minute(Number(firstStartTime.slice(3, 5)));

    let currentAppointmentTime = startTime;
    while (currentAppointmentTime.isSame(startTime, 'day')) {
      appointmentTimeslotsDayjs.add(currentAppointmentTime.format('HH:mm'));
      currentAppointmentTime = currentAppointmentTime.add(selectedAppointmentType.durationInMinutes, 'minute');
    }
    return appointmentTimeslotsDayjs;
  }

  static calculateEndTime(time?: string | null, appointmentType?: BookedAppointmentV2Type | null) {
    if (!time || !appointmentType) {
      return undefined;
    }
    const sanitizedTime = time.split(' ')[0];
    return dayjs
      .tz(sanitizedTime, 'HH:mm', DEFAULT_TIME_ZONE)
      .add(appointmentType.durationInMinutes, 'minutes')
      .format('HH:mm');
  }

  static getArrayIndexFromWeekday(date: Dayjs) {
    return date.isoWeekday() - 1;
  }

  static getTimeslotsFromOpeningHours(openingHours?: string, appointmentType?: AppointmentV2Type): Timeslot[] {
    if (!openingHours) {
      return [];
    }
    const timeslotRegexp = /^(\d{1,2}:\d{1,2})(?::\d{1,2})? -- (\d{1,2}:\d{1,2})(?::\d{1,2})?$/;
    let timeslots = openingHours.split(',').map((timeslot) => timeslot.trim());
    if (appointmentType?.ignoreBreakTimes) {
      const startTime = timeslots.at(0)?.match(timeslotRegexp)?.at(1);
      const endTime = timeslots.at(-1)?.match(timeslotRegexp)?.at(2);
      if (startTime && endTime) {
        timeslots = [`${startTime} -- ${endTime}`];
      }
    }
    if (appointmentType?.ignoreOpeningHours) {
      const firstTimeslot = timeslots.at(0);
      timeslots = [
        `${this.blockingTimeslot.from} -- ${firstTimeslot?.match(timeslotRegexp)?.at(2)}`,
        ...timeslots.slice(1),
      ];
      const lastTimeslot = timeslots.at(-1);
      timeslots = [
        ...timeslots.slice(0, -1),
        `${lastTimeslot?.match(timeslotRegexp)?.at(1)} -- ${this.blockingTimeslot.until}`,
      ];
    }
    return timeslots
      .map((timeslot) => {
        const match = timeslot.match(timeslotRegexp);
        return match ? ({ from: match[1], until: match[2] } as Timeslot) : null;
      })
      .filter((timeslot) => !!timeslot);
  }

  static appointmentTypeIsActivated({ endDate, isActive, startDate }: AppointmentV2Type, date: Dayjs): boolean {
    const startDateIsAfterCurrentDate = startDate && date.isBefore(startDate, 'date');
    const endDateIsBeforeCurrentDate = endDate && date.isAfter(endDate, 'date');
    return !(!isActive || startDateIsAfterCurrentDate || endDateIsBeforeCurrentDate);
  }

  static appointmentTypeHasTimeslots({ timeslots }: AppointmentV2Type, date: Dayjs): boolean {
    return !!timeslots[AppointmentTimeslotUtil.getArrayIndexFromWeekday(date)];
  }

  static roundDownToBookableMinutes(time: Time): BookableTime {
    return `${time.slice(0, 4)}0` as any;
  }

  static roundUpToBookableMinutes(time: Time): BookableTime {
    const parsedTime = dayjs(time, 'HH:mm');
    const lastDigit = parsedTime.minute() % 10;
    const roundedTime = lastDigit !== 0 ? parsedTime.add(10 - lastDigit, 'minutes') : parsedTime;
    return roundedTime.format('HH:mm') as any;
  }

  private static invertTimeslotCollection(blockedTimeslots: Timeslot[]) {
    if (blockedTimeslots.length === 0) {
      return [{ ...this.blockingTimeslot }];
    }
    const sortedBlockedTimeslots = blockedTimeslots.toSorted((a, b) => (a.from < b.from ? -1 : 1));

    const availableTimeslots: Timeslot[] = [];
    let startTime: Time;
    sortedBlockedTimeslots.forEach((blockedTimeslot, index) => {
      if (index === 0 && blockedTimeslot.from !== this.blockingTimeslot.from) {
        availableTimeslots.push({ from: this.blockingTimeslot.from, until: blockedTimeslot.from });
      }
      if (!startTime) {
        startTime = blockedTimeslot.until;
      } else if (blockedTimeslot.from <= startTime && blockedTimeslot.until > startTime) {
        startTime = blockedTimeslot.until;
      } else if (blockedTimeslot.from > startTime) {
        availableTimeslots.push({ from: startTime, until: blockedTimeslot.from });
        startTime = blockedTimeslot.until;
      }
      if (index === sortedBlockedTimeslots.length - 1 && startTime !== this.blockingTimeslot.until) {
        availableTimeslots.push({ from: startTime, until: this.blockingTimeslot.until });
      }
    });

    return availableTimeslots;
  }

  private static getAvailableAppointmentTimesInTimeslot(
    timeslot: Timeslot,
    date: Dayjs,
    appointmentType: BookedAppointmentV2Type
  ) {
    const startTime = this.roundUpToBookableMinutes(timeslot.from).split(':');
    const endTime = timeslot.until.split(':');

    let appointmentTime = date.set('hour', Number(startTime[0])).set('minutes', Number(startTime[1]));
    const timeslotEnd = date.set('hour', Number(endTime[0])).set('minutes', Number(endTime[1]));

    const availableDateTimes: Dayjs[] = [];
    while (appointmentTime.add(appointmentType.durationInMinutes, 'minutes').isSameOrBefore(timeslotEnd)) {
      availableDateTimes.push(appointmentTime);
      appointmentTime = appointmentTime.add(10, 'minutes');
    }
    return availableDateTimes;
  }

  private static getBlockedTimesByOpeningHours(openingHours?: string, appointmentType?: AppointmentV2Type): Timeslot[] {
    return this.invertTimeslots(this.getTimeslotsFromOpeningHours(openingHours, appointmentType));
  }

  private static getBlockedTimesByAppointmentType(
    selectedAppointmentType: AppointmentV2Type,
    selectedDate: Dayjs
  ): Timeslot[] {
    const timeslot = selectedAppointmentType.timeslots[this.getArrayIndexFromWeekday(selectedDate)];
    return timeslot ? this.invertTimeslots([timeslot]) : [{ ...this.blockingTimeslot }];
  }

  private static getBlockedTimesByCurrentTimeOfDay(date: Dayjs, leadTime: LeadTimeInput): Timeslot[] {
    const now = dayjs().tz(DEFAULT_TIME_ZONE);
    const earliestBookableTime = leadTime.respectLeadTime ? now.add(leadTime.leadTimeInHours, 'hours') : now;
    if (date.isAfter(earliestBookableTime, 'day')) {
      return [];
    }
    if (date.isBefore(earliestBookableTime, 'day')) {
      return [{ ...this.blockingTimeslot }];
    }
    const minuteOffsetToNextInterval = (10 - (earliestBookableTime.get('minutes') % 10)) % 10;
    const until = earliestBookableTime.add(minuteOffsetToNextInterval, 'minutes').format('HH:mm') as Time;
    return [{ from: this.blockingTimeslot.from, until }];
  }

  private static getBlockedTimesByBookedAppointments(
    appointmentsOnDay: DecryptedAppointmentV2[] | BlockingAppointmentV2[],
    currentAppointmentId?: string
  ) {
    let bookedAppointments = appointmentsOnDay.filter((appointment) => !appointment.isCancelled);
    if (currentAppointmentId) {
      bookedAppointments = bookedAppointments.filter((appointment) => appointment.id !== currentAppointmentId);
    }
    return bookedAppointments
      .map((appointment) => {
        const regex = /(\d{2}):(\d{2}) Uhr/;
        const match = appointment.time?.match(regex);
        if (!appointment.time || !appointment.appointmentType || !match) {
          return null;
        }
        const endTime = appointment.date
          .set('hour', Number(match[1]))
          .set('minutes', Number(match[2]))
          .add(appointment.appointmentType.durationInMinutes, 'minutes')
          .format('HH:mm');
        return { from: `${match[1]}:${match[2]}`, until: endTime } as Timeslot;
      })
      .filter((timeslot): timeslot is Timeslot => !!timeslot);
  }

  private static invertTimeslots(timeslots: Timeslot[]) {
    if (timeslots.length === 0) {
      return [{ ...this.blockingTimeslot }];
    }
    const invertedTimeslots: Timeslot[] = [];
    timeslots.forEach((timeslot, index) => {
      if (index === 0 && this.blockingTimeslot.from !== timeslot.from) {
        invertedTimeslots.push({ from: this.blockingTimeslot.from, until: timeslot.from });
      }
      if (timeslot.until < this.blockingTimeslot.until) {
        const from = timeslot.until;
        const until =
          index < timeslots.length - 1
            ? (timeslots[index + 1]?.from ?? this.blockingTimeslot.until)
            : this.blockingTimeslot.until;
        invertedTimeslots.push({ from, until });
      }
    });
    return invertedTimeslots;
  }
}
