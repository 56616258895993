import gql from 'graphql-tag';

export default gql`
  mutation updateOSPharmacyCognitoId($sanacorpCustomerId: ID!, $cognitoId: ID!) {
    updateOSPharmacy(sanacorpCustomerId: $sanacorpCustomerId, pharmacy: { cognitoId: $cognitoId }) {
      __typename
      sanacorpCustomerId
      cognitoId
    }
  }
`;
