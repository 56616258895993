import {
  isShoppingCartERezeptMedia,
  isShoppingCartImageMedia,
  ShoppingCartERezeptMedia,
  ShoppingCartImageMedia,
  SubmittedShoppingCart,
} from '../../types/src/shoppingCart';
import {
  isValidShopProduct,
  ShopArticle,
  ShopEPrescription,
  ShopPrescriptionImage,
  ShopSale,
  ValidShopProduct,
} from '../../types/src/shopSale';
import { DataUrlUtil } from './chat/data-url.util';

export class ShopCoopUtil {
  static mapShoppingCartToShopSale(shoppingCart: SubmittedShoppingCart, conversationId?: string): ShopSale {
    let collectedNotes = 'Aus mea Chat übertragen.';
    const articles: ShopArticle[] = shoppingCart.products
      .filter((product): product is ValidShopProduct => isValidShopProduct(product))
      .map((product) => ({
        title: product.name,
        sku: product.pzn,
        priceBrutto: product.price.toFixed(2),
        tax: product.tax.toFixed(2),
        taxRate: product.taxRate.toFixed(2),
        quantity: product.selectedAmount,
        discount: (product.retailPrice - product.price).toFixed(2),
        originalPriceBrutto: product.retailPrice.toFixed(2),
      }));
    const ePrescriptions: ShopEPrescription[] = shoppingCart.prescriptions
      .filter((prescription): prescription is ShoppingCartERezeptMedia => isShoppingCartERezeptMedia(prescription))
      .map((prescription, index) => {
        if (prescription.note) {
          collectedNotes += '\n' + prescription.note;
        }
        if (prescription.attachment.prescriptionInformation) {
          return {
            originalPrescriptionInPharmacy: false,
            eprescriptionCode: {
              tokens: [JSON.stringify({ urls: [prescription.attachment.prescriptionInformation.eRezept] })],
            },
            title: `E-Rezept ${index + 1}`,
          };
        }
        return prescription.attachment.eRezept.map((rezept, subIndex) => ({
          originalPrescriptionInPharmacy: true,
          eprescriptionCode: { tokens: [JSON.stringify({ urls: [rezept] })] },
          title: `E-Rezept ${index + 1}, Verordnung ${subIndex + 1}`,
        }));
      })
      .flat();

    const prescriptionImages: ShopPrescriptionImage[] = shoppingCart.prescriptions
      .filter((prescription): prescription is ShoppingCartImageMedia => isShoppingCartImageMedia(prescription))
      .map((prescription, index) => {
        if (!prescription.attachment.imageDataUrl) {
          return undefined;
        }
        if (prescription.note) {
          collectedNotes += '\n' + prescription.note;
        }
        return {
          originalPrescriptionInPharmacy: true,
          prescriptionFileName: prescription.attachment.name,
          prescriptionImageBase64: DataUrlUtil.extractContentFromDataUrl(prescription.attachment.imageDataUrl),
          title: `Rezeptfoto ${index + 1}`,
        };
      })
      .filter((prescription) => !!prescription);

    return {
      price: this.getShoppingCartPrice(articles),
      pharmacyComment: collectedNotes,
      articles,
      receipts: [...ePrescriptions, ...prescriptionImages],
      conversationID: conversationId,
    };
  }

  static getShoppingCartPrice(articles: ShopArticle[]) {
    const totalPriceBrutto = articles.reduce((accumulator, article) => {
      return accumulator + parseFloat(article.priceBrutto) * article.quantity;
    }, 0);
    const totalOriginalPriceBrutto = articles.reduce((accumulator, article) => {
      return accumulator + parseFloat(article.originalPriceBrutto) * article.quantity;
    }, 0);
    const totalTax = articles.reduce((accumulator, article) => {
      return accumulator + parseFloat(article.tax) * article.quantity;
    }, 0);
    const totalDiscount = totalOriginalPriceBrutto - totalPriceBrutto;

    return {
      totalBrutto: totalOriginalPriceBrutto.toFixed(2),
      discount: totalDiscount.toFixed(2),
      grandTotal: totalPriceBrutto.toFixed(2),
      tax: totalTax.toFixed(2),
      shippingCost: '0.00',
    };
  }
}
