import BackendUserConversation from '../../../types/src/backendUserConversation';
import { Logger } from '../logger';

export const logger = new Logger('ChatHelper');

export function getValidConversationsWithNonNullParticipants(
  backendUserConversations: BackendUserConversation[]
): BackendUserConversation[] {
  return backendUserConversations.filter((backendUserConversation) => {
    const participantsValid = userConversationHasValidParticipants(backendUserConversation);
    const conversationExists = userConversationHasConversation(backendUserConversation);
    return participantsValid && conversationExists;
  });
}

function userConversationHasValidParticipants(backendUserConversation: BackendUserConversation) {
  const nonNullParticipants = backendUserConversation.participants.filter((participant) => !!participant);
  const hasValidParticipants = nonNullParticipants.length === 2;
  if (!hasValidParticipants) {
    const errorMessage =
      `Conversation with conversationId ${backendUserConversation.conversationId} has invalid participants. ` +
      'Ignoring conversation.';
    logger.warn(errorMessage);
  }
  return hasValidParticipants;
}

function userConversationHasConversation(backendUserConversation: BackendUserConversation) {
  const hasConversation = !!backendUserConversation.conversation;
  if (!hasConversation) {
    const errorMessage =
      `Conversation with conversationId ${backendUserConversation.conversationId} has no conversation. ` +
      'Ignoring conversation.';
    logger.warn(errorMessage);
  }
  return hasConversation;
}
