import gql from 'graphql-tag';
import MessageMedia from '../../../../../essentials/types/src/messageMedia';
import { messageAttributes } from '../attributes';

export interface CreateMessageInput {
  conversationId: string;
  recipientId: string;
  senderId: string;
  media: MessageMedia[];
  encryptedTextContent?: string;
  displayAsPharmacyMessage?: boolean;
  displayOwnAsUnread?: boolean;
  automatic?: boolean;
  customMessageId?: string;
  appointmentChangedDateTime?: string;
}

export default gql`
  mutation createMessage(
    $conversationId: ID!
    $encryptedTextContent: String
    $recipientId: ID!
    $senderId: ID!
    $media: [MessageMediaInput!]!
    $displayAsPharmacyMessage: Boolean
    $displayOwnAsUnread: Boolean
    $automatic: Boolean
    $initialMessage: Boolean
    $appointmentChangedDateTime: AWSDateTime
    $customMessageId: String
  ) {
    createMessage(
      conversationId: $conversationId
      message: {
        encryptedTextContent: $encryptedTextContent
        recipientId: $recipientId
        senderId: $senderId
        media: $media
        displayAsPharmacyMessage: $displayAsPharmacyMessage
        displayOwnAsUnread: $displayOwnAsUnread
        automatic: $automatic
        initialMessage: $initialMessage
        appointmentChangedDateTime: $appointmentChangedDateTime
      }
      customMessageId: $customMessageId
    ) {
      ${messageAttributes}
    }
  }
`;
