import gql from 'graphql-tag';

export default gql`
  query getBlockingAppointmentsV2(
    $pharmacyCognitoId: ID!
    $startDate: AWSDateTime!
    $endDate: AWSDateTime!
    $nextToken: String
  ) {
    getAppointmentsV2(
      pharmacyCognitoId: $pharmacyCognitoId
      startDate: $startDate
      endDate: $endDate
      nextToken: $nextToken
    ) {
      __typename
      appointments {
        __typename
        pharmacyCognitoId
        id
        dateTime
        appointmentTypeId
        durationInMinutes
        name
        isCancelled
      }
      nextToken
    }
  }
`;
