import gql from 'graphql-tag';
import { appointmentV2Attributes } from '../../../../../common/resources/src/graphql/attributes';

export default gql`
    mutation updateAppointmentV2(
        $id: ID!,
        $dateTime: AWSDateTime!,
        $telephone: String,
        $email: String,
        $appointmentTypeId: ID!,
        $mandatoryCustomerFields: MandatoryCustomerFieldsInput!,
        $additionalInformation: String,
        $durationInMinutes: Int!,
        $name: String!
    ) {
        updateAppointmentV2(
            id: $id,
            appointmentV2Update: {
                dateTime: $dateTime,
                telephone: $telephone,
                email: $email,
                appointmentTypeId: $appointmentTypeId,
                mandatoryCustomerFields: $mandatoryCustomerFields,
                additionalInformation: $additionalInformation,
                durationInMinutes: $durationInMinutes,
                name: $name
            }
        ) {
            ${appointmentV2Attributes}
        }
    }
`;
