import { inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { Logger } from '../../../../../../../../libs/essentials/util/src/logger';
import { MeaState } from '../../../../store/state/mea.state';
import { selectSoundNotification } from '../../selectors/home.selectors';

const logger = new Logger('AudioService');

@Injectable({ providedIn: 'root' })
export class AudioService {
  private store: Store<MeaState> = inject(Store);
  private platform = inject(Platform);

  audio: HTMLMediaElement | undefined;

  private soundNotification$ = this.store.select(selectSoundNotification);

  constructor() {
    const isSafari =
      this.platform.testUserAgent('Safari') &&
      !this.platform.testUserAgent('Chrome') &&
      !this.platform.testUserAgent('Chromium');
    if (!isSafari) {
      logger.info('not in Safari, initializing audio immediately');
      this.initAudio();
    }
  }

  async playSound() {
    try {
      const soundNotification = await firstValueFrom(this.soundNotification$);
      if (soundNotification && this.audio) {
        this.audio.paused ? await this.audio.play() : (this.audio.currentTime = 0);
      }
    } catch (e) {
      logger.info('Failed to play audio sound', e);
    }
  }

  /* Init audio playback if in Safari, since it is only possible on interaction */
  initAudioOnInteraction() {
    if (!this.audio) {
      this.initAudio();
    }
  }

  private initAudio() {
    logger.info('Initializing audio');
    this.audio = new Audio();
    this.audio.src = '../../../assets/audio/notification.wav';
    this.audio.load();
  }
}
