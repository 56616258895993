import { Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';
import { ChatUser, PushRepetition } from '../../../../../essentials/types/src/chatUser';
import { LoadStatus } from '../../../../../essentials/types/src/loadStatus';
import { LogoutType } from '../../../../../essentials/types/src/logoutType';
import Pharmacy from '../../../../../essentials/types/src/pharmacy';

export const initUser = createAction(`${ActionType.User}: Init user`);
export const loadInitialUser = createAction(`${ActionType.User}: Load initialUser`);

export const setUserOnLogin = createAction(`${ActionType.User}: Set user on login`, props<{ user: ChatUser }>());
export const updateUser = createAction(`${ActionType.User}: Update user`, props<{ partialUser: Partial<ChatUser> }>());

export const updateMePharmacy = createAction(
  `${ActionType.User}: Update pharmacy of pharmacy user`,
  props<{ pharmacy: Partial<Pharmacy> }>()
);

export const logoutUser = createAction(
  `${ActionType.User}: Logout user`,
  props<{ logoutType: LogoutType; loginQueryParams?: Params }>()
);

export const setUserLoadStatus = createAction(
  `${ActionType.User}: Set initial user load status`,
  props<{ userLoadStatus: LoadStatus }>()
);
export const setPushNotificationRepetitionInterval = createAction(
  `${ActionType.User}: Set pushNotificationRepetitionInterval`,
  props<{ pushNotificationRepetitionInterval: PushRepetition }>()
);

export const clearUserOnLogout = createAction(`${ActionType.User}: Clear user on logout`);
export const resetUserState = createAction(`${ActionType.User}: Reset user state`);

export const deleteGuestSessionAttempt = createAction(`${ActionType.User}: Attempt to delete guest user session`);

export const rehydrateUser = createAction(`${ActionType.User}: Rehydrate user`, props<{ user: ChatUser }>());

export const checkSsoSessionStatus = createAction(
  `${ActionType.User}: Check sso session status`,
  props<{ user: ChatUser }>()
);
